export const en = {
	'New pattern': 'New pattern',
	'Pattern name': 'Pattern name',
	'Brand': 'Brand',
	'Notes': 'Notes',
	'My patterns': 'My patterns',
	'Add new pattern': 'Add new pattern',
	'Feedbacks': 'Feedbacks',
	'Guide': 'Guide',
	'Brands': 'Brands',
	'Remember me': 'Remember me',
	'Login': 'Login',
	'Username': 'Username',
	'Password': 'Password',
	'Forgot your password': 'Forgot your password',
	'Profile': 'Profile',
	'No account yet ? Sign in': 'No account yet ? Sign in',
	'here': 'here',
	'About us': 'About us',
	'Contact': 'Contact',
	'Sign in': 'Account creation',
	'Create account': 'Create account',
	'Wishlist': 'Wishlist',
	'No account found for the provided credentials': 'No account found for the provided credentials',
	'Image URL': 'Image URL',
	'Categories': 'Categories',
	'Self drafted pattern': 'Self drafted pattern',
	'Projector': 'Projector',
	'A4 with layers': 'A4 with layers',
	'US Letter with layers': 'US Letter avec calques',
	'A0 with layers': 'A0 with layers',
	'Projector with layers': 'Projector with layers',
	'Custom projector file': 'Custom projector file',
	'Available formats': 'Available formats',
	'Tags': 'Tags',
	'Recommended fabrics': 'Recommended fabrics',
	'Bought in': 'Bought in',
	'Destined to': 'Destined to',
	'Everyone': 'Everyone',
	'Women': 'Women',
	'Men': 'Men',
	'Children': 'Children',
	'Language': 'Language',
	'Difficulty': 'Difficulty',
	'Shop pattern link': 'Pattern link',
	'Free pattern': 'Free pattern',
	'Video name': 'Video name',
	'Video link': 'Video link',
	'My inspirations': 'My inspirations',
	'My fabrics': 'My fabrics',
	'Files': 'Files',
	'File name': 'File name',
	'File link': 'File link',
	'Save pattern': 'File link',
	'Custom filters': 'Custom filters',
	'Logout': 'Logout',
	'Profile settings': 'Profile settings',
	'Filters': 'Filters',
	'Advanced filters': 'Advanced filters',
	'Search results': ' Search results ({{count}} result(s))',
	'No results': 'No results',
	'NO IMAGE AVAILABLE': 'NO IMAGE AVAILABLE',
	'Validate': 'Validate',
	'Paper': 'Paper',
	'Applicable size chart': 'Applicable size chart',
	'OR': 'OR',
	'Name is mandatory': 'Name is mandatory',
	'Click to browse or drop image': 'Click to browse or drop image',
	'a4': 'A4',
	'a4-layered': 'A4 with layers',
	'us-letter': 'US Letter',
	'us-letter-layered': 'US Letter with layers',
	'a0': 'A0',
	'a0-layered': 'A0 with layers',
	'projector': 'Projector',
	'projector-layered': 'Projector with layers',
	'custom-projector': 'Custom projector file',
	'paper': 'Paper',
	'Fabric requirements': 'Fabric requirements',
	'Fabric details': 'Fabric details',
	'My notes': 'My notes',
	'My files': 'My files',
	'My videos': 'My videos',
	'Username already taken': 'Username already taken',
	'Username updated': 'Username updated',
	'You must provide the link of the video': 'You must provide the link of the video',
	'Invalid email': 'Invalid email',
	'Reset password': 'Reset password',
	'Video url': 'Video url',
	'File url': 'File url',
	'Click to edit': 'Click to edit',
	'Create an account': 'Create an account',
	'Create': 'Create',
	'No result for that search': 'No results for that search',
	'My library': 'My library',
	'Resources': 'Resources',
	'Demonstration': 'Demonstration',
	'My brands': 'My brands',
	'Add new item': 'Add new item',
	'New fabric': 'New fabric',
	'Name': 'Name',
	'Shop': 'Shop',
	'Available quantity': 'Available quantity',
	'Fabric types': 'Fabric types',
	'4-way-stretch': '4-way-stretch',
	'2-way-stretch': '2-way-stretch',
	'woven': 'Woven',
	'Type to search or create': 'Type to search or create',
	'Brand name': 'Brand name',
	'Brand website url': 'Brand website url',
	'Brands list': 'Brands list',
	'By': 'By {{username}}',
	'Your feedback': 'Your feedback',
	'Submit': 'Submit',
	'Cancel': 'Cancel',
	'Save': 'Save',
	'My filters': 'My filters',
	'Custom projector': 'Custom projector',
	'US letter with layers': 'US letter with layers',
	'Delete my account': 'Delete my account',
	'Deleting your account will delete permanently all the patterns and fabrics you created': 'Deleting your account will delete permanently all the patterns and fabrics you created',
	'Do you confirm you want to delete your account': 'Do you confirm you want to delete your account',
	'Confirm': 'Confirm',
	'Account deletion': 'Account deletion',
	'On this page, you will find a list of verified brands selling pdf patterns': 'On this page, you will find a list of verified brands selling PDF patterns',
	'Feel free to submit a brand you like if it is not in the list in the form below': 'Feel free to submit a brand you like if it is not in the list in the form below',
	'Did not find your favorite brand in the list? Submit it': 'Your favorite brand is not in the list? Submit it',
	'Manage brands': 'Manage brands',
	'Manage users': 'Manage users',
	'Logs': 'Logs',
	'Stats': 'Stats',
	'Site management': 'Site management',
	'Console': 'Console',
	'Users list': 'Users list',
	'Validated': 'Validated',
	'Website': 'Website',
	'Delete pattern': 'Delete pattern',
	'Are you sure you want to delete that pattern': 'Are you sure you want to delete that pattern',
	'Pattern deletion': 'Pattern deletion',
	'User email': 'User email',
	'Role': 'Role',
	'Creation date': 'Creation date',
	'Last connection date': 'Last connection date',
	'Last modification date': 'Last modification date',
	'Delete fabric': 'Delete fabric',
	'Fabric deletion': 'Fabric deletion',
	'Are you sure you want to delete that fabric': 'Are you sure you want to delete that fabric',
	'Projects you can do with that fabric_one': 'Projects you can do with this fabric ({{count}} project)',
	'Projects you can do with that fabric_other': 'Projects you can do with this fabric ({{count}} projects)',
	'Fabrics you can use for that pattern_one': 'Fabrics you can use for this pattern ({{count}} fabric)',
	'Fabrics you can use for that pattern_other': 'Fabrics you can use for this pattern ({{count}} fabrics)',
	'We found no patterns in your collection for that type and quantity of fabric': 'We found no patterns in your collection for that type and quantity of fabric',
	'We found no fabrics in your collection for that pattern': 'We found no fabrics in your stash for that pattern',
	'Colors': 'Colors',
	'Design/Pattern': 'Design',
	'Composition': 'Composition',
	'Other informations': 'Other informations',
	'Fitting adjustment required': 'Fitting adjustment required',
	'Projector adjustment required': 'Projector adjustment required',
	'Sewing patterns quantity': 'Patterns count',
	'Fabrics quantity': 'Stash count',
	'Projects quantity': 'Projects count',
	'Inspirations quantity': 'Inspirations count',
	'Unlimited': 'Unlimited',
	'Go back to patterns list': 'Go back to patterns list',
	'Go back to fabrics list': 'Go back to fabrics list',
	'You have reached the limits of sewing patterns you are allowed to create': 'You have reached the limits of sewing patterns you are allowed to create',
	'You have reached the limits of stash items you are allowed to create': 'You have reached the limits of stash items you are allowed to create',
	'Brands updated': 'Brands updated',
	'Go back to main site': 'Go back to main site',
	'Brand submitted': 'Brand submitted',
	'My projects': 'My projects',
	'Add new inspiration': 'Add new inspiration',
	'Sewing': 'Sewing',
	'Knit': 'Knit',
	'Crochet': 'Crochet',
	'Other': 'Other',
	'My stash': 'Mon inventaire',
	'Yarn': 'Yarn',
	'Fabrics': 'Fabrics',
	'New item': 'New item',
	'Go back to stash': 'Go back to stash',
	'Fabric': 'Fabric',
	'NotStarted': 'Not started',
	'Started': 'Started',
	'Paused': 'Paused',
	'ToAdjust': 'To adjust',
	'Finished': 'Finished',
	'Abandoned': 'Abandoned',
	'Add new project': 'Add new project',
	'Home': 'Home',
	'Edit': 'Edit',
	'Delete': 'Delete',
	'Related elements': 'Related elements',
	'Project details': 'Project details',
	'meters': 'meters',
	'yard': 'yard',
	'grams': 'grams',
	'Related projects default': 'Current projects',
	'Related projects': 'Current projects ({{count}})',
	'Projects you can do with that yarn': 'Projects you can do with that yarn',
	'Yarn details': 'Yarn details',
	'Sewing patterns': 'Sewing patterns',
	'Knit patterns': 'Knit patterns',
	'Crochet patterns': 'Crochet patterns',
	'Other patterns': 'Other patterns',
	'View': 'View',
	'Create project': 'Create project',
	'Quantity': 'Quantity',
	'Required quantity': 'Required quantity',
	'Pattern details': 'Pattern details',
	'Project creation': 'Project creation',
	'Item deletion': 'Item deletion',
	'Are you sure you want to delete this item': 'Are you sure you want to delete this item',
	'Are you sure you want to delete that yarn': 'Are you sure you want to delete that yarn',
	'Project deletion': 'Project deletion',
	'Inspiration deletion': 'Inspiration deletion',
	'Active filters': 'Active filters',
	'New inspiration': 'New inspiration',
	'Status': 'Status',
	'Recipient': 'Recipient',
	'Started at': 'Started on',
	'Finished at': 'Finished on',
	'Needles size': 'Needles size',
	'Weight categories': 'Weight categories',
	'Stitches': 'Stitches',
	'Rows': 'Rows',
	'Gauge': 'Gauge',
	'Sample size': 'Sample size',
	'Project name': 'Project name',
	'Are you sure you want to delete that project': 'Are you sure you want to delete that project : {{projectName}}',
	'Recipients': 'Recipients',
	'Materials': 'Materials',
	'Pattern(s)': 'Pattern(s)',
	'External link': 'External link',
	'New project': 'New project',
	'Select': 'Select',
	'Create a project with these pattern and fabric': 'Create a project with these pattern and fabric',
	'Create a project with these pattern and yarn': 'Create a project with these pattern and yarn',
	'Additional informations': 'Additional informations',
	'Subscription': 'Subscription',
	'Privacy': 'Privacy',
	'Quantity required': 'Quantity required',
	'Report a bug': 'Report a bug',
	'How to add an image': 'How to add an image',
	'Close': 'Close',
	'Welcome to the demonstration space': 'Welcome to the demonstration space',
	'Demonstration home': 'Demonstration home',
	'characters': 'characters',
	'Pattern edition': 'Pattern edition',
	'Or': 'Or',
	'Fabric edition': 'Fabric edition',
	'By tags': 'By tags',
	'By categories': 'By categories',
	'By tag': 'By tag',
	'By category': 'By category',
	'My inspirations by tags': 'Inspirations by tags',
	'My inspirations by categories': 'Inspirations by categories',
	'Details': 'Details',
	'Inspiration details': 'Details',
	'Media type': 'Media type',
	'Image url': 'Image url',
	'Select or add': 'Select or add',
	'Projects': 'Projects',
	'Pattern creation': 'Pattern creation',
	'Load more': 'Load more',
	'My yarn': 'My yarn',
	'Yarns you can use for that pattern': 'Yarns you can use for that pattern',
	'We found no yarns in your collection for that pattern': 'We found no yarns in your collection for that pattern',
	'Yarn weight': 'Yarn weight',
	'Projects list': 'Projects list',
	'My creations': 'My creations',
	'Add new creation': 'Add new creation',
	'Project deleted': 'Project deleted',
	'Subject': 'Subject',
	'Get information': 'Get information',
	'Professional contact': 'Professional contact',
	'Edit project': 'Edit project',
	'New creation': 'New creation',
	'Bug report': 'Bug report',
	'If you have any question or want to report a bug send me a mail at': 'If you have any question or want to report a bug send me a mail at',
	'or use the contact form below': 'or use the contact form below',
	'Email address': 'Email address',
	'News': 'News',
	'Inspiration edition': 'Edition',
	'Free sewing patterns': 'Free sewing patterns',
	'Cotton yarn': 'Cotton yarn',
	'Shell stitch crochet inspirations': 'Shell stitch crochet inspirations"',
	'Always free': 'Always free',
	'No file': 'No file',
	'No video': 'No video',
	'Add': 'Add',
	'Add video': 'Add video',
	'Add file': 'Add file',
	'Open link': 'Open link',
	'Copy link': 'Copy link',
	'women': 'Women',
	'men': 'Men',
	'children': 'Children',
	'everyone': 'Everyone',
	'Links': 'Links',
	'Recommended yarn weight': 'Recommended yarn weight',
	'Change custom limits': 'Change custom limits',
	'Add news': 'Add news',
	'Disabled in demo mode': 'Disabled in demo mode',
	'Yarn edition': 'Edition',
	'Forget password': 'Forgotten password',
	'Signin': 'Signin',
	'Default': 'By default',
	'External': 'External',
	'Welcome to My Crafting Cloud': 'Welcome to My Crafting Cloud',
	'Have any suggestion to improve the app ? Leave a feedback, or like an existing feedback so that I can know what features you would like me to add': 'Have any suggestion to improve the app ? Leave a feedback, or like an existing feedback so that I can know what features you would like me to add!',
	'Add a note': 'Add a note',
	'No notes yet': 'No notes yet',
	'Created on': 'Created on {{date}}',
	'Last modified on': 'Last modified on {{date}}',
	'My designs': 'My designs',
	'Create new design': 'Create new design',
	'Create with': 'Create with',
	'Title': 'Titre'
};
