import { useState } from 'react';
import { FabricDetailsFragment } from '__generated__/graphql';
import ActiveProjectsListView from 'components/ActiveProjectsListView/ActiveProjectsListView';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';

interface FabricDetailsProjectsViewProps {
	fabric: FabricDetailsFragment;
}

const FabricDetailsActiveProjectsView = ({ fabric }: FabricDetailsProjectsViewProps) => {
	const { data } = useProjectsSearchEngine({ limit: 6, overrideFilters: { stashIds: [fabric.stashId] } });
	const [page, setPage] = useState(1);
	return <ActiveProjectsListView
		projects={data?.projects || []}
		total={data?.count || 0}
		page={page}
		onPageChange={page => setPage(page)}
		data={{
			type: 'fabric',
			fabric
		}}
	/>
	;

};


export default FabricDetailsActiveProjectsView;
