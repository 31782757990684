import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';
import { translate } from 'services/i18n';
import ProjectsListFiltersView from './ProjectsListFiltersView/ProjectsListFiltersView';
import ProjectsListResultsView from './ProjectsListResultsView/ProjectsListResultsView';

const ProjectsListView = () => {
	const { data } = useProjectsSearchEngine();

	return (
		<FlexContainer column>
			<ProjectsListFiltersView/>
			<Divider
				sx={{ marginTop: '2em' }}
				label={<strong>
					{translate({ key: 'Search results', data: { count: data?.count || 0 } })}
				</strong>}/>
			<ProjectsListResultsView/>
		</FlexContainer>
	);
};

export default ProjectsListView;
