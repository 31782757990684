import { Grid } from '@mui/material';

const GridContainer = ({ children, ...props }: any) => {
	return (
		<Grid container spacing={2} {...props} >
			{children}
		</Grid>
	);
};

export default GridContainer;
