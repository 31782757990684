import { ReactComponent as SewingNeedle } from 'assets/images/sewing-needle.svg';
import { ReactComponent as BallOfWool } from 'assets/images/yarn_ball.svg';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';
import styled from 'styled-components';
import HomepageEnglishView from './HomepageEnglishView';
import HomepageFrenchView from './HomepageFrenchView';
import HomepageItalianView from './HomepageItalianView';
import NewsListView from '../news/NewsListView';

const StyledContainer = styled(FlexContainer)`
  a {
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const StyledTitle = styled.h2`
  font-family: Parisienne;
  font-size: 4em;
  @media screen and (max-width: 1200px) {
    font-size: 3em;
  }
  @media screen and (max-width: 767px) {
    font-size: 1.2em;
  }

  svg {
    width: 0.8em !important;
  }
`;

const StyledSubtitleTitle = styled.h3`
  font-family: Parisienne;
  font-size: 3em;
  @media screen and (max-width: 767px) {
    font-size: 2em;
  }
`;

const HomepageView = () => {
	const userLanguage = getCurrentLanguage();
	return (
		<StyledContainer column>
			<StyledTitle>
				<BallOfWool/>&nbsp;{translate({ key: 'Welcome to My Crafting Cloud' })}&nbsp;<SewingNeedle/>
			</StyledTitle>
			{userLanguage === 'fr' && <HomepageFrenchView/>}
			{userLanguage === 'en' && <HomepageEnglishView/>}
			{userLanguage === 'ita' && <HomepageItalianView/>}
			<Divider label={<StyledSubtitleTitle>
				{translate({ key: 'News' })}
			</StyledSubtitleTitle>}/>
			<NewsListView language={userLanguage}/>
		</StyledContainer>
	);
};

export default HomepageView;
