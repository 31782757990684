import { useGetInspirationsListLazyQuery } from '__generated__/graphql';
import { translate } from 'services/i18n';
import AsyncAutoCompleteInput, { AsyncAutoCompleteInputOptionProps } from './AsyncAutoCompleteInput';
import { defaultPaginationParams } from '../../constants';

interface InspirationsAutoCompleteInputProps {
	value: AsyncAutoCompleteInputOptionProps[];
	onChange: (options: AsyncAutoCompleteInputOptionProps[]) => void;
}

const InspirationsAutoCompleteInput = ({ value, onChange }: InspirationsAutoCompleteInputProps) => {
	const [getInspirations] = useGetInspirationsListLazyQuery();
	return (
		<AsyncAutoCompleteInput
			label={translate({ key: 'Inspiration(s)' })}
			id="project-inspirations"
			value={value}
			fetchOptions={async (search: string) => {
				const { data } = await getInspirations({
					variables: {
						...defaultPaginationParams,
						filters: { name: search }
					}
				});
				return (data?.inspirations?.inspirations || []).map((inspiration) => ({
					imageUrl: inspiration.imageUrl,
					label: inspiration.name,
					resourceType: null,
					value: inspiration._id
				}));
			}}
			onChange={options => onChange(options)}
		/>
	);
};

export default InspirationsAutoCompleteInput;
