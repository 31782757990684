import { useEffect, useState } from 'react';
import { faBold, faItalic, faListOl, faListUl, faUnderline } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CharacterCount } from '@tiptap/extension-character-count';
import Underline from '@tiptap/extension-underline';
import { Editor, EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { FlexContainer } from 'components/base/FlexContainer';
import { useDebounce } from 'customHooks/utils/useDebounce';
import styled from 'styled-components';

const Label = styled.div`
  color: ${({ theme }) => `${theme.colors.darkgrey}!important`};
  font-weight: bold;
  margin-bottom: 0.5em;
`;

const CharacterCounter = styled.span`
  color: ${({ theme }) => `${theme.colors.lightgrey}!important`};
`;

const DEFAULT_LIMIT = 2000;
const MenuBar = ({ editor }: { editor: Editor }) => {
	if (!editor) {
		return null;
	}

	return (
		<div className="menuBar">
			<div>
				<button
					onClick={() => editor.chain().focus().toggleBold().run()}
					className={editor.isActive('bold') ? 'is_active' : ''}
				>
					<FontAwesomeIcon icon={faBold}/>
				</button>
				<button
					onClick={() => editor.chain().focus().toggleItalic().run()}
					className={editor.isActive('italic') ? 'is_active' : ''}
				>
					<FontAwesomeIcon icon={faItalic}/>
				</button>
				<button
					onClick={() => editor.chain().focus().toggleUnderline().run()}
					className={editor.isActive('underline') ? 'is_active' : ''}
				>
					<FontAwesomeIcon icon={faUnderline}/>
				</button>
				<button
					onClick={() => editor.chain().focus().toggleBulletList().run()}
					className={editor.isActive('bulletList') ? 'is_active' : ''}
				>
					<FontAwesomeIcon icon={faListUl}/>
				</button>
				<button
					onClick={() => editor.chain().focus().toggleOrderedList().run()}
					className={editor.isActive('orderedList') ? 'is_active' : ''}
				>
					<FontAwesomeIcon icon={faListOl}/>
				</button>
			</div>
		</div>
	);
};

const TextEditor = ({
	content,
	handleChange,
	title,
	limit
}: { content?: string | null, limit?: number, title?: string, handleChange: (text: string) => void }) => {

	const [editorContent, setEditorContent] = useState<string>(content || '');
	const debouncedContent = useDebounce<string>(editorContent, 500);
	useEffect(() => {
		handleChange(debouncedContent);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedContent]);

	const editor = useEditor({
		extensions: [
			StarterKit,
			Underline,
			CharacterCount.configure({
				limit: limit || DEFAULT_LIMIT,
			})],
		content: editorContent,
		onUpdate: ({ editor }) => {
			const html = editor.getHTML();
			setEditorContent(html);
		},
	});

	return (
		<FlexContainer className="w-100 align-items-start" column>
			<FlexContainer justifyContent="space-between">
				{title && <Label>{title}</Label>}
				<CharacterCounter>{`${editor?.storage?.characterCount?.characters() || 0}/${limit || DEFAULT_LIMIT} characters`}</CharacterCounter>
			</FlexContainer>
			{editor && <div className="textEditor w-100">
				<MenuBar editor={editor}/>
				<EditorContent editor={editor}/>
			</div>}
		</FlexContainer>
	);
};

export default TextEditor;
