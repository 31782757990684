import { Suspense } from 'react';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import NotFoundPage from 'pages/NotFoundPage';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/demonstration';
import RouteComponent from 'routes/RouteComponent';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/main';
import { DemonstrationProvider } from './DemonstrationContext';
import { DemonstrationPageHeader } from './DemonstrationPageHeader/DemonstrationPageHeader';
import { DemonstrationPageSidebar } from './DemonstrationPageSidebar/DemonstrationPageSidebar';
import MainPageBreadcrumbs from '../MainPage/MainPageBreadcrumbs/MainPageBreadcrumbs';
import {
	StyledBreadcrumbsContainer,
	StyledContent,
	StyledMainPage,
	StyledPageContainer,
	StyledRoutesContainer
} from '../MainPage/MainPageStyles';

export const DemonstrationPage = () => {
	const { isMediumScreen } = useScreenWidth();

	return <ThemeProvider theme={theme}>
		<DemonstrationProvider>
			<StyledMainPage className="demonstration">
				{!isMediumScreen && <DemonstrationPageSidebar/>}
				<StyledPageContainer>
					<DemonstrationPageHeader/>
					<div>
						<Suspense fallback={(() => <div/>)()}>
							<Routes>
								{routes.map((route) => (
									<Route
										key={route.path}
										path={route.path}
										element={<StyledRoutesContainer column>
											<StyledBreadcrumbsContainer>
												<MainPageBreadcrumbs route={route}/>
											</StyledBreadcrumbsContainer>
											<StyledContent>
												<RouteComponent
													Component={route.Component}
													needAuthentication={route.needAuthentication}/>
											</StyledContent>
										</StyledRoutesContainer>}
									/>
								))}
								<Route path="*" element={<NotFoundPage/>}/>
							</Routes>
						</Suspense>
					</div>
				</StyledPageContainer>
			</StyledMainPage>
		</DemonstrationProvider>
	</ThemeProvider>;
};
