import { Suspense } from 'react';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import NotFoundPage from 'pages/NotFoundPage';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/main';
import RouteComponent from 'routes/RouteComponent';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/main';
import MainPageBreadcrumbs from './MainPageBreadcrumbs/MainPageBreadcrumbs';
import MainPageFooter from './MainPageFooter/MainPageFooter';
import { MainPageHeader } from './MainPageHeader/MainPageHeader';
import { MainPageSidebar } from './MainPageSidebar/MainPageSidebar';
import {
	StyledBreadcrumbsContainer,
	StyledContent,
	StyledMainPage,
	StyledPageContainer,
	StyledRoutesContainer
} from './MainPageStyles';

export const MainPage = () => {
	const { isMediumScreen } = useScreenWidth();

	return <ThemeProvider theme={theme}>
		<StyledMainPage id={'main-page'}>
			{!isMediumScreen && <MainPageSidebar/>}
			<StyledPageContainer>
				<MainPageHeader/>
				<div>
					<Suspense fallback={(() => <div/>)()}>
						<Routes>
							{routes.map((route) => (
								<Route
									key={route.path}
									path={route.path}
									element={<StyledRoutesContainer column>
										<StyledBreadcrumbsContainer>
											<MainPageBreadcrumbs route={route}/>
										</StyledBreadcrumbsContainer>
										<StyledContent>
											<RouteComponent
												Component={route.Component}
												needAuthentication={route.needAuthentication}/>
										</StyledContent>
									</StyledRoutesContainer>}
								/>
							))}
							<Route path="*" element={<NotFoundPage/>}/>
						</Routes>
					</Suspense>
				</div>
				<MainPageFooter/>
			</StyledPageContainer>
		</StyledMainPage>
	</ThemeProvider>;
};
