import { v4 as uuid } from 'uuid';

export function updateStringArray(array: string[], value: string) {
	return array.includes(value) ?
		array.filter(element => element !== value) :
		[...array, value];
}

export function updateObjectArray(array: object[], value: object | null, index?: number) {
	if (typeof index === 'undefined' || index < 0) {
		return [...array, value];
	}
	const updatedArray = [...array];
	if (value) {
		updatedArray.splice(index, 1, value);
	} else {
		updatedArray.splice(index, 1);
	}
	return updatedArray;
}

export function getId() {
	return uuid();
}

export function isValidEmail(email: string) {
	const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	return !!email.match(regex);
}

export function dateToString(date: Date, locale: string = 'fr-FR') {
	return date.toLocaleDateString(locale, {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	});
}

export function dateToShortString(date: Date, locale: string = 'fr-FR') {
	return date.toLocaleDateString(locale, {
		year: 'numeric',
		month: 'short',
		day: 'numeric',
	});
}

export function isValidURL(testedUrl: string) {
	try {
		const url = new URL(testedUrl);
		return true;
	} catch (e) {
		return false;
	}
}

export function formatRange({ from, to }: { from?: number | null, to?: number | null, [key: string]: any }) {
	if (!from && !to) return null;
	return (!from || !to || from === to) ? (from || to).toString() : `${from} - ${to}`;
}

export function copyToClipboard(text: string) {
	navigator.clipboard.writeText(text).then(function () {
		console.log('Text copied to clipboard');
	}).catch(function (err) {
		console.error('Could not copy text: ', err);
	});
}
