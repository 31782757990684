import { YarnDetailsFragment } from '__generated__/graphql';
import Badge from 'components/base/Badge';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import { FlexContainer } from 'components/base/FlexContainer';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { translate } from 'services/i18n';
import { formatRange } from 'services/utils';

const YarnDetailsDescriptionView = ({ yarn }: { yarn: YarnDetailsFragment }) => {

	return <GridContainer>
		<FieldDescriptionBox
			label={translate({ key: 'Colors' })}
			content={<FlexContainer justifyContent="flex-start">
				{yarn.colors?.length ? yarn.colors.map(key => <Badge
					key={key}
					label={translate({ key })}/>
				) : '--'}
			</FlexContainer>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Weight categories' })}
			content={<>
				{yarn.weightCategories?.length ? yarn.weightCategories.join(' - ') : '--'}
			</>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Tags' })}
			content={<>
				{(yarn.tags || []).length ? yarn.tags.join(' - ') : '--'}
			</>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Needles size' })}
			content={formatRange(yarn.needlesSize) || '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Gauge' })}
			content={<FlexContainer column alignItems="flex-start">
				<div>{`${translate({ key: 'Sample size' })} : ${yarn.gauge.sampleSize || '--'}`}</div>
				<div>{`${translate({ key: 'Stitches' })} : ${yarn.gauge?.stitches || '--'} / 
				${translate({ key: 'Rows' })} : ${yarn.gauge?.rows || '--'} `}
				</div>
			</FlexContainer>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Composition' })}
			content={<>
				{yarn.composition?.length ? yarn.composition.join(' - ') : '--'}
			</>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Available quantity' })}
			content={yarn.quantity && yarn.quantityUnit ? (`${yarn.quantity} ${translate({ key: yarn.quantityUnit })}`) : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Shop' })}
			content={yarn.shop || '--'}/>
	</GridContainer>;
};

export default YarnDetailsDescriptionView;
