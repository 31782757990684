import { useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import RadioInput from 'components/Inputs/RadioInput';
import TextInput from 'components/Inputs/TextInput';
import ProjectImage from 'components/ProjectImage/ProjectImage';
import { useProjectFormContext } from 'customHooks/formContexts/useProjectFormContext';
import { translate } from 'services/i18n';

const ProjectFormImageView = () => {
	const FormContext = useProjectFormContext();
	const { state, handleChange } = FormContext;
	const [type, setType] = useState<'default' | 'external'>('default');
	return (
		<FlexContainer column>
			<div className="w-100 mb-1">
				<FlexContainer column>
					<div>{translate({ key: 'Image' })}</div>
					<RadioInput
						options={[
							{ value: 'default', label: translate({ key: 'Default' }) },
							{ value: 'external', label: translate({ key: 'External' }) },
						]}
						onChange={value => {
							setType(value);
							if (value === 'default' && state.imageUrl) {
								handleChange('imageUrl', null);
							}
						}}
						value={type}
						inputName={'project-image-type'}
					/>
				</FlexContainer>
				<TextInput
					value={state.imageUrl || ''}
					disabled={type !== 'external'}
					placeholder={translate({ key: 'Image url' })}
					onChange={url => handleChange('imageUrl', url)}
					id="external-project-image"/>
			</div>
			<ProjectImage project={state}/>
		</FlexContainer>
	);
};

export default ProjectFormImageView;
