import { useContext } from 'react';
import { DemonstrationContext } from 'pages/DemonstrationPage/DemonstrationContext';

const UseIsDemo = () => {
	const { isDemo } = useContext(DemonstrationContext) || { isDemo: false };

	return isDemo;
};

export default UseIsDemo;
