import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';
import styled from 'styled-components';
import ImageEnglishHelpPanel from './ImageEnglishHelpPanel';
import ImageFrenchHelpPanel from './ImageFrenchHelpPanel';
import ImageItalianHelpPanel from './ImageItalianHelpPanel';

const Container = styled(FlexContainer)`
  p {
    width: 100%;
  }`
;

const ImageHelpPanel = () => {
	const userLanguage = getCurrentLanguage();
	return (
		<FlexContainer column>
			<h2>{`${translate({ key: 'How to add an image' })} ?`}</h2>
			<Container column justifyContent="flex-start">
				{userLanguage === 'fr' && <ImageFrenchHelpPanel/>}
				{userLanguage === 'en' && <ImageEnglishHelpPanel/>}
				{userLanguage === 'ita' && <ImageItalianHelpPanel/>}
			</Container>
		</FlexContainer>
	);
};

export default ImageHelpPanel;
