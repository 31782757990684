import { useCallback } from 'react';
import { GetYarnsQueryVariables, useGetYarnsQuery } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import useIsDemo from '../data/useIsDemo';

const formatSearchParams = (searchParams: URLSearchParams) => {
	const name = searchParams.get('name');
	const composition = searchParams.get('composition');
	const colors = searchParams.get('colors');
	const quantity = searchParams.get('quantity');
	const weightCategories = searchParams.get('weightCategories');
	const page = searchParams.get('page');
	const needlesSize = searchParams.get('needlesSize');

	return {
		composition: composition?.split(',') || [],
		weightCategories: weightCategories?.split(',') || [],
		colors: colors?.split(',') || [],
		needlesSize: (needlesSize?.split(',') || []).map(size => +size),
		quantity: quantity ? +quantity : null,
		page: page ? +page : 1,
		name
	};
};

const useYarnsSearchEngine = ({
	limit,
	overrideFilters
}: { limit?: number, overrideFilters?: Partial<GetYarnsQueryVariables> & { page: number } } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const [searchParams, setSearchParams] = useSearchParams();

	const { loading, error, data, } = useGetYarnsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			...overrideFilters ? overrideFilters : formatSearchParams(searchParams),
			limit: LIMIT,
			isDemo
		}
	});

	const updateFilterValues = useCallback((field: keyof GetYarnsQueryVariables, newValue: string | null) => {
		if (newValue) {
			searchParams.set(field, newValue);
		} else {
			searchParams.delete(field);
		}
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);


	const updateUrl = useCallback((field: keyof GetYarnsQueryVariables, newValue: string | null) => {
		switch (field) {
		case 'quantity':
		case 'quantityUnit':
		case 'page':
		case 'name':
		case 'composition':
		case 'colors':
		case 'weightCategories':
		case 'needlesSize':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters: formatSearchParams(searchParams),
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		data: data?.yarns
	};
};

export default useYarnsSearchEngine;
