import { useState } from 'react';
import { AsyncAutoCompleteInputOptionProps } from 'components/Inputs/AsyncAutoCompleteInput';
import DateInput from 'components/Inputs/DateInput';
import InspirationsAutoCompleteInput from 'components/Inputs/InspirationsAutoCompleteInput';
import MaterialsAutoCompleteInput from 'components/Inputs/MaterialsAutoCompleteInput';
import MultiSelectInput from 'components/Inputs/MultiSelect';
import PatternsAutoCompleteInput from 'components/Inputs/PatternsAutoCompleteInput';
import SelectInput from 'components/Inputs/Select';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { useProjectFormContext } from 'customHooks/formContexts/useProjectFormContext';
import { translate } from 'services/i18n';


const ProjectFormContentView = () => {
	const FormContext = useProjectFormContext();
	const { state: project, handleChange } = FormContext;
	const { tags, projectStatuses, recipients, updateUserFilters } = useUserFilters();
	const [patterns, setPatterns] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.patterns || []).map(pattern => ({
		value: pattern._id,
		label: pattern.name,
		imageUrl: pattern.imageUrl,
		resourceType: pattern.patternType
	})));
	const [stash, setStash] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.stash || []).map(item => ({
		value: item._id,
		label: item.name,
		imageUrl: item.imageUrl,
		resourceType: item.itemType
	})));
	const [inspirations, setInspirations] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.inspirations || []).map(inspiration => ({
		value: inspiration._id,
		label: inspiration.name,
		imageUrl: inspiration.imageUrl,
		resourceType: null
	})));

	return (
		<GridContainer>
			<GridItem md={8}>
				<TextInput
					onChange={name => handleChange('name', name)}
					value={project.name}
					id={'project-name'}
					label={translate({ key: 'Name' })}/>
			</GridItem>
			<GridItem md={4}>
				<SelectInput
					data={projectStatuses}
					value={project.status ? {
						value: project.status,
						label: translate({ key: project.status })
					} : null}
					onChange={status => handleChange('status', status)}
					label={translate({ key: 'Status' })}/>
			</GridItem>
			<GridItem md={12}>
				<PatternsAutoCompleteInput
					value={patterns}
					onChange={options => {
						setPatterns(options);
						handleChange('patternIds', options.map(({ value }) => value));
						handleChange('patterns', options.map(({ imageUrl, label }) => ({ imageUrl, name: label })));
					}}
				/>
			</GridItem>
			<GridItem md={12}>
				<MaterialsAutoCompleteInput value={stash} onChange={options => {
					setStash(options);
					handleChange('stashIds', options.map(({ value }) => value));
				}}
				/>
			</GridItem>
			<GridItem md={12}>
				<InspirationsAutoCompleteInput
					value={inspirations}
					onChange={options => {
						setInspirations(options);
						handleChange('inspirationIds', options.map(({ value }) => value));
					}}
				/>
			</GridItem>
			<GridItem md={12}>
				<TextInput
					label={translate({ key: 'Tutorial url' })}
					value={project.tutorialUrl}
					onChange={url => handleChange('tutorialUrl', url)}
					id={'tutorialUrl'}
				/>
			</GridItem>
			<GridItem md={12}>
				<MultiSelectInput
					label={translate({ key: 'Tags' })}
					data={tags.map(value => ({ value, label: value }))}
					value={project.tags.map(value => ({ value, label: value }))}
					onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
					onChange={(tags) => handleChange('tags', tags)}/>
			</GridItem>
			<GridItem md={6}>
				<SelectInput
					label={translate({ key: 'Recipients' })}
					data={recipients.map(value => ({ value, label: value }))}
					creatable
					value={project.recipient ? { value: project.recipient, label: project.recipient } : null}
					onCreate={(recipient: string) => updateUserFilters({ recipients: [recipient] })}
					onChange={(recipient) => handleChange('recipient', recipient)}/>
			</GridItem>
			<GridItem md={3}>
				<DateInput
					label={translate({ key: 'Started at' })}
					value={project.startedAt ? new Date(project.startedAt) : null}
					onChange={startedAt => handleChange('startedAt', startedAt)}
				/>
			</GridItem>
			<GridItem md={3}>
				<DateInput
					label={translate({ key: 'Finished at' })}
					value={project.finishedAt ? new Date(project.finishedAt) : null}
					onChange={finishedAt => handleChange('finishedAt', finishedAt)}/>
			</GridItem>
			<GridItem md={12}>
				<TextEditor
					title={translate({ key: 'Notes' })}
					handleChange={notes => handleChange('notes', notes)}
					content={project.notes}
				/>
			</GridItem>
		</GridContainer>
	);
};

export default ProjectFormContentView;
