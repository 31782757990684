import { useCallback } from 'react';
import {
	FabricDetailsFragment,
	FabricInput,
	namedOperations,
	StashItemType,
	useSaveFabricMutation
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RadioInput from 'components/Inputs/RadioInput';
import FormContainerLayout from 'components/Layouts/FormContainerLayout/FormContainerLayout';
import { FormProvider } from 'customHooks/formContexts/useFabricFormContext';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { ValueOf } from 'types/core';
import { useImmerReducer } from 'use-immer';
import FabricFormDescriptionView from './FabricFormDescriptionView/FabricFormDescriptionView';
import { FabricFormReducer } from './FabricFormReducer';

interface FabricFormProps {
	fabric?: FabricDetailsFragment,
	formTitle?: string,
	onSwitchItemType?: (itemType: StashItemType) => void
}

export const FabricForm = ({
	fabric = {
		_id: null,
		currentProjectsIds: [],
		stashId: null,
		name: null,
		shop: null,
		quantity: null,
		type: [],
		colors: [],
		designs: [],
		composition: [],
		notes: null,
		tags: []
	}, formTitle, onSwitchItemType
}: FabricFormProps) => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const [state, dispatch] = useImmerReducer(FabricFormReducer, fabric);

	const handleChange = useCallback(
		(key: keyof FabricInput, value: ValueOf<FabricInput>) => {
			dispatch({ key, value });
		}, [dispatch]
	);
	const [saveFabric, { loading }] = useSaveFabricMutation({
		onError: err => {
			notifyError(err.message);
		},
		refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.getFabrics, namedOperations.Query.getStashList],
		onCompleted: ({ saveFabric: fabric }) => {
			navigate(`/fabrics/${fabric._id}`);
		}
	});


	const onSubmit = useCallback(async () => {
		const { __typename, stashId, currentProjectsIds, ...fabric } = state;
		await saveFabric({
			variables: {
				input: fabric
			},
		});
	}, [saveFabric, state]);

	return (<FormContainerLayout
		formTitle={formTitle}
		loading={loading}
		onSave={onSubmit}
		isValid={!!state.name}
		disabledMessage={translate({ key: 'You must give a name to the fabric' })}
	>
		<FormProvider value={{ state, handleChange }}>
			<FlexContainer>
				<RadioInput
					disabled={!onSwitchItemType}
					options={[
						{ label: translate({ key: 'Fabric' }), value: StashItemType.Fabric },
						{ label: translate({ key: 'Yarn' }), value: StashItemType.Yarn },
					]}
					onChange={(value: StashItemType) => onSwitchItemType(value)}
					value={StashItemType.Fabric}
					inputName={'Item type'}
				/>
			</FlexContainer>
			<FabricFormDescriptionView/>
		</FormProvider>
	</FormContainerLayout>
	);
};

