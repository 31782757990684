import { useState } from 'react';
import {
	FabricDetailsFragment,
	namedOperations,
	PatternDetailsFragment,
	useSaveProjectMutation
} from '__generated__/graphql';
import ActiveFiltersBox from 'components/base/ActiveFiltersBox';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import CheckboxInput from 'components/Inputs/Checkbox';
import CreateProjectModalContentLayout
	from 'components/Layouts/CreateProjectModalContentLayout/CreateProjectModalContentLayout';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import useFabricsSearchEngine from 'customHooks/searchEngine/useFabricsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';

const PatternDetailsFabricsView = ({ pattern }: { pattern: PatternDetailsFragment }) => {
	const isDemo = useIsDemo();
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [filterOnQuantity, setFilterOnQuantity] = useState(!!pattern.fabricRequirement);
	const [filterOnType, setFilterOnType] = useState(!!pattern.recommendedFabrics.length);
	const { data, limit } = useFabricsSearchEngine({
		limit: 6,
		overrideFilters: {
			type: filterOnType ? pattern.recommendedFabrics : null,
			page,
			//TODO - adjust
			quantity: filterOnQuantity ? pattern.fabricRequirement : null,
		}
	});
	const [saveProject, { loading: savingProject }] = useSaveProjectMutation({
		onCompleted: ({ saveProject }) => {
			navigate(`/projects/${saveProject._id}`);
		},
		refetchQueries: [namedOperations.Query.getProjectsList],
		onError: error => notifyError(error.message)
	});

	return <FlexContainer column className="w-100">
		<Divider label={translate({ key: 'Fabrics you can use for that pattern', data: { count: data?.count || 0 } })}/>
		<div className="ph-1 pv-1 w-100">
			<ActiveFiltersBox>
				<CheckboxInput
					checked={filterOnType}
					disabled={!pattern.recommendedFabrics.length}
					onChange={(checked) => setFilterOnType(checked)}
					label={translate({ key: 'Composition' })}
					id="pattern-fabric-composition"/>
				<CheckboxInput
					checked={filterOnQuantity}
					disabled={!pattern.fabricRequirement}
					onChange={(checked) => setFilterOnQuantity(checked)}
					label={translate({ key: 'Quantity' })}
					id="pattern-fabric-quantity"/>
			</ActiveFiltersBox>
			{!!data?.fabrics.length && (<ListLayout
				items={data.fabrics}
				currentPage={page}
				total={data.count}
				limit={limit}
				onPageChange={page => {
					setPage(page);
				}}
				component={(item: FabricDetailsFragment) => <ListGridItem
					navigateLink={`${isDemo ? '/demonstration' : ''}/fabrics/${item._id}`}
					item={{
						name: item.name,
						imageUrl: item.imageUrl
					}}
					deletable={false}
					projectCreatable
					initialProjectName={`${pattern.name} / ${item.name}`}
					modalTitle={'Project creation'}
					modalContent={<CreateProjectModalContentLayout
						headerText={'Create a project with these pattern and fabric'}
						stashImage={{ imageUrl: item.imageUrl, name: item.name }}
						patternImage={{ imageUrl: pattern.imageUrl, name: pattern.name }}
					/>}
					onModalConfirm={(name) => saveProject({
						variables: {
							input: {
								patternIds: [pattern._id],
								stashIds: [item.stashId],
								name
							}
						}
					})}/>}
			/>)}
			{!data?.fabrics.length && `${translate({
				key: 'We found no fabrics in your collection for that pattern'
			})
			}.`}
		</div>
	</FlexContainer>;
};

export default PatternDetailsFabricsView;
