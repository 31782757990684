import { useState } from 'react';
import { InspirationDetailsFragment } from '__generated__/graphql';
import ActiveProjectsListView from 'components/ActiveProjectsListView/ActiveProjectsListView';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';

interface InspirationDetailsProjectsViewProps {
	inspiration: InspirationDetailsFragment;
}

const InspirationDetailsActiveProjectsView = ({ inspiration }: InspirationDetailsProjectsViewProps) => {
	const { data } = useProjectsSearchEngine({ limit: 6, overrideFilters: { inspirationIds: [inspiration._id] } });
	const [page, setPage] = useState(1);
	return <ActiveProjectsListView
		projects={data?.projects || []}
		total={data?.count || 0}
		page={page}
		onPageChange={page => setPage(page)}
		data={{
			type: 'inspiration',
			inspiration
		}}
	/>;

};


export default InspirationDetailsActiveProjectsView;
