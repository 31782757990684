import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';

const ProjectHelpPanel = () => {
	const userLanguage = getCurrentLanguage();
	return (
		<FlexContainer column>
			<h2>{translate({ key: 'Projects' })}</h2>
			<FlexContainer column>
				<p>Vous pouvez créer un projet à partir de n&apos;importe quel élément ou combinaison d&apos;éléments
					ajoutés sur
					le site, patrons, matériel ou inspiration. L&apos;image d&apos;illustration se mettra à jour en
					fonction des
					éléments sélecitonnés.</p>
				<p>&nbsp;</p>
				<p><u>Comment créer un projet ?</u></p>
				<ul>
					<li>En cliquant sur “Ajouter un projet” via le menu de gauche&nbsp;</li>
					<li>À partir d&apos;une fiche patron, tissu, laine ou d&apos;une inspiration, en allant sur
						l&apos;onglet “Projet
						en cours”
					</li>
					<li>À partir d&apos;une fiche patron, via la liste des tissus/laines utilisables, un bouton “Créer
						projet” apparait au survol de l&apos;image.
					</li>
					<li>À partir d&apos;une fiche tissu ou laine, via la liste des patrons réalisables, un bouton
						“Créer
						projet” apparait au survol de l&apos;image.
					</li>
				</ul>
			</FlexContainer>
		</FlexContainer>
	);
};

export default ProjectHelpPanel;
