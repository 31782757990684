import { useState } from 'react';
import { namedOperations, useSaveBrandMutation } from '__generated__/graphql';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import TextInput from 'components/Inputs/TextInput';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  align-self: flex-end !important;
  margin-bottom: 1em;
`;
const BrandsListSubmitBrandForm = () => {
	const { notifyError, notifyInfo } = useNotifications();
	const [brandName, setBrandName] = useState('');
	const [brandWebsiteUrl, setBrandWebsiteUrl] = useState('');
	const [saveBrand] = useSaveBrandMutation({
		refetchQueries: [namedOperations.Query.getBrands],
		onError: (err) => {
			notifyError(err.message);
		},
		onCompleted: () => {
			notifyInfo(translate({ key: 'Brand submitted' }));
			setBrandName('');
			setBrandWebsiteUrl('');
		}
	});

	return (
		<Div className="pt">
			<div>{`${translate({ key: 'Did not find your favorite brand in the list? Submit it' })}!`}</div>
			<FlexContainer alignItems={'flex-end'}>
				<TextInput
					className="pr-1"
					onChange={brandName => setBrandName(brandName)}
					value={brandName}
					id={'new-brand-name'}
					label={translate({ key: 'Brand name' })}
				/>
				<TextInput
					className="pl-1 pr"
					onChange={brandWebsiteUrl => setBrandWebsiteUrl(brandWebsiteUrl)}
					value={brandWebsiteUrl}
					id={'brand-website-url'}
					label={translate({ key: 'Brand website url' })}
				/>
				<StyledButton
					label={translate({ key: 'Submit' })}
					onClick={async () => {
						await saveBrand({ variables: { input: { name: brandName, websiteUrl: brandWebsiteUrl } } });
					}}
					disabled={!brandWebsiteUrl?.length || !brandName?.length}
				/>
			</FlexContainer>
		</Div>
	);
};

export default BrandsListSubmitBrandForm;
