// @ts-nocheck
import { useCallback, useEffect, useState } from 'react';
import useUserProfile from 'customHooks/data/useUserProfile';

export default function useDrivePicker() {
	const [savedToken, setSavedToken] = useState<string | null>(null);
	const [pickedFile, setPickedFile] = useState(null);
	const { profile } = useUserProfile();

	useEffect(() => {
		window.gapi.load('auth', () => {
			console.log('auth loaded');
		});
		window.gapi.load('picker', () => {
			console.log('picker loaded');
		});
	}, []);

	const createPicker = useCallback((token: string, onPickFile: (result?) => void) => {
		const view = new google.picker.DocsView(google.picker.ViewId['DOCS'])
			.setIncludeFolders(true)
			.setParent('root');

		const uploadView = new google.picker.DocsUploadView()
			.setIncludeFolders(true)
			.setParent('/');

		const picker = new google.picker.PickerBuilder()
			.setAppId(process.env.REACT_APP_GOOGLE_APP_ID)
			.setOAuthToken(token)
			.setDeveloperKey(process.env.REACT_APP_GOOGLE_DEVELOPER_KEY)
			.setCallback((data) => {
				if (data.action === google.picker.Action.PICKED) {
					const file = data.docs[0];
					setPickedFile(({ name: file.name, url: file.url }));
					onPickFile(file);
				}
			})
			.setLocale(profile?.userLanguage || 'fr')
			.addView(view)
			.addView(uploadView)
			//.enableFeature(google.picker.Feature['NAV_HIDDEN'])
			.enableFeature(google.picker.Feature['SUPPORT_DRIVES']);

		picker.build().setVisible(true);

		return true;
	}, [profile]);

	const getAuthorizationToken = (): Promise<string> => new Promise((resolve, reject) => {
		window.gapi.auth.authorize(
			{
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				scope: ['https://www.googleapis.com/auth/drive.file'],
				immediate: false,
			},
			(authResult: { error?: string, access_token: string }) => {
				if (authResult?.error || !authResult) {
					reject('An error occured');
				}
				resolve(authResult.access_token);
			}
		);
	});

	const openPicker = async (onPickFile: (result?: any) => void) => {
		try {
			const token = savedToken || await getAuthorizationToken();
			setSavedToken(token);
			createPicker(token, onPickFile);
		} catch (err) {
			console.log(err);
		}
	};


	return { openPicker, pickedFile, resetPickedFile: () => setPickedFile(null) };
}
