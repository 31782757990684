import styled from 'styled-components';

export const Div = styled.div`
    text-align: justify;
    width: 100%;

    &.mt {
        margin-top: 2em;
    }
    &.mb {
        margin-bottom: 2em;
    }
    &.ml {
        margin-left: 2em;
    }
    &.mr {
        margin-right: 2em;
    }
`;
