import { namedOperations, useDeleteYarnMutation, useYarnQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import RemoteImage from 'components/base/RemoteImage';
import DetailsLayout from 'components/Layouts/DetailsLayout/DetailsLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import YarnDetailsActiveProjectsView from './YarnDetailsActiveProjectsView/YarnDetailsActiveProjectsView';
import YarnDetailsDescriptionView from './YarnDetailsDescriptionView/YarnDetailsDescriptionView';
import YarnDetailsProjectsView from './YarnDetailsProjectsView/YarnDetailsProjectsView';
import DeleteYarnModal from '../DeleteYarnModal/DeleteYarnModal';

const YarnDetailsView = () => {
	const { notifyError } = useNotifications();
	const isDemo = useIsDemo();
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const [deleteYarn, { loading: deletingYarn }] = useDeleteYarnMutation({
		variables: { yarnId: id },
		refetchQueries: [namedOperations.Query.getYarns],
		onCompleted: () => {
			navigate('/yarn');
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	const { loading, data } = useYarnQuery({
		variables: { id, isDemo },
		onError: (err) => {
			notifyError(err.message);
		}
	});

	return (
		<FlexContainer column>
			{(loading || deletingYarn) && <LoadingOverlay/>}
			{data?.yarn && (<DetailsLayout
				deleteModalTitle={translate({ key: 'Item deletion' })}
				onDeleteConfirm={() => deleteYarn()}
				deleteModalContent={<DeleteYarnModal yarn={data.yarn}/>}
				editLink={`${isDemo ? '/demonstration' : ''}/yarn/${data.yarn._id}/edit`}
				itemName={data.yarn?.name || 'Unnamed yarn'}
				imgContent={<RemoteImage src={data.yarn?.imageUrl || null} alt={data.yarn.name}/>}
				mainContent={<YarnDetailsDescriptionView yarn={data.yarn}/>}
				notes={data.yarn.notes}
				relatedEntitiesTitle={translate({
					key: 'Related projects',
					data: { count: data.yarn.currentProjectsIds.length }
				})}
				relatedEntitiesContent={<YarnDetailsActiveProjectsView yarn={data.yarn}/>}
			>
				<YarnDetailsProjectsView yarn={data.yarn}/>
			</DetailsLayout>)}
		</FlexContainer>
	);
};

export default YarnDetailsView;
