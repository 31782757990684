import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import useIsDemo from 'customHooks/data/useIsDemo';
import HelpPanel from 'pages/HelpPanel';
import { useNavigate } from 'react-router-dom';
import { RouteProps } from 'routes/main';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const BreadcrumbsContainer = styled(FlexContainer)`
  @media screen and (max-width: 767px) {
    li, h6 {
      font-size: 0.8em;
    }
  }
`;

const MainPageBreadcrumbs = ({ route: { HelpPanelContent, ...route } }: { route: RouteProps }) => {
	const navigate = useNavigate();
	const isDemo = useIsDemo();
	if (route.path === '/') return;
	const breadcrumbs = [
		<Link
			sx={{
				'&:hover': {
					cursor: 'pointer'
				}
			}}
			underline="hover"
			key={0}
			color="inherit"
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				navigate(isDemo ? '/demonstration' : '/');
			}}>
			{translate({ key: isDemo ? 'Demonstration home' : 'Home' })}
		</Link>
	].concat((route.breadcrumbs || []).map((breadcrumb, index) => <Link
		sx={{
			'&:hover': {
				cursor: 'pointer'
			}
		}}
		underline="hover"
		key={index + 1}
		color="inherit"
		onClick={e => {
			e.preventDefault();
			e.stopPropagation();
			navigate(breadcrumb.link);
		}}>
		{translate({ key: breadcrumb.name })}
	</Link>)).concat([
		<Typography key={(route.breadcrumbs || []).length + 1} color="main" variant="h6" sx={{ fontWeight: 'bold' }}>
			{translate({ key: route.name })}
		</Typography>
	]);
	return (
		<BreadcrumbsContainer justifyContent="space-between" className={'w-100'}>
			<Stack spacing={2}>
				<Breadcrumbs separator="›" aria-label="breadcrumb">
					{breadcrumbs}
				</Breadcrumbs>
			</Stack>
			{HelpPanelContent && <HelpPanel panelName={route.panelName}>
				<HelpPanelContent/>
			</HelpPanel>}
		</BreadcrumbsContainer>
	);
};

export default MainPageBreadcrumbs;
