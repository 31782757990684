import styled from 'styled-components';

export const StyledAdminPage = styled.div`
    height: 100vh;
    display: flex;
    h1 {
        margin: 0;
        padding: 0 0 0.67em 0;
        font-size: 20px;
        font-weight: bold;
    }
`;

export const StyledContainer = styled.div`
    height: fit-content;
    width: 100%;
    margin-left: 20em;
    @media screen and (max-width: 1200px) {
      margin-left: 0;
    }
`;

export const StyledContent = styled.div`
    padding: 2em;
    margin: 6em 2em 2em 2em;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.5em;
    min-height: 60vh;
`;
