import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FlexContainer } from './FlexContainer';

const StyledLogoContainer = styled.div`
  margin-bottom: 0.5em;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 2em !important;
  text-shadow: 1px 1px 2px lightcyan;
  font-weight: bold;
  font-family: Parisienne;

  a {
    height: 2em;
    color: white;
    white-space: nowrap;

    &:hover {
      color: lightcyan;
    }
  }
`;

const Logo = () => {
	return (
		<StyledLogoContainer>
			<Link to="/">
				<FlexContainer alignItems="flex-start">
					<div>☁&nbsp;</div>
					<div>My Crafting Cloud</div>
				</FlexContainer>
			</Link>
		</StyledLogoContainer>
	);
};

export default Logo;
