import { createContext, useContext } from 'react';
import { FileInput, YarnDetailsFragment, YarnInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export interface IYarnFormContext {
	state: YarnDetailsFragment & { hostedImage?: FileInput };
	handleChange: (name: keyof YarnInput, value: ValueOf<YarnInput>) => void;
}

export const FormContext = createContext<null | IYarnFormContext>(null);

export const FormProvider = FormContext.Provider;

export function useYarnFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useYarnFormContext must be used within a FormContextProvider');
	}
	return context;
}

