const ImageFrenchHelpPanel = () => {
	return <>
		<p><u>Méthode 1 : Copier/Coller l&apos;url d&apos;une image à partir d&apos;un site</u></p>
		<p>Pour cette méthode, il suffit de faire un clic droit sur l&apos;image que vous souhaitez ajouter puis
			de
			cliquer sur &#8220;Copier l&apos;adresse de l&apos;image&#8220; dans le menu. Vous pouvez ensuite
			coller
			le lien dans le champ &#8220;Url de l&apos;image&#8220;.</p>
		<p>Cette méthode est la plus simple et la plus rapide pour ajouter une image.</p>
		<p><u>Méthode 2 : Sauvegarder l&apos;image sur un hébergeur d&apos;image gratuit</u></p>
		<p>Vous pouvez également enregistrer l&apos;image sur un site d&apos;hébergement d&apos;image gratuit.
			Certains sites
			permettent de sauvegarder une image directement à partir d&apos;une url, d&apos;autres vous
			demanderont
			de fournir
			l&apos;image à partir de votre ordinateur. Pour enregistrer une image sur votre ordinateur, faites
			un
			clic droit sur l&apos;image puis &#8220;Enregistrer l&apos;image sous&#8220;. Une fois l&apos;image
			sauvegardée sur le site
			d&apos;hébergement, vous pouvez récupérer son url et la copier dans le champ&nbsp;&#8220;Url de
			l&apos;image&#8220; (cf méthode 1).</p>
		<p>Cette méthode est plus longue mais les images ne seront jamais perdues tant qu&apos;elles sont
			hébergées
			par le site. Vous pouvez l&apos;utiliser pour stocker des images de vos tissus.</p>
		<p><u>Méthode 3 : Enregistrer l&apos;image sur le site</u></p>
		<p>En cliquant sur l&apos;icône, vous pouvez charger une image de votre ordinateur pour pouvoir
			l&apos;héberger sur le site.
			Vous pouvez également vous connectant au site sur un téléphone pour
			prendre une photo. Cette
			méthode vous permettra de sauver une image de vos tissus ou laines sans avoir à passer par un site
			d&apos;hébergement.</p>
	</>;
};

export default ImageFrenchHelpPanel;
