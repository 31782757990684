import { lazy } from 'react';
import DemonstrationHomepage from 'views/demonstration/DemonstrationHomepage';
import FabricDetailsView from 'views/fabrics/FabricDetailsView/FabricDetailsView';
import FabricsListView from 'views/fabrics/FabricsListView/FabricsListView';
import InspirationDetailsView from 'views/inspirations/InspirationDetailsView/InspirationDetailsView';
import InspirationsListView from 'views/inspirations/InspirationsListView/InspirationsListView';
import PatternCreationView from 'views/patterns/PatternCreationView/PatternCreationView';
import ProjectDetailsView from 'views/projects/ProjectDetailsView/ProjectDetailsView';
import ProjectsListView from 'views/projects/ProjectsListView/ProjectsListView';
import StashListView from 'views/stash/StashListView';
import YarnDetailsView from 'views/yarns/YarnDetailsView/YarnDetailsView';
import YarnsListView from 'views/yarns/YarnsListView/YarnsListView';
import { RouteProps } from './main';
import CreationCreationView from '../views/creations/CreationCreationView/CreationCreationView';
import CreationDetailsView from '../views/creations/CreationDetailsView/CreationDetailsView';
import CreationEditView from '../views/creations/CreationEditView/CreationEditView';
import CreationsListView from '../views/creations/CreationsListView/CreationsListView';
import FabricEditView from '../views/fabrics/FabricEditView/FabricEditView';
import InspirationHelpPanel from '../views/inspirations/InspirationHelpPanel/InspirationHelpPanel';
import PatternEditView from '../views/patterns/PatternEditView/PatternEditView';
import PatternFormHelpPanel from '../views/patterns/PatternForm/PatternFormHelpPanel/PatternFormHelpPanel';
//import PatternsListHelpPanel from '../views/patterns/PatternsListView/PatternsListHelpPanel/PatternsListHelpPanel';
import ProjectHelpPanel from '../views/projects/ProjectHelpPanel';
import StashItemCreationView from '../views/stash/StashItemCreationView/StashItemCreationView';
import YarnEditView from '../views/yarns/YarnEditView/YarnEditView';
//import StashHelpPanel from '../views/stash/StashHelpPanel';

const PatternDetailsView = lazy(() => import( 'views/patterns/PatternDetailsView/PatternDetailsView'));
const PatternsListView = lazy(() => import( 'views/patterns/PatternsListView/PatternsListView'));

export default [
	{ path: '/', name: 'Demonstration page', Component: DemonstrationHomepage },
	{
		path: 'patterns', name: 'My patterns', Component: PatternsListView,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/sewing', name: 'Sewing patterns', Component: PatternsListView,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/knit', name: 'Knit patterns', Component: PatternsListView,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/crochet', name: 'Crochet patterns', Component: PatternsListView,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/other', name: 'Other patterns', Component: PatternsListView,
		//HelpPanelContent: PatternsListHelpPanel,
		panelName: 'patternList'
	},
	{
		path: 'patterns/:id',
		name: 'Pattern details',
		Component: PatternDetailsView,
		breadcrumbs: [{ name: 'My patterns', link: '/demonstration/patterns' }]
	},
	{
		path: 'patterns/:id/edit',
		name: 'Pattern edition',
		Component: PatternEditView,
		breadcrumbs: [{ name: 'My patterns', link: '/demonstration/patterns' }],
		HelpPanelContent: PatternFormHelpPanel,
		panelName: 'patternForm'
	},
	{
		path: 'patterns/new',
		name: 'New pattern',
		Component: PatternCreationView,
		breadcrumbs: [{ name: 'My patterns', link: '/demonstration/patterns' }],
		HelpPanelContent: PatternFormHelpPanel,
		panelName: 'patternForm'
	},
	{
		path: 'stash',
		name: 'My stash',
		Component: StashListView,
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'stash/new',
		name: 'New item',
		Component: StashItemCreationView,
		//HelpPanelContent: StashHelpPanel
	},
	{
		path: 'fabrics',
		name: 'Fabrics',
		Component: FabricsListView,
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'fabrics/:id',
		name: 'Fabric details',
		Component: FabricDetailsView,
		breadcrumbs: [{ name: 'My fabrics', link: '/demonstration/fabrics' }],
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'fabrics/:id/edit',
		name: 'Fabric edition',
		Component: FabricEditView,
		breadcrumbs: [{ name: 'My fabrics', link: '/demonstration/fabrics' }],
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'yarn',
		name: 'Yarn',
		Component: YarnsListView,
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'yarn/:id',
		name: 'Yarn details',
		Component: YarnDetailsView,
		breadcrumbs: [{ name: 'My yarn', link: '/demonstration/yarn' }],
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'yarn/:id/edit',
		name: 'Yarn edition',
		Component: YarnEditView,
		breadcrumbs: [{ name: 'My yarn', link: '/demonstration/yarn' }],
		//HelpPanelContent: StashHelpPanel,
		panelName: 'stash'
	},
	{
		path: 'inspirations',
		name: 'Inspirations',
		Component: InspirationsListView,
		HelpPanelContent: InspirationHelpPanel,
		panelName: 'inspiration'
	},
	{
		path: 'inspirations/:id',
		name: 'Inspiration details',
		Component: InspirationDetailsView,
		breadcrumbs: [{ name: 'My inspirations', link: '/demonstration/inspirations' }],
		HelpPanelContent: InspirationHelpPanel,
		panelName: 'inspiration'
	},
	{
		path: 'projects',
		name: 'My projects',
		Component: ProjectsListView,
		HelpPanelContent: ProjectHelpPanel,
		panelName: 'project'
	},
	{
		path: 'projects/:id',
		name: 'Project details',
		Component: ProjectDetailsView,
		breadcrumbs: [{ name: 'My projects', link: '/demonstration/projects' }],
		HelpPanelContent: ProjectHelpPanel,
		panelName: 'project'
	},
	{
		path: 'creations',
		name: 'My creations',
		Component: CreationsListView,
		needAuthentication: true,
	},
	{
		path: 'creations/new',
		name: 'New creation',
		Component: CreationCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
	{
		path: 'creations/:id',
		name: 'Creation details',
		Component: CreationDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
	{
		path: 'creations/:id/edit',
		name: 'Edit creation',
		Component: CreationEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My creations', link: '/creations' }],
	},
] as RouteProps[];
