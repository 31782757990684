import { QuantityUnit } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import HostedImageInput from 'components/Inputs/ImageInput/HostedImageInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import QuantityInput from 'components/Inputs/QuantityInput';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { useFabricFormContext } from 'customHooks/formContexts/useFabricFormContext';
import { translate } from 'services/i18n';

const FabricFormDescriptionView = () => {
	const FormContext = useFabricFormContext();
	const { state: fabric, handleChange } = FormContext;
	const { loading, fabrics, colors, designs, composition, updateUserFilters, tags } = useUserFilters();

	return fabric ? (
		<>
			{loading && <LoadingOverlay/>}
			<FormLayout
				imgContent={<HostedImageInput
					id="fabric-image"
					remoteUrl={fabric.imageUrl}
					image={fabric.hostedImage || null}
					onChange={(image) => {
						handleChange('hostedImage', image);
						handleChange('imageUrl', '');
					}}
					onRemoteUrlChange={(url) => {
						handleChange('imageUrl', url);
					}}
				/>}
				mainContent={<GridContainer>
					<GridItem>
						<TextInput
							className="pt-0"
							value={fabric.name || ''}
							onChange={(name) => handleChange('name', name)}
							id="fabric-name"
							label={translate({ key: 'Name' })}
						/>
					</GridItem>
					<GridItem>
						<QuantityInput
							label="Available quantity"
							onChange={({ quantity, unit }) => {
								handleChange('quantity', quantity);
								handleChange('quantityUnit', unit);
							}
							}
							quantity={fabric.quantity}
							unit={fabric.quantityUnit || QuantityUnit.Meters}/>
					</GridItem>
					<GridItem>
						<MultiSelect
							onChange={types => handleChange('type', types)}
							data={fabrics}
							value={fabric.type.map(value => ({ value, label: value }))}
							creatable
							onCreate={(fabric: string) => updateUserFilters({ 'recommendedFabrics': [fabric] })}
							label={translate({ key: 'Fabric types' })}
						/>
					</GridItem>
					<GridItem>
						<MultiSelect
							onChange={colors => handleChange('colors', colors)}
							data={colors.map(value => ({ value, label: value }))}
							value={fabric.colors.map(value => ({ value, label: value }))}
							creatable
							onCreate={(colors: string) => updateUserFilters({ 'colors': [colors] })}
							label={translate({ key: 'Colors' })}
						/>
					</GridItem>
					<GridItem>
						<MultiSelect
							onChange={designs => handleChange('designs', designs)}
							data={designs.map(value => ({ value, label: value }))}
							value={fabric.designs.map(value => ({ value, label: value }))}
							creatable
							onCreate={(design: string) => updateUserFilters({ 'designs': [design] })}
							label={translate({ key: 'Design/Pattern' })}
						/>
					</GridItem>
					<GridItem>
						<MultiSelect
							onChange={composition => handleChange('composition', composition)}
							data={composition.map(value => ({ value, label: value }))}
							value={fabric.composition.map(value => ({ value, label: value }))}
							creatable
							onCreate={(composition: string) => updateUserFilters({ 'composition': [composition] })}
							label={translate({ key: 'Composition' })}
						/>
					</GridItem>
					<GridItem xs={12} md={6}>
						<MultiSelect
							label={translate({ key: 'Tags' })}
							onChange={(tags) => {
								handleChange('tags', tags);
							}}
							value={fabric.tags.map(value => ({ value, label: value }))}
							creatable
							onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
							data={tags.map(value => ({ value, label: value }))}/>
					</GridItem>
					<GridItem>
						<TextInput
							onChange={(shop) => handleChange('shop', shop)}
							id="fabric-shop"
							value={fabric.shop || ''}
							label={translate({ key: 'Shop' })}
						/>
					</GridItem>
					<GridItem xs={12} md={12}>
						<TextEditor
							content={fabric.notes || ''}
							handleChange={notes => handleChange('notes', notes)}
							title={translate({ key: 'Notes' })}/>
					</GridItem>
				</GridContainer>
				}
			>
			</FormLayout>
		</>
	) : <></>;
};

export default FabricFormDescriptionView;
