import { useNewsQuery } from '__generated__/graphql';
import { useParams } from 'react-router-dom';
import NewsFormView from './NewsFormView';

const NewsEditView = () => {
	const { newsId } = useParams<'newsId'>();
	const { data } = useNewsQuery({ variables: { id: newsId }, skip: !newsId });

	return data?.news ? (
		<NewsFormView
			en={data.news.en}
			fr={data.news.fr}
			ita={data.news.ita}
			spa={data.news.spa}
			newsDate={new Date(data.news.date)}
			id={newsId}
		/>
	) : <></>;
};

export default NewsEditView;
