import { createContext, useContext } from 'react';
import {
	InspirationDetailsFragment,
	PatternDetailsFragment,
	ProjectDetailsFragment,
	ProjectInput,
	StashDetailsFragment
} from '__generated__/graphql';
import { ValueOf } from 'types/core';

export interface ExtendedProjectInput extends ProjectInput {
	patterns?: Partial<PatternDetailsFragment>[];
	inspirations?: Partial<InspirationDetailsFragment>[];
	stash?: Partial<StashDetailsFragment>[];
}

interface ProjectFormContextProps {
	state: ProjectDetailsFragment;
	handleChange: (name: keyof ExtendedProjectInput, value: ValueOf<ExtendedProjectInput> | undefined | null) => void;
}

export const FormContext = createContext<null | ProjectFormContextProps>(null);

export const FormProvider = FormContext.Provider;

export function useProjectFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useProjectFormContext must be used within a FormContextProvider');
	}
	return context;
}
