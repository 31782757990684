import imageCompression from 'browser-image-compression';

const UseImageCompressor = () => {
	const options = {
		maxSizeMB: 0.1,
		maxWidthOrHeight: 1000
	};

	return { compressImage: async (file: File, optionsOverride: { maxSizeMB?: number, maxWidthOrHeight?: number } = {}) => imageCompression(file, { ...options, ...optionsOverride }) };
};

export default UseImageCompressor;
