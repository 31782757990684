import { useCallback } from 'react';
import Badge, { IColors } from 'components/base/Badge';
import { FlexContainer } from 'components/base/FlexContainer';
import { Label } from 'components/base/Label';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useUserFilters from 'customHooks/data/useUserFilters';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { StyledBadges, StyledFilterView } from './GlobalFiltersViewStyles';

const GlobalFiltersView = () => {
	const navigate = useNavigate();
	const { loading, fabrics, categories, tags, patternLanguage, boughtIn, brands } = useUserFilters();

	const availableFormats: { value: string, label: string, color: keyof IColors }[] = [
		{ value: 'a4', label: 'A4', color: 'blue' },
		{ value: 'a4-layered', label: 'A4 with layers', color: 'blue' },
		{ value: 'us-letter', label: 'US Letter', color: 'orange' },
		{ value: 'us-letter-layered', label: 'US letter with layers', color: 'orange' },
		{ value: 'a0', label: 'A0', color: 'green' },
		{ value: 'a0-layered', label: 'A0 with layers', color: 'green' },
		{ value: 'projector', label: 'Projector', color: 'pink' },
		{ value: 'projector-layered', label: 'Projector with layers', color: 'pink' },
		{ value: 'custom-projector', label: 'Custom projector', color: 'limo' },
		{ value: 'paper', label: 'Paper', color: 'blue' }
	];
	const destinedTo = [
		{ value: 'everyone', label: 'Everyone' },
		{ value: 'women', label: 'Women' },
		{ value: 'men', label: 'Men' },
		{ value: 'children', label: 'Children' },
	];

	const onFilterClick = useCallback((filter:string, value:string) => {
		navigate({
			pathname: '/patterns',
			search: `?${encodeURIComponent(filter)}=${encodeURIComponent(value)}`
		});
	}, [navigate]);

	return <div className="w-100 border-bottom">
		{loading && <LoadingOverlay />}
		<FlexContainer column className="align-items-start pv-1 border-bottom">
			<Label className="pb-1">{translate({ key: 'Filters' })} </Label>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Available formats' })}</FlexContainer>
				<StyledBadges>
					{availableFormats.map(format => (
						<Badge
							className="filter-badge"
							key={format.value}
							label={translate({ key: format.label })}
							color={format.color}
							onClick={() => { onFilterClick('availableFormats', format.value); }}
						/>
					))}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Destined to' })}</FlexContainer>
				<StyledBadges>
					{destinedTo.map(({ label, value }) => (
						<Badge
							className="filter-badge"
							key={value}
							label={translate({ key: label })}
							onClick={() => { onFilterClick('destinedTo', value); }}
						/>
					))}
				</StyledBadges>
			</StyledFilterView>
		</FlexContainer>
		<FlexContainer column className="align-items-start pv-1">
			<Label className="pb-1">{translate({ key: 'Custom filters' })}</Label>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Categories' })}</FlexContainer>
				<StyledBadges>
					{categories.length ? categories.map(category => (
						<Badge
							className="filter-badge"
							key={category}
							label={category}
							color="blue"
							onClick={() => { onFilterClick('categories', category); }}
						/>
					)) : '--'}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Brands' })}</FlexContainer>
				<StyledBadges>
					{brands.length ? brands.map(brand => (
						<Badge
							className="filter-badge"
							key={brand}
							label={brand}
							color="blue"
							onClick={() => { onFilterClick('brands', brand); }}
						/>
					)) : '--'}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Tags' })}</FlexContainer>
				<StyledBadges>
					{tags.length ? tags.map(tag => (
						<Badge
							className="filter-badge"
							key={tag}
							label={`#${tag}`}
							color="green"
							onClick={() => { onFilterClick('tags', tag); }}
						/>)) : '--'}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Recommended fabrics' })}</FlexContainer>
				<StyledBadges>
					{fabrics.length ? fabrics.map(fabric => (
						<Badge
							className="filter-badge"
							key={fabric.value}
							label={fabric.label}
							color="pink"
							onClick={() => { onFilterClick('recommendedFabrics', fabric.value); }}
						/>)) : '--'}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Language' })}</FlexContainer>
				<StyledBadges>
					{patternLanguage.length ? patternLanguage.map(patternLanguage => (
						<Badge
							className="filter-badge"
							key={patternLanguage}
							label={patternLanguage}
							color="orange"
							onClick={() => { onFilterClick('patternLanguage', patternLanguage); }}
						/>)) : '--'}
				</StyledBadges>
			</StyledFilterView>
			<StyledFilterView>
				<FlexContainer className="justify-start pb-1">{translate({ key: 'Bought in' })}</FlexContainer>
				<StyledBadges>
					{boughtIn.length ? boughtIn.map(date => (
						<Badge
							className="filter-badge"
							key={date}
							label={date}
							color="blue"
							onClick={() => { onFilterClick('boughtIn', date); }}
						/>)) : '--'}
				</StyledBadges>
			</StyledFilterView>
		</FlexContainer>
	</div>;
};

export default GlobalFiltersView;
