import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import Logo from 'components/base/Logo';
import {
	StyledHeader,
	StyledMainPageSidebar,
	StyledMainPageSidebarMenuItem,
	StyledMenu
} from 'pages/PageSidebarStyles';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';


const StyledGoBack = styled.div`
  color: white;
  font-size: 1.1em;
  margin-bottom: 1em;

  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
export const DemonstrationPageSidebar = () => {
	const location = useLocation();

	const mainNavigation = [
		{ path: '/demonstration/patterns', name: 'My patterns', sub: false },
		{ path: '/demonstration/patterns/new', name: 'Add new pattern', sub: true },
		{ path: '/demonstration/patterns/sewing?patternType=sewing', name: 'Sewing', sub: true },
		{ path: '/demonstration/patterns/crochet?patternType=crochet', name: 'Crochet', sub: true },
		{ path: '/demonstration/patterns/knit?patternType=knit', name: 'Knit', sub: true },
		{ path: '/demonstration/patterns/other?patternType=other', name: 'Other', sub: true },
		{ path: '/demonstration/stash', name: 'My stash', sub: false },
		{ path: '/demonstration/stash/new', name: 'Add new item', sub: true },
		{ path: '/demonstration/fabrics', name: 'Fabrics', sub: true },
		{ path: '/demonstration/yarn', name: 'Yarn', sub: true },
		{ path: '/demonstration/inspirations', name: 'My inspirations', sub: false },
		{ path: '/demonstration/inspirations/new', name: 'Add new inspiration', sub: true },
	];
	const projectsAndCreationsNavigation = [
		{ path: '/demonstration/projects', name: 'Projects list', sub: false },
		{ path: '/demonstration/projects/new', name: 'Add new project', sub: true },
		{ path: '/demonstration/creations', name: 'My creations', sub: false },
		{ path: '/demonstration/creations/new', name: 'Add new creation', sub: true },

	];
	return <StyledMainPageSidebar className="demonstration">
		<Logo/>
		<StyledMenu column justifyContent="space-between" className="h-100">
			<FlexContainer column style={{ paddingLeft: '2em' }}>
				<Div>
					<StyledHeader>{translate({ key: 'My library' })}</StyledHeader>
					{mainNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={!!route.sub}
							$active={location.pathname === (route.path.split('?')[0])}>
							<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
				<Div>
					<StyledHeader className="mt-1">{translate({ key: 'My projects' })}</StyledHeader>
					{projectsAndCreationsNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={!!route.sub}
							$active={location.pathname === (route.path.split('?')[0])}>
							<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
			</FlexContainer>
			<FlexContainer justifyContent="flex-start">
				<Link to={'/'}>
					<StyledGoBack>
						<FontAwesomeIcon icon={faAnglesLeft} className="mr-1"/>
						<span>{translate({ key: 'Go back to main site' })}</span>
					</StyledGoBack>
				</Link>
			</FlexContainer>
		</StyledMenu>
	</StyledMainPageSidebar>;
};
