import { FlexContainer } from 'components/base/FlexContainer';

const PatternFormEnglishHelpPanel = () => {
	return (
		<FlexContainer column>
			<p>When you add a pattern to your library, you can add as much categories, tags and other informations as
				you want. The search engine will automatically
				be updated with the informations you provided, so feel free to add as much details as possible to be
				able to easily filter your patterns.
			</p>
			<p>Additionally, by filling the type and quantity of materials you need for a pattern, you will get the list
				of the matching materials from your inventory and be able to create a project with that combo in one
				click.
			</p>
		</FlexContainer>
	);
};

export default PatternFormEnglishHelpPanel;
