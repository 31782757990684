import { ReactNode, useState } from 'react';
import { Collapse } from '@mui/material';
import styled from 'styled-components';
import { FlexContainer } from './FlexContainer';
import { Label } from './Label';

interface IExpendableContainer {
	initiallyOpen?: boolean;
	boxed?: boolean;
	label: string;
	children: ReactNode;
	className?: string;
}

const StyledLabel = styled(Label)`
  text-transform: uppercase;
  margin: 0;
`;

const StyledHeader = styled(FlexContainer)<{ $boxed: boolean }>`
  border-bottom: ${({ $boxed, theme }) => $boxed ? '' : `1px solid ${theme.colors.lightgrey}`};
  padding: 1em 0;
  width: 100%;
  justify-content: space-between;
`;

const StyledContainer = styled(FlexContainer)<{ $boxed: boolean }>`
  border: ${({ $boxed, theme }) => $boxed ? `1px solid ${theme.colors.lightgrey}` : ''};
  border-radius: 0.5em;
`;

const ExpendableContainer = ({
	initiallyOpen = false,
	label,
	boxed = false,
	className,
	children
}: IExpendableContainer) => {
	const [open, setOpen] = useState(initiallyOpen);

	return <StyledContainer $boxed={boxed} column className={className}>
		<StyledHeader $boxed={boxed}>
			<StyledLabel>{label}</StyledLabel>
			<span
				className={`fa fa-solid cursor-pointer ${open ? 'fa-chevron-up' : 'fa-chevron-down'}`}
				onClick={() => {
					setOpen(!open);
				}}
			/>
		</StyledHeader>
		<Collapse className="w-100" in={open}>
			{children}
		</Collapse>
	</StyledContainer>;
};

export default ExpendableContainer;
