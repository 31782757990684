import { useEffect, useState } from 'react';
import { NotesDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { translate } from 'services/i18n';
import NotesForm from './NotesForm/NotesForm';
import { dateToShortString } from '../../services/utils';

const NotesDetailsView = ({
	notes,
	onEditSaved
}: { notes: NotesDetailsFragment, onEditSaved: (updatedNotes: NotesDetailsFragment) => void }) => {
	const [currentlyEditing, setCurrentlyEditing] = useState(false);

	useEffect(() => {
		setCurrentlyEditing(false);
	}, [notes._id]);
	return (
		<FlexContainer column alignItems="flex-start" className="pt-1 w-100">
			{currentlyEditing ?
				<NotesForm
					initialNote={notes}
					onCancel={() => setCurrentlyEditing(false)}
					onSaved={(updatedNote) => {
						setCurrentlyEditing(false);
						onEditSaved(updatedNote);
					}}
				/> :
				<div className="w-100" onClick={() => setCurrentlyEditing(true)}>
					<FlexContainer justifyContent="space-between">
						<h3>{notes.title}</h3>
						<Button label={translate({ key: 'Edit' })} onClick={() => setCurrentlyEditing(true)}/>
					</FlexContainer>
					<div className="italic">{`${translate({
						key: 'Created on',
						data: { date: dateToShortString(new Date(notes.createdAt)) }
					})} - ${translate({
						key: 'Last modified on',
						data: { date: dateToShortString(new Date(notes.createdAt)) }
					})}`}
					</div>
					<div dangerouslySetInnerHTML={{ __html: notes.content }}/>
				</div>
			}
		</FlexContainer>
	);
};

export default NotesDetailsView;
