import { useCallback } from 'react';
import { Pagination as MuiPagination } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';


interface IPagination {
	totalItems: number;
	currentPage: number;
	limit: number;
	onPageChange?: (page: number) => void;
}

const StyledPagination = styled(MuiPagination)`
  font-family: 'Comfortaa', -apple-system, BlinkMacSystemFont, sans-serif !important;

  button {
    font-family: 'Comfortaa', -apple-system, BlinkMacSystemFont, sans-serif !important;
    border: none;
    font-size: 1em;
  }

  //TODO - styling
  .mantine-Pagination-active {
    border-radius: 100%;
    background-color: ${({ theme }) => theme.colors.green};
  }

  .mantine-Pagination-item:not(.mantine-Pagination-active) {
    color: ${({ theme }) => theme.colors.lightgrey}
  }
`;

const Pagination = ({ totalItems, currentPage, limit, onPageChange }: IPagination) => {
	const totalPages = Math.ceil(totalItems / limit);
	const [, setSearchParams] = useSearchParams();
	const defaultOnPageChange = useCallback((page: number) => {
		setSearchParams({ page: page.toString() });
	}, [setSearchParams]);

	return (
		totalPages > 1 ? <StyledPagination
			count={totalPages}
			page={currentPage}
			onChange={(event, page) => {
				if (onPageChange) {
					onPageChange(page);
				} else {
					defaultOnPageChange(page);
				}
			}}
		/> : <></>
	);
};

export default Pagination;
