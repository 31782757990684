import { useMemo } from 'react';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FeedbackDetailsFragment } from '__generated__/graphql';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const StyledFeedback = styled(FlexContainer)`
	border: 1px solid lightgrey;
	border-radius: 0.5em;
	margin-bottom: 1em;
	padding: 1em;
	justify-content: flex-start;
	width: calc(100% - 2em);
	.hover-green:hover {
		color: ${({ theme }) => theme.colors.green};
	}
	.small {
		font-size: 0.8em;
	}
`;

const StyledComment = styled(Div)`
	line-height: 1em;
`;
const StyledIcon = styled(FontAwesomeIcon)<{ $liked : boolean}>`
	margin-right: 0.5em;
	width: 1.5em;
	height: 1.5em;
	&:hover {
		cursor: pointer;
	}
	path {
		fill: ${({ $liked, theme }) => $liked ? theme.colors.green : theme.colors.lightgrey}!important;
	}
`;

interface IFeedbacksListTileView {
	feedback: FeedbackDetailsFragment
	onEditFeedback: (feedback:FeedbackDetailsFragment) => void
	onDeleteFeedback: (feedbackId: string) => Promise<void>
	onReactToFeedback: (feedbackId: string) => Promise<void>
}
const FeedbacksListTileView = ({ feedback, onEditFeedback, onDeleteFeedback, onReactToFeedback } : IFeedbacksListTileView) => {
	const { user } = useAuthenticatedUser();
	const editable = useMemo(() => !!user && user._id === feedback.userId, [feedback, user]);
	const createdAt = new Date(feedback.createdAt);

	return (
		<StyledFeedback column>
			<FlexContainer justifyContent="space-between" className="small pb-1">
				<div className="bold">{`${translate({ key: 'By', data: { username: feedback.username } })}`}</div>
				<div>{createdAt.toLocaleDateString()}</div>
			</FlexContainer>
			<StyledComment>{feedback.comment}</StyledComment>
			<FlexContainer className="pt-1 small" justifyContent={editable ? 'space-between': 'flex-end'}>
				{editable && (
					<div>
						<span className="mr-1 cursor-pointer hover-green" onClick={async () => {
							if (feedback._id) {
								await onDeleteFeedback(feedback._id);
							}
						}}>
							{translate({ key: 'Delete' })}
						</span>
						<span className="cursor-pointer hover-green" onClick={() => onEditFeedback(feedback)}>
							{translate({ key: 'Edit' })}
						</span>
					</div>
				)}
				<div className="cursor-pointer" >
					<StyledIcon icon={faHeart} $liked={!!feedback.likedByUser} onClick={async () => {
						if (user) {
							await onReactToFeedback(feedback._id);
						}
					}}/>
					<span>{feedback.likes}</span>
				</div>
			</FlexContainer>
		</StyledFeedback>
	);
};

export default FeedbacksListTileView;
