import { Typography } from '@mui/material';
import { namedOperations, PatternType, useDeletePatternMutation, usePatternQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import RemoteImage from 'components/base/RemoteImage';
import DetailsLayout from 'components/Layouts/DetailsLayout/DetailsLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import PatternDetailsActiveProjectsView from './PatternDetailsActiveProjectsView/PatternDetailsActiveProjectsView';
import PatternDetailsDescriptionView from './PatternDetailsDescriptionView/PatternDetailsDescriptionView';
import PatternDetailsFabricsView from './PatternDetailsFabricsView/PatternDetailsFabricsView';
import PatternDetailsYarnsView from './PatternDetailsYarnsView/PatternDetailsYarnsView';
import ExternalLink from '../../../components/base/ExternalLink';
import DeletePatternModal from '../DeletePatternModal/DeletePatternModal';

const PatternDetailsView = () => {
	const isDemo = useIsDemo();
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const { notifyError } = useNotifications();
	const { loading, data } = usePatternQuery(
		{
			variables: { id, isDemo },
			onError: err => {
				notifyError(err.message);
			}
		});
	const [deletePattern, { loading: deletingPattern }] = useDeletePatternMutation({
		refetchQueries: [namedOperations.Query.getPatterns],
		onCompleted: () => {
			navigate('/patterns');
		}
	});

	return <FlexContainer column>
		{(loading || deletingPattern) && <LoadingOverlay/>}
		{data?.pattern && (
			<DetailsLayout
				deleteModalTitle={translate({ key: 'Pattern deletion' })}
				onDeleteConfirm={() => deletePattern({ variables: { patternId: data.pattern._id } })}
				deleteModalContent={<DeletePatternModal pattern={data.pattern}/>}
				editLink={`${isDemo ? '/demonstration' : ''}/patterns/${data.pattern._id}/edit`}
				itemName={<FlexContainer column alignItems="flex-start">
					<Typography variant="h5">{data.pattern.name}</Typography>
					<ExternalLink
						link={data.pattern.shopPatternLink}
						label={data.pattern.brand || data.pattern.shopPatternLink}
					/>
				</FlexContainer>}
				notes={data.pattern.notes}
				imgContent={<RemoteImage alt={data.pattern.name} src={data.pattern.imageUrl || null}/>}
				mainContent={<PatternDetailsDescriptionView pattern={data.pattern}/>}
				relatedEntitiesContent={<PatternDetailsActiveProjectsView pattern={data.pattern}/>}
				relatedEntitiesTitle={translate({
					key: 'Related projects',
					data: { count: data.pattern.currentProjectsIds.length }
				})}
			>
				{data?.pattern.patternType === PatternType.Sewing &&
				<PatternDetailsFabricsView pattern={data.pattern}/>}
				{(data?.pattern.patternType === PatternType.Knit || data?.pattern.patternType === PatternType.Crochet) &&
				<PatternDetailsYarnsView pattern={data.pattern}/>}
			</DetailsLayout>)
		}
	</FlexContainer>;
};

export default PatternDetailsView;
