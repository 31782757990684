import { FlexContainer } from 'components/base/FlexContainer';
import { Link } from 'react-router-dom';

const HomepageEnglishView = () => {
	return (
		<FlexContainer justifyContent="space-between" alignItems="flex-start" column>
			<p>Born from the imagination and needs of an amateur sewist, My Crafting Cloud is a tool aiming at
				accompanying crafters.
				Whether you want to categorize your patterns, manage your inventory or organize your project and
				inspirations,
				you are at the right place. Here, you will be able to :
			</p>
			<ul>
				<li>Create your patterns library, categorize and annote them</li>
				<li>Inventory your fabrics, yarns and other materials</li>
				<li>Save your inspirations so that you won&apos;t forget them</li>
				<li>Combine your resources to create your projects</li>
			</ul>
			<p>Check the <span style={{ fontSize: '18px' }}><Link
				to={'/demonstration'}>demonstration space</Link></span> to understand how the app works. You can
				also check the&nbsp;
			<Link to={'/faq'}>FAQ</Link> or <Link to={'/contact'}> contact me </Link> for any other questions.</p>
			<p>In order to make our tool even more useful, you can leave a feedback on the <Link to={'/feedbacks'}>dedicated
				page</Link>.</p>
			<p className="italic">
				<p>
					The website is currently available with some restrictions : everything is accessible and working
					(unless indicated otherwise) ; however some bugs might occur, in which
					case, you are invited to report and desribe the problem you encountered (through <span
						className="bold">Report bug</span> in the header bar).
				</p>
				<p>
					Additionally, the following limitations are currently in place :
					<ul>
						<li>100 patterns</li>
						<li>100 stash items</li>
						<li>10 projects</li>
						<li>200 inspirations</li>
						<li>200 creations (coming soon)</li>
					</ul>
				</p>
			</p>
		</FlexContainer>
	);
};

export default HomepageEnglishView;
