import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import useFabricsSearchEngine from 'customHooks/searchEngine/useFabricsSearchEngine';
import { translate } from 'services/i18n';
import FabricsListFiltersView from './FabricsListFiltersView/FabricsListFiltersView';
import FabricsListResultsView from './FabricsListResultsView/FabricsListResultsView';

const FabricsListView = () => {
	const { data } = useFabricsSearchEngine();

	return (
		<FlexContainer column>
			<FabricsListFiltersView/>
			<Divider
				sx={{ marginTop: '2em' }}
				label={<strong>
					{translate({ key: 'Search results', data: { count: data?.count || 0 } })}
				</strong>}/>
			<FabricsListResultsView/>
		</FlexContainer>
	);
};

export default FabricsListView;
