import { FlexContainer } from 'components/base/FlexContainer';
import styled from 'styled-components';

export const StyledMainPageSidebar = styled.div`
  background: ${({ theme }) => theme.colors.darkblue};
  padding: 1em;
  width: 18em;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  height: calc(100% - 2em);
  position: fixed;

  &.demonstration {
    background: ${({ theme }) => theme.colors.darkgreen};
  }

  @media screen and (max-width: 1200px) {
    padding: 2em 1em 1em 1em;
    height: calc(100% - 6em);
    width: 16em;
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 6em);
    width: calc(100% - 2em);
  }

  svg {
    fill {
      background: rgb(254, 254, 254);
      background: radial-gradient(circle, rgba(254, 254, 254, 1) 0%, rgba(224, 255, 254, 1) 72%, rgba(137, 202, 224, 1) 100%);
    }
  }
`;

export const StyledMenu = styled(FlexContainer)`
  height: 100%;
`;

interface StyledMenuItemProps {
	$sub?: boolean;
	$active?: boolean;
}

export const StyledMainPageSidebarMenuItem = styled(FlexContainer)<StyledMenuItemProps>`
  justify-content: flex-start;
  height: 2.2em;
  padding-left: ${({ $sub }) => $sub ? '1.5em' : 0};
  font-weight: ${({ $active }) => $active ? 'bold' : 'normal'};
  font-style: ${({ $active }) => $active ? 'italic' : 'normal'};

  svg {
    height: 2em;
  }

  a {
    text-decoration: none;
    color: ${({ theme, $active }) => $active ? theme.colors.activeLink : theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.brightgreen};
    }
  }
`;

export const StyledHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 1em;
  margin-right: 2em;
  font-weight: bold;
  border-bottom: 1px solid;
`;
