import * as React from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useInspirationsSearchEngine from 'customHooks/searchEngine/useInspirationsSearchEngine';
import { useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import InspirationsListResultView from '../InspirationsListView/InspirationsListResultView';

const InspirationsListForTagView = () => {
	const { tag } = useParams<'tag'>();
	const { data, loading, loadMore, limit } = useInspirationsSearchEngine();

	return <div className={'w-100'}>
		{loading && <LoadingOverlay/>}
		<FlexContainer><h2>{`#${tag}`}</h2></FlexContainer>
		{!data?.inspirations.length && <span>{translate({ key: 'No results' })}</span>}
		<InspirationsListResultView data={data} loadMore={loadMore} limit={limit}/>
	</div>;
};

export default InspirationsListForTagView;
