import { namedOperations, useDeleteFabricMutation, useFabricQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import RemoteImage from 'components/base/RemoteImage';
import DetailsLayout from 'components/Layouts/DetailsLayout/DetailsLayout';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import FabricDetailsActiveProjectsView from './FabricDetailsActiveProjectsView/FabricDetailsActiveProjectsView';
import FabricDetailsDescriptionView from './FabricDetailsDescriptionView/FabricDetailsDescriptionView';
import FabricDetailsProjectsView from './FabricDetailsProjectsView/FabricDetailsProjectsView';
import useIsDemo from '../../../customHooks/data/useIsDemo';
import DeleteFabricModal from '../DeleteFabricModal/DeleteFabricModal';

const FabricDetailsView = () => {
	const isDemo = useIsDemo();
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const [deleteFabric, { loading: deletingFabric }] = useDeleteFabricMutation({
		variables: { fabricId: id },
		refetchQueries: [namedOperations.Query.getFabrics],
		onCompleted: () => {
			navigate('/fabrics');
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	const { loading, data } = useFabricQuery({
		variables: { id, isDemo },
		onError: (err) => {
			notifyError(err.message);
		}
	});

	return (
		<FlexContainer column>
			{(loading || deletingFabric) && <LoadingOverlay/>}
			{data?.fabric && (<DetailsLayout
				editLink={`${isDemo ? '/demonstration' : ''}/fabrics/${data.fabric._id}/edit`}
				deleteModalTitle={translate({ key: 'Item deletion' })}
				onDeleteConfirm={deleteFabric}
				deleteModalContent={<DeleteFabricModal fabric={data.fabric}/>}
				itemName={data.fabric?.name || translate({ key: 'Unnamed fabric' })}
				imgContent={<RemoteImage src={data.fabric?.imageUrl || null} alt={data.fabric.name}/>}
				mainContent={<FabricDetailsDescriptionView fabric={data.fabric}/>}
				notes={data.fabric.notes}
				relatedEntitiesTitle={translate({
					key: 'Related projects',
					data: { count: data.fabric.currentProjectsIds.length }
				})}
				relatedEntitiesContent={<FabricDetailsActiveProjectsView fabric={data.fabric}/>}
			>
				<FabricDetailsProjectsView fabric={data.fabric}/>
			</DetailsLayout>)}
		</FlexContainer>
	);
};

export default FabricDetailsView;
