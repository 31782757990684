import { useGetPatternsLazyQuery } from '__generated__/graphql';
import { translate } from 'services/i18n';
import AsyncAutoCompleteInput, { AsyncAutoCompleteInputOptionProps } from './AsyncAutoCompleteInput';
import { defaultPaginationParams } from '../../constants';

interface PatternsAutoCompleteInputProps {
	value: AsyncAutoCompleteInputOptionProps[];
	onChange: (options: AsyncAutoCompleteInputOptionProps[]) => void;
}

const PatternsAutoCompleteInput = ({ value, onChange }: PatternsAutoCompleteInputProps) => {
	const [getPatterns] = useGetPatternsLazyQuery();
	return (
		<AsyncAutoCompleteInput
			label={translate({ key: 'Pattern(s)' })}
			id="project-patterns"
			value={value}
			fetchOptions={async (search: string) => {
				const { data } = await getPatterns({
					variables: {
						...defaultPaginationParams,
						name: search,
					}
				});
				return (data?.patterns?.patterns || []).map((pattern) => ({
					imageUrl: pattern.imageUrl,
					label: pattern.name,
					resourceType: pattern.patternType,
					value: pattern._id
				}));
			}}
			onChange={options => onChange(options)}
		/>
	);
};

export default PatternsAutoCompleteInput;
