import MultiSelectInput from 'components/Inputs/MultiSelect';
import useUserFilters from 'customHooks/data/useUserFilters';
import { translate } from 'services/i18n';

interface PatternFormFormatSelectionViewProps {
	selectedFormats: string[];
	onChange: (value: string[]) => void;
}

const PatternFormFormatSelectionView = ({ selectedFormats, onChange }: PatternFormFormatSelectionViewProps) => {
	const { availableFormats } = useUserFilters();
	return <MultiSelectInput
		data={availableFormats}
		creatable={false}
		value={selectedFormats.map(format => availableFormats.find(({ value }) => value === format))}
		onChange={onChange}
		label={translate({ key: 'Available formats' })}
	/>;
};

export default PatternFormFormatSelectionView;
