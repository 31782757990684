export const theme = {
	colors: {
		brightgreen: '#0BD925',
		white: 'white',
		red: '#EF1818',
		darkgrey: '#575454',
		lightgrey: 'lightgrey',
		petrol: '#00424d',
		darkblue: '#00042F',
		darkgreen: '#022e2a',
		green: '#2ce87e',
		lightblue: '#e8eefa',
		activeLink: '#2ce87e',
		transparentGreen: 'rgb(44, 232, 125, 0.5)'
	},
	boxShadow: '1px 1px 2px 1px #D3D3D3',
};
