import { createContext, useContext } from 'react';
import { FileInput, PatternDetailsFragment, PatternInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export interface PatternFormContextProps {
	state: PatternDetailsFragment & { hostedImage?: FileInput };
	handleChange: (name: keyof PatternInput, value: ValueOf<PatternInput> | undefined | null) => void;
}

export const FormContext = createContext<null | PatternFormContextProps>(null);

export const FormProvider = FormContext.Provider;

export function usePatternFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('usePatternFormContext must be used within a FormContextProvider');
	}
	return context;
}
