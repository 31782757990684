import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { InspirationType } from '__generated__/graphql';
import RemoteImage from 'components/base/RemoteImage';
import RemoteVideo from 'components/base/RemoteVideo';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

const StyledImageListItem = styled(ImageListItem)`
  .name-bar {
    display: none
  }

  &:hover {
    .name-bar {
      display: block;
    }
  }
`;

interface ImageListProps {
	images: { name?: string, url: string, navigateLink: string, type: InspirationType, _id: string }[];
}

const ImageListLayout = ({ images }: ImageListProps) => {
	const { isMediumScreen, isSmallScreen } = useScreenWidth();

	return (
		isSmallScreen ? <GridContainer>
			{
				images.map(({ name, url, navigateLink, type, _id }) => <GridItem key={_id} xs={12} sm={6}>
					<Link key={_id} to={navigateLink}>
						{type === InspirationType.Image && <RemoteImage src={url} alt={name}/>}
						{type === InspirationType.Video &&
								<RemoteVideo videoUrl={url} disableClick displayThumbnail/>}
					</Link>
				</GridItem>
				)
			}
		</GridContainer> :
			<Box sx={{ overflowY: 'auto', height: '100%', width: '100%' }}>
				<ImageList variant="masonry" cols={isMediumScreen ? 2 : 4} gap={16}>
					{images.map(({ name, url, navigateLink, type, _id }) => (
						<Link key={_id} to={navigateLink}>
							<StyledImageListItem sx={{ ':hover': { cursor: 'pointer' } }}>
								{type === InspirationType.Image && <RemoteImage src={url} alt={name}/>}
								{type === InspirationType.Video &&
								<RemoteVideo videoUrl={url} disableClick displayThumbnail/>}
								{name &&
								<ImageListItemBar
									className="name-bar"
									position="bottom"
									title={name}
									sx={{ borderRadius: '0 0 0.5em 0.5em', }}/>
								}
							</StyledImageListItem>
						</Link>
					))}
				</ImageList>
			</Box>
	);
};

export default ImageListLayout;
