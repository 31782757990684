import { useEffect, useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import TextArea from 'components/Inputs/TextArea';
import TextInput from 'components/Inputs/TextInput';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { translate } from 'services/i18n';

interface PatternFormFileModalViewProps {
	opened: boolean;
	onConfirm: (file: { name: string, url: string, notes?: string }) => void;
	onClose: () => void;
	file?: { name: string, url: string, notes?: string };
}

const PatternFormFileModalView = ({ opened, onClose, onConfirm, file }: PatternFormFileModalViewProps) => {
	const [name, setName] = useState(file?.name || '');
	const [url, setUrl] = useState(file?.url || '');
	const [notes, setNotes] = useState(file?.notes || '');

	useEffect(() => {
		setName(file?.name || null);
		setUrl(file?.url || null);
		setNotes(file?.notes || null);
	}, [file]);
	return (
		<ModalContainer
			opened={opened}
			onConfirm={() => {
				onConfirm({ name, url, notes });
				setUrl('');
				setName('');
				setNotes('');
				onClose();
			}}
			onClose={() => {
				setUrl('');
				setName('');
				setNotes('');
				onClose();
			}}
			title={translate({ key: 'Add file' })}
			confirmDisabled={!url || !name}
		>
			<FlexContainer column>
				<div className="mb-1 w-100">
					<TextInput
						id="file-name"
						onChange={name => setName(name)}
						label={translate({ key: 'Name' })}
						value={name}
					/>
				</div>
				<div className="mb-1 w-100">
					<TextInput
						id="file-url"
						onChange={url => setUrl(url)}
						label={translate({ key: 'Url' })}
						value={url}
					/>
				</div>
				<div className="mb-1 w-100">
					<TextArea
						label={translate({ key: 'Notes' })}
						rows={5}
						onChange={notes => setNotes(notes)}
						value={notes}
					/>
				</div>
			</FlexContainer>
		</ModalContainer>
	);
};

export default PatternFormFileModalView;
