import { useGetInspirationQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useNotifications from 'customHooks/utils/useNotifications';
import { useParams } from 'react-router-dom';
import InspirationForm from 'views/inspirations/InspirationForm/InspirationForm';

const InspirationEditView = () => {
	const { notifyError } = useNotifications();
	const { id } = useParams<'id'>();
	const { loading, data } = useGetInspirationQuery({
		variables: { inspirationId: id },
		onError: err => {
			notifyError(err.message);
		}
	});
	return (
		<FlexContainer column>
			{(loading) && <LoadingOverlay/>}
			{data?.inspiration && <InspirationForm
				inspiration={data.inspiration}
				formTitle={'Inspiration edit'}
			/>}
		</FlexContainer>
	);
};

export default InspirationEditView;
