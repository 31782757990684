import { ProjectDetailsFragment } from '__generated__/graphql';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { translate } from 'services/i18n';
import { dateToString } from '../../../../services/utils';

const ProjectDetailsDescriptionView = ({ project }: { project: ProjectDetailsFragment }) => {
	return <GridContainer>
		<FieldDescriptionBox
			label={translate({ key: 'Status' })}
			content={project.status ? translate({ key: project.status }) : '--'}
		/>
		<FieldDescriptionBox label={translate({ key: 'Recipient' })} content={project.recipient || '--'}/>
		<FieldDescriptionBox
			label={translate({ key: 'Started at' })}
			content={project.startedAt ? dateToString(new Date(project.startedAt)) : '--'}/>
		<FieldDescriptionBox
			label={translate({ key: 'Finished at' })}
			content={project.finishedAt ? dateToString(new Date(project.finishedAt)) : '--'}/>
	</GridContainer>;
};

export default ProjectDetailsDescriptionView;
