import React from 'react';
import * as ReactDOM from 'react-dom/client';
import './styles/index.scss';
import './styles/custom.scss';
import { BrowserRouter } from 'react-router-dom';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
	//<React.StrictMode>
	<BrowserRouter>
		<App/>
	</BrowserRouter>
	//</React.StrictMode>
);

