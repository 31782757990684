import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';
import InspirationEnglishHelpPanel from './InspirationEnglishHelpPanel';
import InspirationFrenchHelpPanel from './InspirationFrenchHelpPanel';
import InspirationItalianHelpPanel from './InspirationItalianHelpPanel';

const InspirationHelpPanel = () => {
	const userLanguage = getCurrentLanguage();

	return (
		<FlexContainer column>
			<h2>{translate({ key: 'Inspirations' })}</h2>
			{userLanguage === 'fr' && <InspirationFrenchHelpPanel/>}
			{userLanguage === 'en' && <InspirationEnglishHelpPanel/>}
			{userLanguage === 'ita' && <InspirationItalianHelpPanel/>}
		</FlexContainer>
	);
};

export default InspirationHelpPanel;
