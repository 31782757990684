import { ReactNode } from 'react';
import { Drawer } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { translate } from 'services/i18n';
import styled from 'styled-components';

interface DrawerProps {
	onClose: () => void;
	onClick?: () => void;
	children: ReactNode;
	opened: boolean;
}

const PanelContent = styled(FlexContainer)`
  justify-content: space-between;
  flex-direction: column;
  padding: 1em;
  height: 100%;
  width: 30vw;
  @media screen and (max-width: 1200px) {
    width: 50vw;
  }
  @media screen and (max-width: 767px) {
    width: 90vw;
  }
`;

const Panel = ({ opened, onClose, children, onClick }: DrawerProps) => {
	return (
		<Drawer
			open={opened}
			anchor="right"
			onClick={() => onClick()}
			onClose={() => onClose()}
			sx={{}}
		>
			<PanelContent>
				<div>{children}</div>
				<div>
					<Button label={translate({ key: 'Close' })} onClick={() => onClose()}/>
				</div>
			</PanelContent>
		</Drawer>
	);
};

export default Panel;
