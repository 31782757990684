import { InputLabel } from '@mui/material';
import { translate } from 'services/i18n';
import NumberInput from './NumberInput';
import { FlexContainer } from '../base/FlexContainer';

interface RangeInputProps {
	onChange: (value: { from: number | null, to: number | null }) => void;
	from: number;
	to: number;
	inputLabel?: string;
}

const RangeInput = ({ onChange, from, to, inputLabel }: RangeInputProps) => {
	return (
		<FlexContainer column>
			{inputLabel && <InputLabel>{inputLabel}</InputLabel>}
			<FlexContainer>
				<NumberInput
					innerLabel
					onNumberChange={fromValue => onChange({ from: fromValue, to })}
					value={from}
					label={translate({ key: 'From' })}/>
				<span>&nbsp;-&nbsp;</span>
				<NumberInput
					innerLabel
					onNumberChange={toValue => onChange({ from, to: toValue })}
					value={to}
					label={translate({ key: 'To' })}/>
			</FlexContainer>
		</FlexContainer>
	);
};

export default RangeInput;
