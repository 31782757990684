import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useInspirationsSearchEngine from 'customHooks/searchEngine/useInspirationsSearchEngine';
import InspirationsListFiltersView from './InspirationsListFiltersView';
import InspirationsListResultView from './InspirationsListResultView';

const InspirationsListView = () => {
	const { loading, currentFilters, updateUrl, data, loadMore, limit } = useInspirationsSearchEngine();
	return (
		<FlexContainer column>
			{loading && <LoadingOverlay/>}
			<InspirationsListFiltersView currentFilters={currentFilters} updateUrl={updateUrl}/>
			<InspirationsListResultView data={data} loadMore={loadMore} limit={limit}/>
		</FlexContainer>
	);
};

export default InspirationsListView;
