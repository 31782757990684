import { useEffect, useRef, useState } from 'react';
import {
	Feedback,
	namedOperations,
	useDeleteFeedbackMutation,
	useGetFeedbacksLazyQuery,
	useReactToFeedbackMutation,
	useSaveFeedbackMutation
} from '__generated__/graphql';
import { Div } from 'components/base/Div';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import TextArea from 'components/Inputs/TextArea';
import Pagination from 'components/Pagination/Pagination';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useNotifications from 'customHooks/utils/useNotifications';
import { useSearchParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import FeedbacksListTileView from './FeedbacksListTileView/FeedbacksListTileView';

const LIMIT = 12;
const FeedbacksListView = () => {
	const { user } = useAuthenticatedUser();
	const [comment, setComment] = useState('');
	const [currentlyEditedFeedback, setCurrentlyEditedFeedback] = useState<Feedback | null>(null);
	const [feedbacks, setFeedbacks] = useState<Feedback[]>([]);
	const [searchParams] = useSearchParams();
	const [total, setTotal] = useState(0);
	const { notifyError } = useNotifications();

	const page = searchParams.get('page') || 1;
	const inputRef = useRef<HTMLInputElement | null>(null);

	const [getFeedbacks, { loading, fetchMore }] = useGetFeedbacksLazyQuery({
		variables: { limit: LIMIT, page: +page },
		onCompleted: ({ feedbacks }) => {
			setFeedbacks(feedbacks?.feedbacks || []);
			setTotal(feedbacks?.count || 0);
		},
		onError: (err) => {
			notifyError(err.message);
		}
	});

	const [saveFeedback] = useSaveFeedbackMutation({
		refetchQueries: [namedOperations.Query.getFeedbacks],
		onCompleted: async () => {
			setCurrentlyEditedFeedback(null);
			setComment('');
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	const [deleteFeedback] = useDeleteFeedbackMutation({
		refetchQueries: [namedOperations.Query.getFeedbacks],
		onError: err => {
			notifyError(err.message);
		}
	});
	const [reactToFeedback] = useReactToFeedbackMutation({
		onError: err => {
			notifyError(err.message);
		}
	});


	useEffect(() => {
		if (!fetchMore) {
			getFeedbacks({ variables: { page: +page, limit: LIMIT } });
		} else {
			fetchMore({ variables: { page: +page, limit: LIMIT } });
		}
	}, [page, getFeedbacks, fetchMore]);

	return (
		<FlexContainer column>
			{loading && <LoadingOverlay/>}
			<h1>{translate({ key: 'Feedbacks' })}</h1>
			<Div className="pv">
				{translate({ key: 'Have any suggestion to improve the app ? Leave a feedback, or like an existing feedback so that I can know what features you would like me to add' })}
			</Div>
			{!!feedbacks.length && <>
				<FlexContainer column className="pt-1 w-50 w-sm-100">
					{feedbacks.map((feedback) => (
						<FeedbacksListTileView
							onReactToFeedback={async (feedbackId: string) => {
								await reactToFeedback({ variables: { feedbackId } });
							}}
							onDeleteFeedback={async (feedbackId: string) => {
								await deleteFeedback({ variables: { feedbackId } });
							}}
							key={feedback._id}
							feedback={feedback}
							onEditFeedback={feedback => {
								if (inputRef?.current) {
									setCurrentlyEditedFeedback(feedback);
									setComment(feedback.comment || '');
									inputRef.current.focus();
								}
							}}
						/>
					))}
					<div className={'pb-1'}>
						<Pagination
							totalItems={total}
							currentPage={+page}
							limit={LIMIT}
						/>
					</div>
				</FlexContainer>
			</>}
			<div className="w-50 w-sm-100">
				<Divider className="m-0"/>
			</div>
			<FlexContainer className="pt-1 w-50 w-sm-100" column>
				<TextArea
					ref={inputRef}
					disabled={!user}
					className="p-0 m-1"
					label={translate({ key: 'Your feedback' })}
					rows={5}
					value={comment}
					onChange={comment => setComment(comment)}
				/>
				<FlexContainer justifyContent={'flex-end'}>
					{currentlyEditedFeedback && (
						<Button
							className="align-self-end mt-1 mr-1"
							disabled={!user || !comment.length}
							red
							label={translate({ key: 'Cancel' })}
							onClick={() => {
								setCurrentlyEditedFeedback(null);
								setComment('');
							}}
						/>
					)}
					<Button
						className="align-self-end mt-1"
						disabled={!user || !comment.length || (!!currentlyEditedFeedback && currentlyEditedFeedback.comment === comment)}
						label={translate({ key: 'Submit' })}
						onClick={async () => {
							await saveFeedback({
								variables: {
									input: {
										comment: comment.trim(),
										...currentlyEditedFeedback ? { _id: currentlyEditedFeedback._id } : {}
									}
								}
							});
						}}
					/>
				</FlexContainer>
			</FlexContainer>
		</FlexContainer>
	);
};

export default FeedbacksListView;
