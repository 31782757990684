import { PatternDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';

const DeletePatternModal = ({ pattern }: { pattern: PatternDetailsFragment }) => {
	return (
		<FlexContainer column>
			<div style={{ alignSelf: 'flex-start', paddingBottom: '1em' }}>
				{`${translate({ key: 'Are you sure you want to delete that pattern' })} ?`}
			</div>
			{pattern.imageUrl &&
			<div className="w-50"><RemoteImage src={pattern.imageUrl} alt={pattern.name}/></div>}
		</FlexContainer>
	);
};

export default DeletePatternModal;
