import { useEffect, useState } from 'react';
import { ApolloProvider } from '@apollo/client';
import { createTheme, ThemeProvider } from '@mui/material';
import { Route, Routes } from 'react-router-dom';
import { initServices } from 'services';
import NotificationContainer from './components/Notification/NotificationContainer';
import { AdminPage } from './pages/AdminPage/AdminPage';
import { DemonstrationPage } from './pages/DemonstrationPage/DemonstrationPage';
import { MainPage } from './pages/MainPage/MainPage';
import { client } from './services/api/graphql';
import 'react-toastify/dist/ReactToastify.css';

const theme = createTheme({
	typography: {
		fontFamily: 'Comfortaa,-apple-system, BlinkMacSystemFont,sans-serif',
		fontSize: 14
	},
	palette: {
		primary: {
			main: '#2ce87e'
		},
	},
	components: {
		MuiInputBase: {
			styleOverrides: {
				root: {
					height: 42,
					borderRadius: '0.4em',
				}
			}
		},
		MuiOutlinedInput: {
			styleOverrides: {
				root: {
					borderRadius: '0.4rem',
					'::placeholder': {
						color: 'lightgrey'
					}

				}
			}
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 'bold',
					whiteSpace: 'nowrap'
				}
			}
		},
		MuiFormControlLabel: {
			styleOverrides: {
				label: {
					fontSize: 14,
					whiteSpace: 'nowrap'
				}
			}
		},
		MuiDrawer: {
			styleOverrides: {
				root: {
					fontSize: 14
				}
			}
		},
	}
});

function App() {
	const [initiated, setInitiated] = useState(false);
	useEffect(() => {
		initServices().then(() => {
			setInitiated(true);
		});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<ApolloProvider client={client}>
				{initiated && (
					<Routes>
						<Route path="/admin/*" element={<AdminPage/>}/>
						<Route path="/demonstration/*" element={<DemonstrationPage/>}/>
						<Route path="/*" element={<MainPage/>}/>
					</Routes>
				)}
				<NotificationContainer/>
			</ApolloProvider>
		</ThemeProvider>
	);
}

export default App;
