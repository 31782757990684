import { Fabric } from '__generated__/graphql';
import Badge from 'components/base/Badge';
import ExternalLink from 'components/base/ExternalLink';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { translate } from 'services/i18n';


const FabricDetailsDescriptionView = ({ fabric }: { fabric: Fabric }) => {
	return <GridContainer>
		<FieldDescriptionBox
			label={translate({ key: 'Type' })}
			content={<>
				{fabric.type?.length ? fabric.type.map(key => <Badge
					key={key}
					label={translate({ key })}/>
				) : '--'}
			</>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Available quantity' })}
			content={fabric.quantity && fabric.quantityUnit ? (`${fabric.quantity} ${translate({ key: fabric.quantityUnit })}`) : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Shop' })}
			content={fabric.shop ? <ExternalLink link={fabric.shop}/> : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Composition' })}
			content={fabric.composition.length ? fabric.composition.join(', ') : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Colors' })}
			content={fabric.colors.length ? fabric.colors.join(', ') : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Designs' })}
			content={fabric.designs.length ? fabric.designs.join(', ') : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Tags' })}
			content={fabric.tags?.length ? fabric.tags.join(', ') : '--'}
		/>
	</GridContainer>

	;
};

export default FabricDetailsDescriptionView;
