import { getTiktokVideoData } from 'services/api/rest/tiktok';

const YOUTUBE_EMBED_BASE_URL = (videoId: string) => `https://www.youtube.com/embed/${videoId}`;
const TIKTOK_EMBED_BASE_URL = (videoId: string) => `https://www.tiktok.com/embed/v2/${videoId}`;
const INSTAGRAM_EMBED_BASE_URL = (videoId: string) => `https://www.instagram.com/p/${videoId}/embed/`;

const UseVideoUrlParser = () => {
	return {
		getVideoIdAndThumbnail: async (videoUrl: string) => {
			try {
				const url = new URL(videoUrl);
				switch (url.hostname) {
				case 'youtu.be': {
					const videoId = url.pathname.slice(1);
					return {
						videoId,
						thumbnailUrl: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
					};
				}
				case 'www.youtube.com' : {
					const videoId = url.pathname === '/watch' ? url.searchParams.get('v') : null;
					return {
						videoId,
						thumbnailUrl: videoId ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` : null
					};
				}
				case 'www.tiktok.com': {
					return getTiktokVideoData(videoUrl)
						.then(({ videoId, thumbnailUrl }) => ({
							videoId,
							thumbnailUrl
						}));
				}
				case 'www.instagram.com': {
					const videoId = url.pathname.split('/').filter(part => !!part).reverse()[0];
					return { videoId, thumbnailUrl: null };
				}
				default: {
					return { videoId: null, thumbnailUrl: null };
				}
				}
			} catch (err) {
				return { videoId: null, thumbnailUrl: null };
			}
		},
		parseVideoUrl: async ({ videoUrl }: { videoUrl: string, videoId?: string }) => {
			try {
				const url = new URL(videoUrl);
				switch (url.hostname) {
				case 'youtu.be': {
					const videoId = url.pathname.slice(1);
					return {
						videoUrl: YOUTUBE_EMBED_BASE_URL(videoId),
						embeddedHTML: null,
						provider: 'Youtube',
						thumbnailUrl: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
					};
				}
				case 'www.youtube.com' : {
					if (url.pathname === '/watch') {
						const videoId = url.searchParams.get('v');
						return {
							videoUrl: YOUTUBE_EMBED_BASE_URL(videoId), embeddedHTML: null, provider: 'Youtube',
							thumbnailUrl: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
						};
					}
					return { videoUrl, embeddedHTML: null, provider: null, thumbnailUrl: null };
				}
				case 'www.tiktok.com': {
					return getTiktokVideoData(videoUrl).then(({ videoId, thumbnailUrl }) => {
						return {
							videoUrl: TIKTOK_EMBED_BASE_URL(videoId),
							embeddedHTML: null,
							provider: 'TikTok',
							thumbnailUrl
						};
					});
				}
				case 'www.instagram.com': {
					const videoId = url.pathname.split('/').filter(part => !!part).reverse()[0];
					return {
						videoUrl: INSTAGRAM_EMBED_BASE_URL(videoId),
						embeddedHTML: null,
						provider: 'Instagram',
						thumbnailUrl: null
					};
				}
				default: {
					return { videoUrl, embeddedHTML: null, provider: null, thumbnailUrl: null };
				}

				}
			} catch (err) {
				return { videoUrl, embeddedHTML: null, provider: null, thumbnailUrl: null };
			}
		}
	};

};

export default UseVideoUrlParser;
