import React from 'react';
import styled from 'styled-components';
import { Div } from './Div';

interface FlexContainerProps extends React.ComponentProps<any> {
	justifyContent?: string;
	alignItems?: string;
	alignContent?: string;
	column?: boolean;
	className?: string;
}

interface StyledFlexContainerProps {
	$justifyContent?: string;
	$alignItems?: string;
	$alignContent?: string;
	$column?: boolean;
}

const StyledFlexContainer = styled(Div) <StyledFlexContainerProps>`
  display: flex;
  flex-direction: ${({ $column }) => $column ? 'column' : 'row'};
  justify-content: ${({ $justifyContent }) => $justifyContent || 'center'};
  align-items: ${({ $alignItems }) => $alignItems || 'center'};
  align-content: ${({ $alignContent }) => $alignContent || 'center'};
  width: 100%;

  h1 {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};

    .button {
      font-size: 14px !important;
    }
  }
`;

export const FlexContainer = ({ justifyContent, alignContent, alignItems, column, ...props }: FlexContainerProps) => {
	return <StyledFlexContainer
		$justifyContent={justifyContent}
		$alignItems={alignItems}
		$alignContent={alignContent}
		$column={column}
		{...props}
	/>;
};
