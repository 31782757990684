import { useEffect, useState } from 'react';
import { useDeleteAccountMutation, useUpdateProfileMutation } from '__generated__/graphql';
import { Error } from 'components/base/Error';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import TextInput from 'components/Inputs/TextInput';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import useUserProfile from 'customHooks/data/useUserProfile';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import FiltersView from '../filters/FiltersView';

const StyledLimit = styled.div`
  margin-top: 1em;
  display: grid;
  grid-template-columns: 20em auto;
  grid-row-gap: 1em;

  .red {
    color: ${({ theme }) => theme.colors.red};
  }
`;

const StyledResult = styled.div`
  color: ${({ theme }) => theme.colors.green};
`;

const ProfileDetailsView = () => {
	const navigate = useNavigate();
	const { loadingProfile, profile } = useUserProfile();
	const [username, setUsername] = useState<null | string>(profile?.username || null);
	const [error, setError] = useState<null | string>(null);
	const [result, setResult] = useState<null | string>(null);
	const [deleteAccountModalOpened, setDeleteAccountModalOpened] = useState(false);
	const { notifyError } = useNotifications();
	useEffect(() => {
		if (profile) {
			setUsername(profile.username || null);
		}
	}, [profile]);
	const [updateProfile, { loading: updatingProfile }] = useUpdateProfileMutation({
		onCompleted: () => {
			setResult(translate({ key: 'Profile updated' }));
		},
		onError: err => {
			setError(err.message);
		}
	});
	const [deleteAccount, { loading: deletingProfile }] = useDeleteAccountMutation({
		onCompleted: () => navigate('/'),
		onError: err => {
			notifyError(err.message);
		}
	});

	return <div>
		<ModalContainer
			opened={deleteAccountModalOpened}
			onClose={() => {
				setDeleteAccountModalOpened(false);
			}}
			title={translate({ key: 'Account deletion' })}
			onConfirm={async () => {
				await deleteAccount();
			}}
		>
			<div>
				<div className="pb-1">
					{`${translate({
						key: 'Deleting your account will delete permanently all the patterns and fabrics you created'
					})}.`}
				</div>
				<div className="pb">
					{`${translate({ key: 'Do you confirm you want to delete your account' })} ?`}
				</div>
			</div>
		</ModalContainer>
		<FlexContainer column>
			{(loadingProfile || deletingProfile) && <LoadingOverlay/>}
			<h1>{translate({ key: 'Profile settings' })}</h1>
			{!loadingProfile && (
				<FlexContainer column className="border-bottom pv-1 justify-start align-items-start">
					<FlexContainer className="w-1-3 w-md-100 align-items-end sm-column">
						<TextInput
							id={'username'}
							value={username || ''}
							label={translate({ key: 'Username' })}
							onChange={(value: string) => {
								setUsername(value.trim());
								setError(null);
							}}
						/>
						<div className="pl-1">
							<Button
								disableIfDemo
								loading={updatingProfile}
								label={translate({ key: 'Validate' })}
								onClick={async () => {
									if (username) {
										await updateProfile({ variables: { username } });
									}
								}}
								disabled={!!error || !(profile && (username !== profile.username) && (username && username.length >= 3))}
							/>
						</div>
					</FlexContainer>
					{error && <Error>{translate({ key: error })}</Error>}
					{result && <StyledResult>{result}</StyledResult>}
					{profile && <StyledLimit>
						<div
							className={`bold ${profile.limits.patterns && profile.currentCount.patterns &&
							(profile.limits.patterns - profile.currentCount.patterns <= 5) ? 'red' : ''}`}>
							{translate({ key: 'Sewing patterns quantity' })}
						</div>
						<div
							className={profile.limits.patterns && profile.currentCount.patterns &&
							(profile.limits.patterns - profile.currentCount.patterns <= 5) ? 'red' : ''}>
							{`${profile.currentCount.patterns || 0} / ${profile.limits.patterns ?
								profile.limits.patterns : translate({ key: 'Unlimited' })}`}
						</div>
						<div
							className={`bold ${profile.limits.fabrics && profile.currentCount.fabrics &&
							(profile.limits.fabrics - profile.currentCount.fabrics <= 5) ? 'red' : ''}`}>
							{translate({ key: 'Fabrics quantity' })}
						</div>
						<div>
							{`${profile.currentCount.fabrics || 0} / ${profile.limits.fabrics ?
								profile.limits.fabrics : translate({ key: 'Unlimited' })}`}
						</div>
						<div
							className={`bold ${profile.limits.projects && profile.currentCount.projects &&
							(profile.limits.projects - profile.currentCount.projects <= 5) ? 'red' : ''}`}>
							{translate({ key: 'Projects quantity' })}
						</div>
						<div>
							{`${profile.currentCount.projects || 0} / ${profile.limits.projects ?
								profile.limits.projects : translate({ key: 'Unlimited' })}`}
						</div>
						<div
							className={`bold ${profile.limits.inspirations && profile.currentCount.inspirations &&
							(profile.limits.inspirations - profile.currentCount.inspirations <= 5) ? 'red' : ''}`}>
							{translate({ key: 'Inspirations quantity' })}
						</div>
						<div>
							{`${profile.currentCount.inspirations || 0} / ${profile.limits.inspirations ?
								profile.limits.inspirations : translate({ key: 'Unlimited' })}`}
						</div>
					</StyledLimit>}
				</FlexContainer>
			)}
			<FiltersView/>
			<FlexContainer className="pt-1 align-items-end justify-end">
				<Button
					red
					label={translate({ key: 'Delete my account' })}
					onClick={() => {
						setDeleteAccountModalOpened(true);
					}}
				/>
			</FlexContainer>
		</FlexContainer>
	</div>;
};

export default ProfileDetailsView;
