import { useCallback } from 'react';
import { GetFabricsQueryVariables, useGetFabricsQuery } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import useIsDemo from '../data/useIsDemo';
import useNotifications from '../utils/useNotifications';

const formatSearchParams = (searchParams: URLSearchParams) => {
	const type = searchParams.get('type');
	const name = searchParams.get('name');
	const composition = searchParams.get('composition');
	const colors = searchParams.get('colors');
	const designs = searchParams.get('designs');
	const quantity = searchParams.get('quantity');
	const quantityUnit = searchParams.get('quantityUnit');
	const page = searchParams.get('page');

	return {
		type: type?.split(',') || [],
		composition: composition?.split(',') || [],
		colors: colors?.split(',') || [],
		designs: designs?.split(',') || [],
		quantity: quantity ? +quantity : null,
		page: page ? +page : 1,
		quantityUnit,
		name,
	};
};
const useFabricsSearchEngine = ({
	limit,
	overrideFilters
}: { limit?: number, overrideFilters?: { type: string[], quantity: number | null, page: number } } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const { notifyError } = useNotifications();
	const [searchParams, setSearchParams] = useSearchParams();

	const { loading, error, data } = useGetFabricsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			...overrideFilters ? overrideFilters : formatSearchParams(searchParams),
			limit: LIMIT,
			isDemo
		}, onError: err => {
			notifyError(err.message);
		}
	});

	const updateFilterValues = useCallback((field: keyof GetFabricsQueryVariables, newValue: string | null) => {
		if (newValue) {
			searchParams.set(field, newValue);
		} else {
			searchParams.delete(field);
		}
		if (field !== 'page') {
			searchParams.set('page', '1');
		}
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const updateUrl = useCallback((field: keyof GetFabricsQueryVariables, newValue: string | null) => {
		switch (field) {
		case 'type':
		case 'quantity':
		case 'page':
		case 'name':
		case 'composition':
		case 'designs':
		case 'colors':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters: formatSearchParams(searchParams),
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		data: data?.fabrics
	};
};

export default useFabricsSearchEngine;
