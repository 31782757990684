import { ReactNode } from 'react';
import { Divider as MuiDivider, DividerProps } from '@mui/material';

const Divider = ({
	vertical = false,
	label,
	className,
	...props
}: DividerProps & { label?: string | ReactNode, vertical?: boolean }) => {
	return (
		<MuiDivider orientation={vertical ? 'vertical' : 'horizontal'} variant="middle"
			className={`${vertical ? '' : 'w-100'} pv-1 m-0 ${className}`} {...props}>
			{label}
		</MuiDivider>
	);
};

export default Divider;
