import React from 'react';
import styled from 'styled-components';
import { FlexContainer } from '../base/FlexContainer';
import RemoteImage from '../base/RemoteImage';

const StyledContainer = styled(FlexContainer)`
  position: relative;
  width: 150px;
  height: 200px;

  img {
    border-radius: 0;
    -webkit-box-shadow: 5px 10px 25px -5px #9A9A9A;
    box-shadow: 5px -5px 25px -5px #9A9A9A;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 9 / 16;
    transition: transform 0.2s;
  }
`;

interface StackedImagesProps {
	imagesUrls: string[],
	id: string
}

const StackedImages = ({ imagesUrls }: StackedImagesProps) => {


	return (
		<StyledContainer>
			{imagesUrls.map((src, index) => (
				<RemoteImage
					key={index}
					src={src}
					alt={`Stacked Image ${index + 1}`}
					style={{
						transform: `translate(${index * 10}px, ${-index * 10}px)`,
						zIndex: imagesUrls.length - index
					}}
				/>
			))}
		</StyledContainer>
	);
};

export default StackedImages;

