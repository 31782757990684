//@ts-nocheck

const PrivacyPolicyView = () => {
	return <>
		<h1 style={{ marginLeft: 0 }}><strong>Conditions de Confidentialité</strong></h1>
		<p style={{ marginLeft: 0 }}>Bienvenue sur My Crafting Cloud (le &quot;Site&quot;). Nous accordons une grande
			importance à
			la confidentialité de nos visiteurs. Cette page vous informe de nos politiques concernant la collecte,
			l&apos;utilisation et la divulgation des Informations Personnelles que nous recevons des utilisateurs du
			Site.</p>
		<p style={{ marginLeft: 0 }}>En utilisant le Site, vous acceptez la collecte et l&apos;utilisation des
			informations
			conformément à cette politique.</p>
		<h2 style={{ marginLeft: 0 }}><strong>Collecte et Utilisation des Informations</strong></h2>
		<p style={{ marginLeft: 0 }}>Pendant que vous utilisez notre Site, il se peut que nous vous demandions de nous
			fournir certaines informations personnellement identifiables qui peuvent être utilisées pour vous contacter
			ou vous identifier. Les informations personnellement identifiables (&quot;Informations Personnelles&quot;)
			peuvent
			inclure, mais ne sont pas limitées à :</p>
		<ul>
			<li>
				<p style={{ marginLeft: 0 }}>Votre nom</p>
			</li>
			<li>
				<p style={{ marginLeft: 0 }}>Votre adresse e-mail</p>
			</li>
		</ul>
		<h2 style={{ marginLeft: 0 }}><strong>Protection des Informations</strong></h2>
		<p style={{ marginLeft: 0 }}>La sécurité de vos Informations Personnelles est importante pour nous, mais
			rappelez-vous qu&apos;aucune méthode de transmission sur Internet, ou méthode de stockage électronique,
			n&apos;est
			sûre à 100%. Bien que nous nous efforcions d&apos;utiliser des moyens commercialement acceptables pour
			protéger
			vos Informations Personnelles, nous ne pouvons pas garantir leur sécurité absolue.</p>
		<h2 style={{ marginLeft: 0 }}><strong>Modifications à cette Politique de Confidentialité</strong></h2>
		<p style={{ marginLeft: 0 }}>Cette Politique de Confidentialité est effective à partir de 23 avril 2024 et
			restera en vigueur sauf en ce qui concerne les modifications éventuelles de ses dispositions à
			l&apos;avenir, qui
			seront en vigueur immédiatement après leur publication sur cette page.</p>
		<p style={{ marginLeft: 0 }}>Nous nous réservons le droit de mettre à jour ou de modifier notre Politique de
			Confidentialité à tout moment et vous devriez vérifier cette Politique de Confidentialité périodiquement.
			Votre utilisation continue du Service après que nous ayons publié des modifications sur cette page constitue
			votre reconnaissance des modifications et votre consentement à les respecter et à être lié par elles.</p>
		<h2 style={{ marginLeft: 0 }}><strong>Contactez-nous</strong></h2>
		<p style={{ marginLeft: 0 }}>Si vous avez des questions concernant cette Politique de Confidentialité, veuillez
			nous contacter à <a href="mailto:mycraftingcloud@gmail.com">mycraftingcloud@gmail.com</a>.</p>
	</>;
};

export default PrivacyPolicyView;
