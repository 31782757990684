export async function post(url: string, data: object = {}, contentType: string | null = 'application/json') {
	const res = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, {
		method: 'POST',
		headers: {
			...contentType ? { 'Content-Type': contentType } : {}
		},
		credentials: 'include',
		...data
	});
	return res.json();
}
