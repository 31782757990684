import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import useUserProfile from 'customHooks/data/useUserProfile';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { PatternForm } from 'views/patterns/PatternForm/PatternForm';

const PatternCreationView = () => {
	const { profile, loadingProfile, canCreatePatterns } = useUserProfile();
	const navigate = useNavigate();

	return <FlexContainer column>
		{loadingProfile && <LoadingOverlay/>}
		{!loadingProfile && canCreatePatterns && <PatternForm formTitle={'New pattern'}/>}
		{!loadingProfile && profile && !canCreatePatterns && (
			<>
				<div className="pv">
					{`${translate({ key: 'You have reached the limits of sewing patterns you are allowed to create' })}`}
					&nbsp;
					{`(${profile.currentCount.patterns}/${profile.limits.patterns}).`}
				</div>
				<Button
					label={translate({ key: 'Go back to patterns list' })}
					onClick={() => navigate('/patterns')}
				/>
			</>
		)}
	</FlexContainer>;
};

export default PatternCreationView;
