import { FlexContainer } from 'components/base/FlexContainer';

const PatternFormFrenchHelpPanel = () => {
	return (
		<FlexContainer column>
			<p>Lorsque vous ajoutez un patron à votre bibliothèque, vous pouvez ajouter autant de catégories, tags
				ou autres informations que vous le souhaitez. Le moteur de recherche se mettra automatiquement à
				jour avec ce que vous avez renseigné! N&apos;hésitez donc pas à ajouter autant de détails que
				possible
				pour pouvoir facilement trier et filtrer vos patrons.</p>
			<p>De plus, en remplissant les informations concernant le type et la quantité de matériel nécessaire
				pour la réalisation du patron, vous obtiendrez la liste des matériaux de votre inventaire avec
				lesquels vous pouvez réaliser ce patron, et vous pourrez en un clic créer un projet.</p>
		</FlexContainer>
	);
};

export default PatternFormFrenchHelpPanel;
