import HostedImageInput from 'components/Inputs/ImageInput/HostedImageInput';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import { translate } from 'services/i18n';
import PatternFormMainContentView from './PatternFormMainContentView/PatternFormMainContentView';

const PatternFormContent = () => {
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;
	return (
		<FormLayout
			imgContent={<HostedImageInput
				id="pattern-image"
				image={state.hostedImage || null}
				remoteUrl={state.imageUrl}
				onChange={image => {
					handleChange('hostedImage', image);
				}}
				onRemoteUrlChange={(url) => {
					handleChange('imageUrl', url);
				}}
				alt={translate({ key: 'Pattern form picture' })}/>}
			mainContent={<PatternFormMainContentView/>}>

		</FormLayout>
	);
};

export default PatternFormContent;
