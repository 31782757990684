import { createContext, useContext } from 'react';
import { FabricDetailsFragment, FabricInput, FileInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export interface IFabricFormContext {
	state: FabricDetailsFragment & { hostedImage?: FileInput };
	handleChange: (name: keyof FabricInput, value: ValueOf<FabricInput>) => void;
}

export const FormContext = createContext<null | IFabricFormContext>(null);

export const FormProvider = FormContext.Provider;

export function useFabricFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useFabricFormContext must be used within a FormContextProvider');
	}
	return context;
}
