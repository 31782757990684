import { useCallback } from 'react';
import { PatternType, QuantityUnit, QueryPatternsArgs, useGetPatternsQuery } from '__generated__/graphql';
import useNotifications from 'customHooks/utils/useNotifications';
import { useSearchParams } from 'react-router-dom';
import useIsDemo from '../data/useIsDemo';

type OverrideFilters = {
	recommendedFabrics?: string[]
	recommendedYarnWeight?: string[]
//	needlesSize?: number[]
	patternType: PatternType[]
	fabricRequirement: number;
	fabricQuantityUnit: QuantityUnit;
	page: number;
}

const PatternTypeMapping: Record<string, PatternType> = {
	knit: PatternType.Knit,
	crochet: PatternType.Crochet,
	sewing: PatternType.Sewing,
	other: PatternType.Other
};

const formatSearchParams = (
	searchParams: URLSearchParams,
	overrideFilters?: Omit<Partial<QueryPatternsArgs> & { page: number }, 'limit'>
): Omit<QueryPatternsArgs, 'limit'> => {
	if (overrideFilters) {
		return overrideFilters;
	}
	const categories = searchParams.get('categories');
	const name = searchParams.get('name');
	const availableFormats = searchParams.get('availableFormats');
	const destinedTo = searchParams.get('destinedTo');
	const tags = searchParams.get('tags');
	const recommendedFabrics = searchParams.get('recommendedFabrics');
	const patternLanguage = searchParams.get('patternLanguage');
	const boughtIn = searchParams.get('boughtIn');
	const brands = searchParams.get('brands');
	const selfDraftedPattern = searchParams.get('selfDraftedPattern');
	const difficulty = searchParams.get('difficulty');
	const isFreePattern = searchParams.get('isFreePattern');
	const fabricRequirement = searchParams.get('fabricRequirement');
	const page = searchParams.get('page');
	const patternType = searchParams.get('patternType');
	const recommendedYarnWeight = searchParams.get('recommendedYarnWeight');
	const fabricQuantityUnit = searchParams.get('fabricQuantityUnit');

	return {
		recommendedFabrics: recommendedFabrics?.split(',') || null,
		patternType: patternType?.split(',').map(type => PatternTypeMapping[type]) || null,
		categories: categories?.split(',') || null,
		name,
		availableFormats: availableFormats?.split(',') || null,
		recommendedYarnWeight: recommendedYarnWeight?.split(',') || null,
		destinedTo: destinedTo?.split(',') || null,
		tags: tags?.split(',') || null,
		patternLanguage: patternLanguage?.split(',') || null,
		boughtIn: boughtIn?.split(',') || null,
		brands: brands?.split(',') || null,
		selfDraftedPattern: selfDraftedPattern !== 'true' ? null : !!selfDraftedPattern,
		difficulty: difficulty?.split(',') || null,
		isFreePattern: isFreePattern !== 'true' ? null : !!isFreePattern,
		fabricRequirement: fabricRequirement ? +fabricRequirement : null,
		fabricQuantityUnit: fabricQuantityUnit as QuantityUnit || null,
		page: page ? +page : 1,
		//needlesSize - todo
	};
};

const usePatternsSearchEngine = ({
	limit,
	overrideFilters
}: { limit?: number, overrideFilters?: OverrideFilters } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const { notifyError } = useNotifications();
	const [searchParams, setSearchParams] = useSearchParams();
	const { data, loading, error } = useGetPatternsQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			...formatSearchParams(searchParams, overrideFilters),
			limit: LIMIT,
			isDemo
		},
		onError: err => {
			notifyError(err.message);
		}
	});

	const updateFilterValues = useCallback((field: keyof QueryPatternsArgs, newValue: string | null) => {
		if (newValue) {
			searchParams.set(field, newValue);
		} else {
			searchParams.delete(field);
		}
		if (field !== 'page') {
			searchParams.set('page', '1');
		}
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const updateUrl = useCallback((field: keyof QueryPatternsArgs, newValue: string | null) => {
		switch (field) {
		case 'brands':
		case 'categories':
		case 'tags':
		case 'difficulty':
		case 'recommendedFabrics':
		case 'boughtIn':
		case 'patternLanguage':
		case 'destinedTo':
		case 'selfDraftedPattern':
		case 'isFreePattern':
		case 'availableFormats':
		case 'name':
		case 'fabricRequirement':
		case 'fabricQuantityUnit':
		case 'needlesSize': //TODO
		case 'recommendedYarnWeight':
		case 'page':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters: formatSearchParams(searchParams),
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		data: data?.patterns
	};
}
;

export default usePatternsSearchEngine;
