import { ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { faQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexContainer } from 'components/base/FlexContainer';
import Panel from 'components/Panel/Panel';
import useIsDemo from 'customHooks/data/useIsDemo';
import styled from 'styled-components';
import { DemonstrationContext } from './DemonstrationPage/DemonstrationContext';

const PanelContainer = styled.div`
  li {
    margin-bottom: 0.5em;
  }

  p {
    width: 100% !important;
  }`
;
const HelpIcon = styled(FlexContainer)`
  border-radius: 2em;
  border: ${({ theme }) => `1px solid ${theme.colors.darkgrey}`};
  padding: 0.7em;
  width: 0.2em;
  height: 0.2em;

  &:hover {
    cursor: pointer;
  }
`;

const HelpPanel = ({ children, panelName }: { children: ReactNode, panelName?: string }) => {
	const isDemo = useIsDemo();
	const demoContext = useContext(DemonstrationContext);
	const [panelOpened, setPanelOpened] = useState(isDemo ? demoContext.getHelpPanelState(panelName).opened : false);
	useEffect(() => {
		if (isDemo) {
			setPanelOpened(demoContext.getHelpPanelState(panelName).opened);
		}
	}, [panelName, isDemo, demoContext]);
	const onClose = useCallback(() => {
		if (isDemo) {
			demoContext.getHelpPanelState(panelName).closePanel();
		}
		setPanelOpened(false);
	}, [isDemo, demoContext, panelName]);

	return (
		<>
			<HelpIcon onClick={() => setPanelOpened(true)}>
				<FontAwesomeIcon icon={faQuestion}/>
			</HelpIcon>
			<Panel
				onClose={onClose}
				onClick={onClose}
				opened={panelOpened}>
				<PanelContainer className="help-panel">
					{children}
				</PanelContainer>
			</Panel>
		</>
	);
};

export default HelpPanel;
