import { ReactNode } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const ButtonsContainer = styled(FlexContainer)`
  @media screen and (max-width: 767px) {
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 2em) !important;
    justify-content: center;
    z-index: 1000;
    background-color: white;
    padding: 1em;
  }
`;

const TitleContainer = styled(FlexContainer)`
  @media screen and (min-width: 768px) and (max-width: 1200px) {
    flex-direction: column-reverse;
  }
`;

interface FormContainerLayoutProps {
	formTitle: string;
	onSave: () => void;
	children: ReactNode;
	loading: boolean;
	isValid: boolean;
	disabledMessage: string;
}

const FormContainerLayout = ({ formTitle, onSave, loading, isValid, children }: FormContainerLayoutProps) => {
	const navigate = useNavigate();
	return loading ? <LoadingOverlay/>
		: (
			<FlexContainer column>
				<FlexContainer className="w-100">
					<h1>
						<FlexContainer>
							<div className="w-100">{translate({ key: formTitle })}</div>
							<ButtonsContainer justifyContent="end">
								<div className="mr-1">
									<Button
										className="align-items-end"
										label={translate({ key: 'Cancel' })}
										onClick={() => navigate(-1)}
										red
									/>
								</div>
								<div>

									<Button
										disableIfDemo
										disabled={!isValid}
										className="align-items-end"
										onClick={onSave} label={translate({ key: 'Save' })}/>
								</div>
							</ButtonsContainer>
						</FlexContainer>
					</h1>
				</FlexContainer>
				<div className="pv-1 w-100">
					{children}
				</div>
			</FlexContainer>
		);
};

export default FormContainerLayout;
