import Badge from 'components/base/Badge';
import { FlexContainer } from 'components/base/FlexContainer';
import { Link, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';

const DemonstrationFrenchHomepage = ({ links }: { links: { label: string, link: string }[] }) => {
	const navigate = useNavigate();
	return (
		<FlexContainer column justifyContent="flex-start">
			<p>L&apos;espace démonstration vous permet de voir à quoi peut ressembler votre espace une fois rempli.
				Vous
				pouvez parcourir l&apos;espace, et accéder aux différentes pages et formulaires pour comprendre
				comment
				fonctionne notre site. Des panneaux d&apos;explications vous donneront plus de détails page par
				page.</p>
			<p>Naviguez dans le menu de gauche pour visiter les différentes parties du site, ou cliquez sur les
				filtres rapides ci-dessous pour visualiser quelques exemples de pages.</p>
			{<FlexContainer alignItems="flex-start">
				{links.map(link => (
					<Badge
						className="mb-1"
						key={link.link}
						label={translate({ key: link.label })}
						onClick={() => navigate(link.link)}/>
				))}
			</FlexContainer>}
			<p>Pour toute demande d&apos;informations complémentaires, n&apos;hésitez pas à nous contacter via
				la <Link
				to={'/contact'}><strong>page dédiée</strong></Link>.</p>
		</FlexContainer>
	);
};

export default DemonstrationFrenchHomepage;
