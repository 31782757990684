import React from 'react';
import { Grid } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import Pagination from 'components/Pagination/Pagination';

type ListLayoutProps = {
	items: any[],
	component: React.FC<any>,
	total?: number,
	currentPage?: number,
	limit?: number,
	onPageChange?: (pageNumber: number) => void,
	className?: string
}
const ListLayout = ({ items, component, total, currentPage, limit, onPageChange, className = '' }: ListLayoutProps) => {
	return (
		<FlexContainer className={`w-100 ${className}`} column>
			<Grid container spacing={2}>
				{(items || []).map((item) => <Grid
					item xs={6} sm={4} md={2}
					key={item._id}
				>
					{component(item)}
				</Grid>)}
			</Grid>
			{!!onPageChange && <FlexContainer className="pt w-100">
				<Pagination
					totalItems={total}
					currentPage={currentPage}
					limit={limit}
					onPageChange={onPageChange}
				/>
			</FlexContainer>}
		</FlexContainer>
	);
};

export default ListLayout;
