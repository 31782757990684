import { useCallback, useEffect, useState } from 'react';
import { namedOperations, useCreateUserWithEmailAndPasswordMutation } from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { Error } from 'components/base/Error';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Inputs/Checkbox';
import PasswordInput from 'components/Inputs/PasswordInput';
import TextInput from 'components/Inputs/TextInput';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { isValidEmail } from 'services/utils';
import GoogleSigninButton from './FirebaseSigninView/GoogleSigninButton';

const SignInView = () => {
	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(false);
	const [email, setEmail] = useState('');
	const [error, setError] = useState<null | string>(null);
	const { user } = useAuthenticatedUser();
	const navigate = useNavigate();

	const [createUser, { loading }] = useCreateUserWithEmailAndPasswordMutation({
		refetchQueries: [namedOperations.Query.getAuthenticatedUser],
		onError: (err) => setError(err.message),
		onCompleted: () => {
			navigate('/', { replace: true });
		}
	});

	useEffect(() => {
		if (user) {
			navigate('/', { replace: true });
		}
	}, [user, navigate]);

	const signin = useCallback(async () => {
		await createUser({ variables: { username, password, email } });
	}, [username, password, email, createUser]);

	useEffect(() => {
		const handlePressEnter = (e: KeyboardEvent) => {
			if (e.key === 'Enter' && email.length && password.length && username.length) {
				signin();
			}
		};
		window.addEventListener('keypress', handlePressEnter);
		return () => window.removeEventListener('keypress', handlePressEnter);
	}, [signin, email, password, username]);

	return !user ? <FlexContainer column>
		<FlexContainer className="w-50 w-md-75" column>
			{(loading && !error) && <LoadingOverlay/>}
			<div className={'mb-1 w-100'}>
				<TextInput
					id={'email-signin'}
					disabled={loading && !error}
					label={translate({ key: 'Email' })}
					value={email}
					onChange={typedEmail => {
						setEmail(typedEmail);
						setError(null);
					}}
					required
					validate={(email: string) => !email.length || isValidEmail(email)}
					errorMessage={translate({ key: 'Invalid email' })}
				/>
			</div>
			<div className={'mb-1 w-100'}>
				<PasswordInput
					disabled={loading && !error}
					label={translate({ key: 'Password' })}
					value={password}
					onChange={(password: string) => setPassword(password)}
					required
				/>
			</div>
			<TextInput
				className={'mb-1 '}
				id="username-signin"
				disabled={loading && !error}
				label={translate({ key: 'Username' })}
				value={username}
				onChange={typedUsername => {
					setUsername(typedUsername.trim());
					setError(null);
				}}
				required
			/>
			<Checkbox
				id="signin-remember-me"
				label={translate({ key: 'Remember me' })}
				checked={rememberMe}
				onChange={() => setRememberMe(!rememberMe)}/>
			{error && <Error className="mt">{error}</Error>}
			<Button
				className="mt w-50 w-sm-75"
				label={translate({ key: 'Create account' })}
				disabled={(loading && !error) || !email?.length || !password?.length || !username?.length || (!!email && !isValidEmail(email))}
				onClick={signin}
			/>
		</FlexContainer>
		<div className="w-50 w-md-75 pv-1">
			<Divider label={translate({ key: 'OR' })}/>
		</div>
		{!user && <GoogleSigninButton/>}
	</FlexContainer> : <></>;
};

export default SignInView;
