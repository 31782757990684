import { useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import RadioInput from 'components/Inputs/RadioInput';
import { translate } from 'services/i18n';
import NewsListView from 'views/news/NewsListView';

const AdminNewsListView = () => {
	const [language, setLanguage] = useState<'fr' | 'en' | 'spa' | 'ita'>('fr');
	return (
		<FlexContainer column>
			<h3>{translate({ key: 'News' })}</h3>
			<RadioInput
				options={['fr', 'en', 'ita', 'spa'].map(value => ({ value, label: value.toUpperCase() }))}
				onChange={lang => setLanguage(lang)}
				value={language}
				inputName={'language'}
			/>
			<NewsListView language={language} admin/>
		</FlexContainer>
	);
};

export default AdminNewsListView;
