import { useEffect, useState } from 'react';
import useVideoUrlParser from 'customHooks/utils/useVideoUrlParser';
import styled from 'styled-components';
import InstagramMedia from './InstagramMedia';
import RemoteImage from './RemoteImage';

interface RemoteVideoProps {
	videoUrl: string;
	videoId?: string;
	embeddedHTML?: string;
	disableClick?: boolean;
	displayThumbnail?: boolean;
	adjustRatio?: boolean;
}

const IFrame = styled.iframe`
  border-radius: 0.5em;
  width: 100%;
  height: fit-content;
  border-width: 0 !important;
`;
const RemoteVideo = ({
	videoUrl,
	embeddedHTML,
	disableClick,
	displayThumbnail = false,
	adjustRatio = true
}: RemoteVideoProps) => {
	const { parseVideoUrl } = useVideoUrlParser();
	const [loaded, setLoaded] = useState(false);
	const [sourceUrl, setSourceUrl] = useState(videoUrl);
	const [displayedHTML, setDisplayedHTML] = useState(embeddedHTML);
	const [thumbnail, setThumbnail] = useState(null);
	const [provider, setProvider] = useState(null);
	useEffect(() => {
		parseVideoUrl({ videoUrl }).then(data => {
			setSourceUrl(data.videoUrl);
			setDisplayedHTML(data.embeddedHTML);
			setProvider(data.provider);
			setThumbnail(data.thumbnailUrl);
			setLoaded(true);
		});
	}, [videoUrl, parseVideoUrl]);
	if (!loaded) {
		return <></>;
	}
	if (displayThumbnail && thumbnail) {
		return <RemoteImage src={thumbnail} alt={videoUrl}/>;
	}
	if (provider === 'Instagram') {
		return <InstagramMedia url={videoUrl}/>;
	}
	return displayedHTML ? <div dangerouslySetInnerHTML={{ __html: displayedHTML }}/> :
		<IFrame
			src={sourceUrl}
			style={{
				...adjustRatio ? { aspectRatio: provider === 'Youtube' ? '16 / 9' : '9 / 16' } : { height: '100%' },
				...disableClick ? { pointerEvents: 'none' } : {}
			}}
		/>;
};

export default RemoteVideo;
