import { FabricDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';

const DeleteFabricModal = ({ fabric }: { fabric: FabricDetailsFragment }) => {
	return (<FlexContainer column>
		<div style={{ alignSelf: 'flex-start', paddingBottom: '1em' }}>
			{`${translate({ key: 'Are you sure you want to delete this item' })} : ${fabric.name} ?`}
		</div>
		{!!fabric.imageUrl && <div className="w-50 pt-1">
			<RemoteImage src={fabric.imageUrl} alt={fabric.name}/>
		</div>}
	</FlexContainer>
	);
};

export default DeleteFabricModal;
