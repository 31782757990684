import { FlexContainer } from 'components/base/FlexContainer';
import styled from 'styled-components';

export const StyledFilterView = styled.div`
    display: grid;
    grid-template-columns: 10em auto;
    margin-bottom: 1em;
  @media screen and (max-width: 767px) {
    grid-template-columns: 100%;
  }
`;

export const StyledBadges = styled(FlexContainer)`
    flex-wrap: wrap;
    justify-content: flex-start;
    .filter-badge {
        margin-bottom: 0.5em;
    }
`;
