import { useState } from 'react';
import { StashItemType } from '__generated__/graphql';
import { Button } from 'components/Button/Button';
import useUserProfile from 'customHooks/data/useUserProfile';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { FabricForm } from 'views/fabrics/FabricForm/FabricForm';
import YarnForm from 'views/yarns/YarnForm/YarnForm';


const StashItemCreationView = () => {
	const { profile, loadingProfile } = useUserProfile();
	const [itemType, setItemType] = useState(StashItemType.Fabric);
	const navigate = useNavigate();
	const canCreateFabric = profile && (!profile.limits.fabrics ||
		(profile.limits.fabrics && (profile.currentCount.fabrics || 0) < profile.limits.fabrics));

	//TODO - style radio input
	return (<>
		{!loadingProfile && canCreateFabric && itemType === StashItemType.Fabric &&
		<FabricForm onSwitchItemType={type => setItemType(type)} formTitle={'New item'}/>}
		{!loadingProfile && canCreateFabric && itemType === StashItemType.Yarn &&
		<YarnForm onSwitchItemType={type => setItemType(type)} formTitle={'New item'}/>}
		{!loadingProfile && profile && !canCreateFabric && (
			<>
				<div className="pv">
					{`${translate({ key: 'You have reached the limits of stash items you are allowed to create' })}`}
					&nbsp;
					{`(${profile.currentCount.fabrics}/${profile.limits.fabrics}).`}
				</div>
				<Button
					label={translate({ key: 'Go back to stash' })}
					onClick={() => navigate('/stash')}
				/>
			</>
		)}
	</>);
};

export default StashItemCreationView;
