import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidURL } from 'services/utils';
import { FlexContainer } from './FlexContainer';

const ExternalLink = ({ link, label }: { link?: string, label?: string }) => {
	if (!link) return <></>;
	return isValidURL(link) ?
		<FlexContainer justifyContent="start">
			<a href={link} target="_blank" rel="noreferrer">
				<span className="cursor-pointer-bold">{label || link}</span>
			</a>
			&nbsp;
			<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
		</FlexContainer>
		: link;
};

export default ExternalLink;
