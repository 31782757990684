import { useCallback, useEffect, useState } from 'react';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import PasswordInput from 'components/Inputs/PasswordInput';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { resetPasswordWithToken } from 'services/api/rest/accountService';
import { translate } from 'services/i18n';

const ResetPasswordView = () => {
	const navigate = useNavigate();
	const [password, setPassword] = useState<string | null>(null);
	const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
	const [params] = useSearchParams();
	const token = params.get('token');

	const resetPassword = useCallback((password: string) => {
		if (token) {
			resetPasswordWithToken(password, token).then(() => {
				navigate('/login');
			});
		}
	}, [navigate, token]);

	useEffect(() => {
		const handlePressEnter = (e: KeyboardEvent) => {
			if (e.key === 'Enter' && confirmPassword?.length && password?.length && password === confirmPassword) {
				resetPassword(password);
			}
		};
		window.addEventListener('keypress', handlePressEnter);
		return () => window.removeEventListener('keypress', handlePressEnter);
	}, [confirmPassword, password, resetPassword]);

	return (
		<FlexContainer column>
			<FlexContainer className="w-50 w-md-75 w-sm-100" column>
				<PasswordInput
					onChange={(password: string) => {
						setPassword(password);
					}}
					label={translate({ key: 'New password' })}
				/>
				<PasswordInput
					onChange={(password: string) => {
						setConfirmPassword(password);
					}}
					label={translate({ key: 'Confirm new password' })}
				/>
			</FlexContainer>
			<Div className="pt w-fit-content">
				<Button
					disabled={!password?.length || !confirmPassword?.length || password !== confirmPassword}
					label={translate({ key: 'Reset password' })}
					onClick={() => {
						if (password) {
							resetPassword(password);
						}
					}}/>
			</Div>
		</FlexContainer>
	);
};

export default ResetPasswordView;
