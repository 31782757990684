import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { translate } from 'services/i18n';

const YarnFormSubmitView = ({ onSubmit, disabled }: { onSubmit: () => void, disabled: boolean }) => {

	return <FlexContainer className="pt justify-end justify-sm-center">
		<Button disabled={disabled} onClick={onSubmit} label={translate({ key: 'Save' })}/>
	</FlexContainer>;
};

export default YarnFormSubmitView;
