import ExpendableContainer from 'components/base/ExpendableContainer';
import Checkbox from 'components/Inputs/Checkbox';
import MultiSelect from 'components/Inputs/MultiSelect';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import PatternFormDestinedToSelectionView
	from 'components/PatternForms/PatternFormDestinedToSelectionView/PatternFormDestinedToSelectionView';
import useUserFilters from 'customHooks/data/useUserFilters';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import { translate } from 'services/i18n';

const PatternFormAdditionalInfosView = () => {
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;
	const { updateUserFilters, boughtIn, patternLanguage, availableFormats, } = useUserFilters();

	return (
		<ExpendableContainer className="w-100 " label={translate({ key: 'Additional informations' })}>
			<GridContainer className="pt-1">
				<GridItem>
					<Select
						value={state.boughtIn ? { value: state.boughtIn, label: state.boughtIn } : null}
						data={boughtIn.map(value => ({ value, label: value }))}
						creatable
						onCreate={(boughtIn: string) => updateUserFilters({ 'boughtIn': [boughtIn] })}
						label={translate({ key: 'Bought in' })}
						onChange={boughtIn => handleChange('boughtIn', boughtIn)}
					/>
				</GridItem>
				<GridItem>
					<Select
						creatable
						onCreate={(patternLanguage: string) => updateUserFilters({ 'patternLanguage': [patternLanguage] })}
						data={(patternLanguage || []).map(value => ({ value, label: value }))}
						value={state.patternLanguage ? {
							value: state.patternLanguage,
							label: state.patternLanguage
						} : null}
						label={translate({ key: 'Language' })}
						onChange={patternLanguage => handleChange('patternLanguage', patternLanguage)}
					/>
				</GridItem>
				<GridItem>
					<Checkbox
						onChange={(checked) => {
							handleChange('fittingAdjustmentRequired', !!checked);
						}}
						checked={!!state.fittingAdjustmentRequired}
						label={translate({ key: 'Fitting adjustment required' })}
						id="fitting-adjustment"
					/>
				</GridItem>
				<GridItem>
					<Checkbox
						onChange={(checked) => {
							handleChange('projectorAdjustmentRequired', !!checked);
						}}
						checked={!!state.projectorAdjustmentRequired}
						label={translate({ key: 'Projector adjustment required' })}
						id="projector-adjustment"
					/>
				</GridItem>
				<GridItem>
					<TextInput
						id={'applicable-size-chart'}
						value={state.applicableSizeChart || ''}
						label={translate({ key: 'Applicable size chart' })}
						onChange={value => handleChange('applicableSizeChart', value)}/>
				</GridItem>
				<GridItem>
					<PatternFormDestinedToSelectionView
						onChange={value => handleChange('destinedTo', value)}
						selectedDestinedTo={state.destinedTo}/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Available formats' })}
						onChange={formats => handleChange('availableFormats', formats)}
						data={availableFormats}
						value={state.availableFormats.map(value => ({ value, label: translate({ key: value }) }))}
					/>
				</GridItem>
				<GridItem>
					<Checkbox
						checked={state.selfDraftedPattern}
						id="self-drafted-pattern"
						label={translate({ key: 'Self drafted pattern' })}
						onChange={(checked) => handleChange('selfDraftedPattern', !!checked)}/>
				</GridItem>
			</GridContainer>
		</ExpendableContainer>
	);
};

export default PatternFormAdditionalInfosView;
