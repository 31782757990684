import { FunctionComponent, useCallback } from 'react';
import { FormControl, FormControlProps, FormLabel, TextField } from '@mui/material';

interface NumberInputProps extends FormControlProps {
	onNumberChange: (value: number) => void;
	value: number | string;
	label?: string;
	placeholder?: string;
	innerLabel?: boolean;
}

const NumberInput: FunctionComponent<NumberInputProps> = ({
	value,
	label,
	onNumberChange,
	innerLabel,
	placeholder,
	...props
}) => {
	const onInnerChange = useCallback((text: string) => {
		if (!isNaN(Number.parseFloat(text))) {
			onNumberChange(parseFloat(text));
		} else {
			onNumberChange(null);
		}
	}, [onNumberChange]);
	return (
		<FormControl {...props}>
			{!innerLabel && <FormLabel>{label}</FormLabel>}
			<TextField
				placeholder={placeholder}
				onChange={(e) => onInnerChange(e.target.value)}
				value={value || ''}
			/>
		</FormControl>

	);
};

export default NumberInput;
