import { useState } from 'react';

const useDropboxChooser = () => {
	const [selectedFile, setSelectedFile] = useState(null);

	const openDropboxChooser = (cb?: () => void) => {
		const options = {
			success: (files: any) => {
				setSelectedFile({
					name: files[0].name,
					url: files[0].link
				});
				if (cb) {
					cb();
				}
			},
			cancel: () => {
				setSelectedFile(null);
			},
			linkType: 'preview', // or 'direct'
			multiselect: false, // or true if you want to allow multiple file selection
		} as any;

		window.Dropbox.choose(options);
	};

	return {
		selectedFile,
		openDropboxChooser,
		resetSelectedFile: () => setSelectedFile(null)
	};
};

export default useDropboxChooser;
