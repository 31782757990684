import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InspirationDetailsFragment } from '__generated__/graphql';
import Badge from 'components/base/Badge';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import { FlexContainer } from 'components/base/FlexContainer';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';

const InspirationDetailsContentView = ({ inspiration }: { inspiration: InspirationDetailsFragment }) => {
	const navigate = useNavigate();
	return (
		<GridContainer>
			<FieldDescriptionBox
				label={translate({ key: 'Media type' })}
				content={inspiration.type ? translate({ key: inspiration.type }) : '--'}
			/>
			<FieldDescriptionBox
				label={translate({ key: 'Source' })}
				content={inspiration.sourceUrl ? <a
					style={{ overflowWrap: 'anywhere' }}
					href={inspiration.sourceUrl}
					target="_blank"
					rel="noreferrer">
					{inspiration.sourceUrl.split('?')[0]}
					&nbsp;
					<FontAwesomeIcon icon={faArrowUpRightFromSquare}/>
				</a> : '--'}
			/>
			<FieldDescriptionBox
				label={translate({ key: 'Type' })}
				content={inspiration.craftType ? translate({ key: inspiration.craftType }) : '--'}
			/>
			<FieldDescriptionBox
				label={translate({ key: 'Categories' })}
				content={<FlexContainer justifyContent="flex-start">
					{inspiration.categories?.length ?
						inspiration.categories.map(category => <Badge
							className="cursor-pointer"
							color="green"
							key={category}
							label={`${category}`}
							onClick={() => navigate(`/inspirations/categories/${category}?categories=${category}`)}
						/>)
						: '--'}
				</FlexContainer>}
			/>
			<FieldDescriptionBox
				label={translate({ key: 'Tags' })}
				content={<FlexContainer justifyContent="flex-start">
					{
						inspiration.tags?.length ?
							inspiration.tags.map(tag => <Badge
								className="cursor-pointer"
								key={tag}
								color="blue"
								label={`#${tag}`}
								onClick={() => navigate(`/inspirations/tags/${tag}?tags=${tag}`)}
							/>
							) :
							'--'
					}
				</FlexContainer>}
			/>
		</GridContainer>
	);
};

export default InspirationDetailsContentView;
