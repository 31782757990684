import WipPageView from 'views/wip/WipPageView';

const DesignCreationView = () => {
	return <WipPageView/>
	/*<FlexContainer>
				<DesignApp/>
			</FlexContainer>*/;
};

export default DesignCreationView;
