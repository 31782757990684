import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

interface CreateProjectModalContentLayoutProps {
	headerText: string;
	patternImage: {
		name: string
		imageUrl?: string
	};
	stashImage: {
		name: string
		imageUrl?: string
	};
}

const CreateProjectModalContentLayout = ({
	patternImage,
	stashImage,
	headerText
}: CreateProjectModalContentLayoutProps) => {
	return (
		<FlexContainer column>
			<div style={{ alignSelf: 'flex-start' }}>
				{`${translate({ key: headerText })} ?`}
			</div>
			{(patternImage.imageUrl || stashImage.imageUrl) && <GridContainer className={'pt-1'}>
				<GridItem xs={6}><RemoteImage src={patternImage.imageUrl} alt={patternImage.name}/></GridItem>
				<GridItem xs={6}><RemoteImage src={stashImage.imageUrl} alt={stashImage.name}/></GridItem>
			</GridContainer>}
		</FlexContainer>
	);
};

export default CreateProjectModalContentLayout;
