import { FlexContainer } from '../../../components/base/FlexContainer';

const InspirationEnglishHelpPanel = () => {
	return (
		<FlexContainer column>
			<p>To add your inspirations, you must retrieve the url of the image or video you would like to save.</p>
			<p><u>Images :</u></p>
			<p>
				In most cases, you will be able to get the url of an image by right clicking on it and
				selecting &apos;Copy the url of the image&apos;.
				For Instagram posts, you can copy the url from the URL bar or selecting &apos;Copy link&apos; in the
				post menu.
			</p>
			<p><u>Videos :</u></p>
			<p>You can add videos from Youtube, Instagram and Tiktok.&nbsp;</p>
			<ul>
				<li><strong>Youtube</strong> : Copy the url from the URL bar or right click on the video, then
					select &#8220;Copy video URL&#8220;</li>
				<li><strong>Instagram</strong> : Copy the url from the URL bar or select &#8220;Copy link&#8220; in the
					post menu
				</li>
				<li><strong>Tiktok</strong> : Copy the url from the URL bar or select &#8220;Copy link&#8220; on the
					post
				</li>
			</ul>
			<p>If the URL is valid and correct, a thumbnail or a miniature will be displayed behind the input field.</p>
		</FlexContainer>
	);
};

export default InspirationEnglishHelpPanel;
