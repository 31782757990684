import { faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PatternDetailsFragment, PatternType } from '__generated__/graphql';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteVideo from 'components/base/RemoteVideo';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import { isValidURL } from 'services/utils';

const PatternDetailsDescriptionView = ({ pattern }: { pattern: PatternDetailsFragment }) => {
	const { notifyInfo } = useNotifications();
	return <GridContainer>
		<FieldDescriptionBox
			label={translate({ key: 'Categories' })}
			content={pattern.categories?.length ?
				pattern.categories.map((category: string) => category.toUpperCase()).join(' - ') : '--'
			}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Tags' })}
			content={pattern.tags?.length ?
				pattern.tags.map((tag: string) => tag.toUpperCase()).join(' - ') : '--'
			}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Recommended fabrics' })}
			content={pattern.recommendedFabrics?.length ?
				pattern.recommendedFabrics.map((fabric: string) => translate({ key: fabric }).toUpperCase()).join(' - ') : '--'
			}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Destined to' })}
			content={pattern.destinedTo.map(key => translate({ key })).join(' - ')}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Quantity required' })}
			content={pattern.fabricRequirement && pattern.fabricQuantityUnit ?
				(`${pattern.fabricRequirement} ${translate({ key: pattern.fabricQuantityUnit })}`) : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Available formats' })}
			content={pattern.availableFormats?.length ?
				pattern.availableFormats.map((key: string) => translate({ key })).join(' - ') :
				'--'
			}
		/>
		{(pattern.patternType === PatternType.Knit || pattern.patternType === PatternType.Crochet) &&
		<FieldDescriptionBox
			label={translate({ key: 'Needles size' })}
			content={pattern.needlesSize ?
				pattern.needlesSize.join(' - ') :
				'--'
			}
		/>}
		<FieldDescriptionBox
			label={translate({ key: 'Videos' })}
			content={<GridContainer>
				{(pattern.relevantVideos || []).map(({
					url,
					name
				}, index) => (
					<GridItem md={3} key={index}>
						<RemoteVideo videoUrl={url}/>
						<FlexContainer>{name}</FlexContainer>
					</GridItem>
				))}
				{(!pattern.relevantVideos || !pattern.relevantVideos.length) && '--'}
			</GridContainer>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Files' })}
			content={<div>
				{(pattern.files || []).map(({
					url,
					name, notes
				}, index: number) => (
					<div key={index} className="mb-1">
						<div>
							<span className="mr-1"> <FontAwesomeIcon icon={faFileAlt}/></span>
							<span className="mr-1">{name}</span>
							{isValidURL(url) ?
								<a
									className="cursor-pointer-bold underline"
									href={url} target="_blank"
									rel="noopener noreferrer">{translate({ key: 'Open link' })}
								</a> :
								<span
									className="cursor-pointer-bold underline"
									onClick={() => {
										navigator.clipboard.writeText(url).then(function () {
											notifyInfo('Text copied to clipboard');
										});
									}}>
									{translate({ key: 'Copy link' })}
								</span>}
						</div>
						{notes && <div style={{ fontSize: '12px' }}>{notes}</div>}
					</div>
				))}
				{(!pattern.files || !pattern.files.length) && '--'}
			</div>}
		/>
		<FieldDescriptionBox label={translate({ key: 'Language' })} content={pattern.patternLanguage}/>
		<FieldDescriptionBox label={translate({ key: 'Bought in' })} content={pattern.boughtIn}/>
	</GridContainer>;
};

export default PatternDetailsDescriptionView;
