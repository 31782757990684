import 'firebase/compat/auth';
import { useEffect } from 'react';
import { namedOperations, useLoginWithProviderMutation } from '__generated__/graphql';
import { useLocation, useNavigate } from 'react-router-dom';

const GoogleSigninButton = () => {
	const navigate = useNavigate();
	const { state } = useLocation() as { state: { from: { pathname: string, search: string } } };

	const [loginWithProvider] = useLoginWithProviderMutation({
		onCompleted: async () => {
			navigate(state?.from ? `${state.from.pathname}${state.from.search}` : '/', { replace: true });
		},
		refetchQueries: [namedOperations.Query.getProfile, namedOperations.Query.getAuthenticatedUser]
	});
	useEffect(() => {
		const renderButton = () => {
			window.google.accounts.id.initialize({
				client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
				callback: (data: { credential: string }) => {
					loginWithProvider({ variables: { token: data.credential } });
				},
			});
			window.google.accounts.id.renderButton(
				document.getElementById('googleButton'),
				{ theme: 'outline', size: 'large', dataSize: 'large', type: 'signin', login_hint: null }
			);
		};
		renderButton();
	}, [loginWithProvider]);

	return <div>
		<div id="googleButton"/>
	</div>;
};

export default GoogleSigninButton;


