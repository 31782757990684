import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';
import PatternFormEnglishHelpPanel from './PatternFormEnglishHelpPanel';
import PatternFormFrenchHelpPanel from './PatternFormFrenchHelpPanel';
import PatternFormItalianHelpPanel from './PatternFormItalianHelpPanel';

const PatternFormHelpPanel = () => {
	const userLanguage = getCurrentLanguage();
	return (
		<FlexContainer column>
			<h2>{translate({ key: 'Pattern creation' })}</h2>
			{userLanguage === 'fr' && <PatternFormFrenchHelpPanel/>}
			{userLanguage === 'en' && <PatternFormEnglishHelpPanel/>}
			{userLanguage === 'ita' && <PatternFormItalianHelpPanel/>}
		</FlexContainer>
	);
};

export default PatternFormHelpPanel;
