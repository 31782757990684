import { useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import TextInput from 'components/Inputs/TextInput';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { translate } from 'services/i18n';
import RemoteVideo from '../../../../../components/base/RemoteVideo';

interface PatternFormVideoModalViewProps {
	opened: boolean;
	onConfirm: (video: { name: string, url: string }) => void;
	onClose: () => void;
	video?: { name: string, url: string };
}

const PatternFormVideoModalView = ({ opened, onClose, onConfirm, video }: PatternFormVideoModalViewProps) => {
	const [name, setName] = useState(video?.name || '');
	const [url, setUrl] = useState(video?.url || '');
	return (
		<ModalContainer
			opened={opened}
			onConfirm={() => {
				onConfirm({ name, url });
				setUrl('');
				setName('');
				onClose();
			}}
			onClose={onClose}
			title={translate({ key: 'Add video' })}
			confirmDisabled={!url || !name}
		>
			<FlexContainer column>
				<div className="mb-1 w-100">
					<TextInput
						id="video-name"
						onChange={name => setName(name)}
						label={translate({ key: 'Name' })}
						value={name}
					/>
				</div>
				<div className="mb-1 w-100">
					<TextInput
						id="video-url"
						onChange={url => setUrl(url)}
						label={translate({ key: 'Url' })}
						value={url}
					/>
				</div>
				{url && <RemoteVideo videoUrl={url}/>}
			</FlexContainer>
		</ModalContainer>
	);
};

export default PatternFormVideoModalView;
