const ImageFrenchHelpPanel = () => {
	return <>
		<p><u>Method 1 : Copy/paste the url of an image from a website</u></p>
		<p>To use that method, just right click on the image you would like to add, then click on &#8220;Copy the url of
			the image&#8220;
			in the menu. You can then paste the link in the field input &#8220;Image url&#8220;.</p>
		<p>This is the easiest and quickest way to add an image.</p>
		<p><u>Method 2 : Use a free image hosting website</u></p>
		<p>You can upload an image on an image hosting website, then use method 1 to add the url of the image.
		</p>
		<p>This method is a bit longer than method 1 but the image will always be accessible.</p>
		<p><u>Method 3 : Save the image directly on My Crafting Cloud</u></p>
		<p>
			You can upload your image by clicking on the icon to have your image hosted on the website. </p>
		<p> From a mobile
			device, you
			will also have the possibility to take a photo from your device.
			This method will be very useful if you don&apos;t have a picture of your materials, or if you want to take a
			picture of a bundle you have assembled for your project.
		</p>
	</>;
};
export default ImageFrenchHelpPanel;
