import { useState } from 'react';
import { namedOperations, useSaveMessageMutation } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import Select from 'components/Inputs/Select';
import TextArea from 'components/Inputs/TextArea';
import TextInput from 'components/Inputs/TextInput';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import { isValidEmail } from 'services/utils';

const ContactPageView = () => {
	const { notifyError, notifyInfo } = useNotifications();
	const { user } = useAuthenticatedUser();
	const [subject, setSubject] = useState<string>('');
	const [message, setMessage] = useState('');
	const [email, setEmail] = useState(user?.email || '');
	const [saveMessage, { loading }] = useSaveMessageMutation({
		refetchQueries: [namedOperations.Query.getMessages],
		onError: err => {
			notifyError(err.message);
		},
		onCompleted: () => {
			notifyInfo(translate({ key: 'Message sent' }));
		}
	});
	const subjects = [
		translate({ key: 'Get information' }),
		translate({ key: 'Report a bug' }),
		translate({ key: 'Professional contact' }),
		translate({ key: 'Other' })
	];
	return (
		<FlexContainer column>
			{loading && <LoadingOverlay/>}
			<h1>{translate({ key: 'Contact' })}</h1>
			<div className="pv-1">
				<span>{translate({ key: 'If you have any question or want to report a bug send me a mail at' })}&nbsp;</span>
				<b><u><a
					href="mailto:mycraftingcloud@gmail.com">mycraftingcloud@gmail.com</a></u></b>
				&nbsp;
				<span>{translate({ key: 'or use the contact form below' })}.</span>
			</div>
			<div className="w-50 w-md-100">
				<TextInput
					className={'pt-1'}
					required
					disabled={!!user}
					label={translate({ key: 'Email address' })}
					onChange={email => setEmail(email)}
					value={email}
					id={'contact-email-address'}
					validate={(email) => !email?.length || isValidEmail(email)}
					errorMessage={translate({ key: 'Invalid email' })}
				/>
				<Select
					className={'pt-1'}
					required
					label={translate({ key: 'Subject' })}
					onChange={subject => setSubject(subject)}
					data={(subjects || []).map(subject => ({ label: subject, value: subject }))}
					value={{ label: subject ? translate({ key: subject }) : null, value: subject }}
				/>
				<div className="pt-1">
					<TextArea
						label={translate({ key: 'Message' })}
						value={message}
						onChange={message => setMessage(message)}
						rows={6}
						required
					/>
				</div>
				<FlexContainer className="pt">
					<Button
						disabled={loading || !subject || !message.trim().length || !email.length || !isValidEmail(email)}
						label={translate({ key: 'Send' })}
						onClick={async () => {
							await saveMessage({
								variables: {
									input: {
										message,
										email,
										subject
									}
								}
							});
						}}
					/>
				</FlexContainer>
			</div>
		</FlexContainer>
	);
};

export default ContactPageView;
