import { ToastContainer } from 'react-toastify';

const NotificationContainer = () => {
	//TODO - font
	return (
		<ToastContainer
			position="bottom-right"
			hideProgressBar
			style={{
				fontFamily: 'Comfortaa,-apple-system, BlinkMacSystemFont,sans-serif!important'
			}}
		/>
	);
};

export default NotificationContainer;
