import { ReactNode } from 'react';
import styled from 'styled-components';

type Gradient = {
	from: string, to: string
}

export interface IColors {
	blue: Gradient;
	green: Gradient;
	orange: Gradient;
	pink: Gradient;
	limo: Gradient;
}

interface BadgeProps {
	label: string;
	color?: keyof IColors;
	onClick?: () => void;
	className?: string;
}

interface StyledBadgeProps {
	children: ReactNode,
	gradient: {
		from: string,
		to: string
	},
	clickable: boolean,
	className?: string
	onClick?: () => void
}

const StyledBadge = styled.div<StyledBadgeProps>`
  margin-right: 0.5em;
  height: 1em;
  border-radius: 0.7em;
  padding: 0.5em;
  background: ${({ gradient }) => `linear-gradient(to left, ${gradient.from},${gradient.to})`};
  width: fit-content;

  &:hover {
    cursor: ${({ clickable }) => clickable ? 'pointer' : 'default'};
    font-weight: ${({ clickable }) => clickable ? 'bold' : 'initial'};
    color: ${({ clickable, theme }) => clickable ? theme.colors.darkgrey : 'white'};
  }
`;

const Badge = ({ label, color = 'blue', onClick, className }: BadgeProps) => {
	const colors = {
		blue: { from: '#c5cbfa', to: '#dcadf7' },
		green: { from: '#bdffcf', to: '#daecf7' },
		orange: { from: 'yellow', to: 'orange' },
		pink: { from: 'violet', to: 'pink' },
		limo: { from: '#bdffcf', to: '#c5cbfa' }
	} as IColors;
	return <StyledBadge
		className={className}
		gradient={colors[color]}
		clickable={!!onClick}
		onClick={onClick}>
		{label}
	</StyledBadge>;
};
export default Badge;
