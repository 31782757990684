import MultiSelectInput from 'components/Inputs/MultiSelect';
import { translate } from 'services/i18n';

interface PatternFormDestinedToSelectionViewProps {
	selectedDestinedTo: string[];
	onChange: (value: string[]) => void;
}

const PatternFormDestinedToSelectionView = ({
	selectedDestinedTo,
	onChange
}: PatternFormDestinedToSelectionViewProps) => {
	const DESTINED_TO = [
		{ value: 'everyone', label: translate({ key: 'Everyone' }) },
		{ value: 'women', label: translate({ key: 'Women' }) },
		{ value: 'men', label: translate({ key: 'Men' }) },
		{ value: 'children', label: translate({ key: 'Children' }) },
	];

	return <MultiSelectInput
		label={translate({ key: 'Destined to' })}
		data={DESTINED_TO}
		creatable={false}
		value={selectedDestinedTo.map(selectedValue => DESTINED_TO.find(({ value }) => value === selectedValue))}
		onChange={value => onChange(value)}/>;

};

export default PatternFormDestinedToSelectionView;
