import { QuantityUnit } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import NeedlesSizeSelectInput from 'components/Inputs/NeedlesSizeSelectInput';
import QuantityInput from 'components/Inputs/QuantityInput';
import SearchInput from 'components/Inputs/SearchInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import useYarnsSearchEngine from 'customHooks/searchEngine/useYarnsSearchEngine';
import { translate } from 'services/i18n';

const YarnsListFiltersView = () => {
	const { currentFilters, updateUrl } = useYarnsSearchEngine();
	const { loading, yarnWeights, colors, composition } = useUserFilters();

	return (
		<FlexContainer className="w-100">
			{loading && <LoadingOverlay/>}
			<GridContainer>
				<GridItem xs={12} md={12}>

					<SearchInput
						className="pt-0"
						label={translate({ key: 'Name' })}
						value={currentFilters.name || ''}
						onChange={name => {
							updateUrl('name', name);
						}}
						id={'yarn-search-name'}
					/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Yarn weight' })}
						onChange={(weight) => updateUrl('weightCategories', weight.join(','))}
						data={yarnWeights}
						creatable={false}
						value={currentFilters.weightCategories.map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
				<GridItem>
					<NeedlesSizeSelectInput
						onChange={data => updateUrl('needlesSize', data.join(','))}
						values={(currentFilters.needlesSize || []).map(v => +v)}/>
				</GridItem>
				<GridItem>
					<QuantityInput
						label="Available quantity"
						onChange={({ quantity, unit }) => {
							updateUrl('quantity', quantity.toString());
							//TODO - adjust
							//updateUrl('quantityUnit', unit);
						}
						}
						quantity={currentFilters.quantity}
						unit={QuantityUnit.Meters}/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Colors' })}
						onChange={colors => updateUrl('colors', colors.join(','))}
						data={colors.map(value => ({ value, label: value }))}
						creatable={false}
						value={currentFilters.colors.map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Composition' })}
						onChange={composition => updateUrl('composition', composition.join(','))}
						data={composition.map(value => ({ value, label: value }))}
						creatable={false}
						value={(currentFilters.composition || []).map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
			</GridContainer>
		</FlexContainer>
	);
};

export default YarnsListFiltersView;
