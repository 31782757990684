import {
	AvgStatsResults,
	StatsByMonthResult,
	useAvgStatsQuery,
	useStatsByMonthQuery,
	useSubscriptionsStatsQuery
} from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	Legend,
	Line,
	LineChart,
	Pie,
	PieChart,
	Tooltip,
	XAxis,
	YAxis
} from 'recharts';

const formatStatByMonthData = (data: StatsByMonthResult) => {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const currentMonth = (new Date()).getMonth();
	const orderedMonth = [
		...months.slice(currentMonth + 1),
		...months.slice(0, currentMonth + 1)];
	return orderedMonth.map((month) => {
		const usersForMonth = data.users.find(elem => elem.month === month);
		const patternsForMonth = data.patterns.find(elem => elem.month === month);
		const projectsForMonth = data.projects.find(elem => elem.month === month);
		const inspirationsForMonth = data.inspirations.find(elem => elem.month === month);
		const itemsForMonth = data.stashItems.find(elem => elem.month === month);
		const creationsForMonth = data.creations.find(elem => elem.month === month);
		return {
			month: month.toUpperCase(),
			users: usersForMonth?.count || 0,
			patterns: patternsForMonth?.count || 0,
			projects: projectsForMonth?.count || 0,
			inspirations: inspirationsForMonth?.count || 0,
			items: itemsForMonth?.count || 0,
			creations: creationsForMonth?.count || 0
		};
	});
};

const formatAvgStats = (data: AvgStatsResults) => {
	return [
		{ ...data.patterns, name: 'Patterns' },
		{ ...data.stashItems, name: 'Items' },
		{ ...data.inspirations, name: 'Inspirations' },
		{ ...data.projects, name: 'Projects' },
		{ ...data.creations, name: 'Creations' },
	];
};

const colors = ['#e8dcfa', '#b397db', '#794fb8', '#352054'];
const AdminDashboardView = () => {
	const { data: statsByMonths } = useStatsByMonthQuery();
	const { data: avgStats } = useAvgStatsQuery();
	const { data: subscriptionStats } = useSubscriptionsStatsQuery();

	return (
		<FlexContainer column>
			<FlexContainer column className="mb-1">
				Stats by month
				{statsByMonths?.statsByMonth && <LineChart
					width={1200} height={400}
					data={formatStatByMonthData(statsByMonths.statsByMonth)}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
					<CartesianGrid strokeDasharray="3 3"/>
					<XAxis dataKey="month"/>
					<YAxis/>
					<Tooltip/>
					<Legend/>
					<Line dataKey="users" type="monotone" stroke="red" strokeWidth={2}/>
					<Line dataKey="patterns" type="monotone" stroke="orange" strokeWidth={2}/>
					<Line dataKey="items" type="monotone" stroke="blue" strokeWidth={2}/>
					<Line dataKey="projects" type="monotone" stroke="pink" strokeWidth={2}/>
					<Line dataKey="inspirations" type="monotone" stroke="purple" strokeWidth={2}/>
					<Line dataKey="creations" type="monotone" stroke="green" strokeWidth={2}/>
				</LineChart>}
			</FlexContainer>
			<Divider/>
			<FlexContainer className="mt-1">
				<FlexContainer column>
					<div>Avg by users</div>
					{avgStats?.avgStats && <BarChart
						width={730} height={250}
						data={formatAvgStats(avgStats.avgStats)}>
						<CartesianGrid strokeDasharray="3 3"/>
						<XAxis dataKey="name"/>
						<YAxis/>
						<Tooltip/>
						<Legend/>
						<Bar dataKey="avg" fill="#8884d8">
							<LabelList dataKey="users" position="top"/>
						</Bar>
						<Bar dataKey="overallAvg" fill="#82ca9d"/>
					</BarChart>}
				</FlexContainer>
				<FlexContainer column>
					<div>Subscriptions by plan</div>
					{subscriptionStats?.subscriptionsStats && <PieChart width={500} height={250}>
						<Pie
							data={subscriptionStats.subscriptionsStats}
							dataKey="count"
							nameKey="plan"
							fill="#82ca9d"
							label={({ count, plan }) => count ? `${plan} (${count})` : ''}
						>
							{
								subscriptionStats.subscriptionsStats.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={colors[index]}/>
								))
							}
						</Pie>
						<Tooltip/>
						<Legend/>
					</PieChart>}
				</FlexContainer>
			</FlexContainer>
		</FlexContainer>
	);
};

export default AdminDashboardView;
