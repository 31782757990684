import { ReactComponent as CanvasLogo } from 'assets/images/canvas.svg';
import { ReactComponent as PinterestLogo } from 'assets/images/pinterest_logo.svg';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import Logo from 'components/base/Logo';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import {
	StyledHeader,
	StyledMainPageSidebar,
	StyledMainPageSidebarMenuItem,
	StyledMenu
} from 'pages/PageSidebarStyles';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'services/i18n';

export const MainPageSidebar = () => {
	const location = useLocation();
	const { isMediumScreen, isSuperSmallScreen, isLargeScreen } = useScreenWidth();

	const mainNavigation = [
		{ path: '/patterns', name: 'My patterns', sub: false },
		{ path: '/patterns/new', name: 'Add new pattern', sub: true },
		{ path: '/patterns/sewing?patternType=sewing', name: 'Sewing', sub: true },
		{ path: '/patterns/crochet?patternType=crochet', name: 'Crochet', sub: true },
		{ path: '/patterns/knit?patternType=knit', name: 'Knit', sub: true },
		{ path: '/patterns/other?patternType=other', name: 'Other', sub: true },
		{ path: '/stash', name: 'My stash', sub: false },
		{ path: '/stash/new', name: 'Add new item', sub: true },
		{ path: '/fabrics', name: 'Fabrics', sub: true },
		{ path: '/yarn', name: 'Yarn', sub: true },
		{ path: '/inspirations', name: 'My inspirations', sub: false },
		{ path: '/inspirations/new', name: 'Add new inspiration', sub: true },
		{ path: '/inspirations/tags', name: 'By tags', sub: true },
		{ path: '/inspirations/categories', name: 'By categories', sub: true },
		{ path: '/inspirations/pinterest', name: 'Pinterest', sub: true, icon: <PinterestLogo/> },
	];

	const projectsAndCreationsNavigation = [
		{ path: '/projects', name: 'Projects list', sub: false },
		{ path: '/projects/new', name: 'Add new project', sub: true },
		{ path: '/designs', name: 'My designs', sub: false },
		{ path: '/designs/new', name: 'Create new design', sub: true },
		{ path: '/designs/canva', name: 'Create with', sub: true, icon: <CanvasLogo/> },
		{ path: '/creations', name: 'My creations', sub: false },
		{ path: '/creations/new', name: 'Add new creation', sub: true },

	];
	const resourcesNavigation = [
		{ path: '/brands', name: 'Brands', sub: false },
	];


	return <StyledMainPageSidebar>
		{!isSuperSmallScreen && <Logo/>}
		<StyledMenu column justifyContent="space-between" className="h-100">
			<FlexContainer column style={{ paddingLeft: '2em' }}>
				<Div>
					<StyledHeader>{translate({ key: 'My library' })}</StyledHeader>
					{mainNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={!!route.sub}
							$active={location.pathname === (route.path.split('?')[0])}>
							<Link key={route.path} to={route.path}>
								<FlexContainer>
									{translate({ key: route.name })}
									{route.icon && (<>
										&nbsp;
										{route.icon}
									</>)}
								</FlexContainer>
							</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
				<Div>
					<StyledHeader className="mt-1">{translate({ key: 'My projects' })}</StyledHeader>
					{projectsAndCreationsNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={!!route.sub}
							$active={location.pathname === (route.path.split('?')[0])}>
							<Link key={route.path} to={route.path}>
								<FlexContainer>
									{translate({ key: route.name })}
									{route.icon && (<>
										&nbsp;
										{route.icon}
									</>)}
								</FlexContainer>
							</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
			</FlexContainer>
		</StyledMenu>
	</StyledMainPageSidebar>;
};
