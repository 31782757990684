import { useState } from 'react';
import { translate } from 'services/i18n';
import MultiSelect from './MultiSelect';

interface NeedlesSizeSelectInputProps {
	onChange: (value: number[]) => void;
	values?: number[];
}

const NeedlesSizeSelectInput = ({ onChange, values = [] }: NeedlesSizeSelectInputProps) => {
	const [needlesSizeOptions, setNeedlesSizeOptions] = useState((new Array(20).fill(null)).map((_, index) => ({
		value: `${(index + 1) * 0.5}`,
		label: `${(index + 1) * 0.5}`
	})));
	return (
		<MultiSelect
			onChange={data => onChange(data.map(size => +size))}
			data={needlesSizeOptions}
			value={values.map(size => ({
				value: size.toString(),
				label: size.toString()
			}))}
			creatable
			onCreate={(value) => {
				setNeedlesSizeOptions([...needlesSizeOptions, { value, label: value }]);
				onChange([...values, +value]);
			}}
			label={translate({ key: 'Needles size' })}/>

	);
};

export default NeedlesSizeSelectInput;
