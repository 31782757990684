import { useCallback, useState } from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	SubscriptionPlan,
	useCheckoutLazyQuery,
	useDowngradeMutation,
	useSubscriptionPlansQuery,
	useUpgradeMutation
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import Loader from 'components/base/Loader';
import { Button } from 'components/Button/Button';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import useUserProfile from 'customHooks/data/useUserProfile';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const Container = styled(FlexContainer)`
  border-radius: 1em;
  padding: 1em;
  width: calc(100% - 3em);
  height: 50vh;
  -webkit-box-shadow: 5px 5px 15px 5px #727272;
  box-shadow: 5px 5px 15px 5px #727272;

  &.current {
    -webkit-box-shadow: ${({ theme }) => `2px 5px 14px 0px ${theme.colors.green}`};
    box-shadow: ${({ theme }) => `2px 5px 14px 0px ${theme.colors.green}`};
  }
`;

const Title = styled.div`
  font-family: Parisienne;
  font-size: 2em;
  font-weight: bold;
`;

const ListItem = styled(FlexContainer)`
  padding: 1em 0 0 0;
  justify-content: start;
`;

const CurrentPlanContainer = styled(FlexContainer)`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.green};

  svg {
    margin-right: 0.5em;
    font-size: 2em;

    path {
      fill: ${({ theme }) => theme.colors.green};
    }
  }
`;

const SubscriptionView = () => {
	const { profile, loadingProfile } = useUserProfile();
	const [modalOpened, setModalOpened] = useState(false);
	const [newPlan, setNewPlan] = useState<null | SubscriptionPlan>(null);
	const { data } = useSubscriptionPlansQuery();

	const [getCheckoutUrl] = useCheckoutLazyQuery({
		onCompleted: ({ checkout }) => {
			window.location.replace(checkout);
		}
	});

	const [upgradeSubscription] = useUpgradeMutation();
	const [downgradeSubscription] = useDowngradeMutation();
	const onClick = useCallback((plan: SubscriptionPlan) => {
		if (profile?.subscription?.plan === SubscriptionPlan.Free) {
			getCheckoutUrl({ variables: { plan } });
		} else {
			setNewPlan(plan);
			setModalOpened(true);
		}
	}, [profile, getCheckoutUrl]);

	const updateSubscription = useCallback(async (newPlan: SubscriptionPlan) => {
		if (newPlan === SubscriptionPlan.Free ||
			((profile?.subscription?.plan === SubscriptionPlan.Bulky || profile?.subscription?.plan === SubscriptionPlan.Jumbo)
				&& (newPlan === SubscriptionPlan.Medium || newPlan === SubscriptionPlan.Bulky))
		) {
			downgradeSubscription({ variables: { plan: newPlan } });
		} else if (((profile?.subscription?.plan === SubscriptionPlan.Bulky || profile?.subscription?.plan === SubscriptionPlan.Medium)
			&& (newPlan === SubscriptionPlan.Jumbo || newPlan === SubscriptionPlan.Bulky))) {
			upgradeSubscription({ variables: { plan: newPlan } });
		}
	}, [profile, downgradeSubscription, upgradeSubscription]);
	return loadingProfile ? <Loader/> : <FlexContainer>
		<ModalContainer
			opened={modalOpened}
			onClose={() => {
				setNewPlan(null);
				setModalOpened(false);
			}}
			onConfirm={() => {
				updateSubscription(newPlan);
			}}
		>
			{`${translate({ key: 'Are you sur you want to switch to plan' })} ${newPlan}?`}
		</ModalContainer>
		<GridContainer>
			{(data?.subscriptionPlans || []).map((plan) => (
				<GridItem
					xs={2} sm={4} md={3} key={plan.plan}>
					<Container
						column justifyContent="flex-start"
						className={profile?.subscription.plan === plan.plan ? 'current' : ''}>
						<FlexContainer column>
							<Title>{plan.plan}</Title>
							<div
								style={{ fontSize: plan.plan === SubscriptionPlan.Free ? '1em' : '1.2em' }}
								className="mb-1"
							>
								{plan.price}
							</div>
							<FlexContainer className="border-bottom w-100 pb-1">
								<Button
									disabled={!profile || profile.subscription.plan === plan.plan}
									label={!profile || profile?.subscription.plan === SubscriptionPlan.Free ?
										translate({ key: 'Subscribe' }) :
										translate({ key: 'Switch' })}
									onClick={() => onClick(plan.plan)}
								/>
							</FlexContainer>
						</FlexContainer>
						<FlexContainer column justifyContent="space-between" style={{ height: '100%' }}>
							<FlexContainer column justifyContent="flex-start">
								<div>
									<div>{translate({ key: 'Library' })}</div>
									<ListItem>{`${plan.limitations.patterns} ${translate({ key: 'patterns' })}`}</ListItem>
									<ListItem>{`${plan.limitations.fabrics} ${translate({ key: 'stash items' })}`}</ListItem>
									<ListItem>{`${plan.limitations.inspirations} ${translate({ key: 'inspirations' })}`}</ListItem>
									<div>{translate({ key: 'Projects' })}</div>
									<ListItem>{`${plan.limitations.projects} ${translate({ key: 'projects' })}`}</ListItem>
									<ListItem>{`${plan.limitations.creations} ${translate({ key: 'creations' })}`}</ListItem>
								</div>
							</FlexContainer>
							{profile?.subscription?.plan === plan.plan &&
							<CurrentPlanContainer>
								<FontAwesomeIcon icon={faCheck}/>
								{translate({ key: 'Current plan' })}
							</CurrentPlanContainer>}
						</FlexContainer>
					</Container>
				</GridItem>))}
		</GridContainer>
	</FlexContainer>
	;
};

export default SubscriptionView;
