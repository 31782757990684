import { CircularProgress } from '@mui/material';
import { FlexContainer } from './FlexContainer';

interface ILoader {
	className?: string;
	size?: string | number;
}

const Loader = ({ className, size }: ILoader) => <FlexContainer className={className}>
	<CircularProgress color="inherit" size={size || '3em'}/>
</FlexContainer>;

export default Loader;
