import React, { useState } from 'react';
import { Tooltip } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import PatternFormFileModalView from './PatternFormFileModalView';

interface PatternFormFileItemProps {
	file: {
		name: string
		url: string
		notes?: string
	};
	onChangeConfirm: (file: {
		name: string
		url: string
		notes?: string
	}) => void;
	onDelete: () => void;
}

const FileName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  cursor: default;
`;

const Line = styled(FlexContainer)`
  line-height: 2em;
  margin: 0 1em;
  width: 80%;

  .green-on-hover {
    &:hover {
      .name {
        color: ${({ theme }) => theme.colors.green};
        font-weight: bold;
      }
    }
  }
`;

const ActionSpan = styled.span`
  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const PatternFormFileItem = ({ file, onChangeConfirm, onDelete }: PatternFormFileItemProps) => {
	const [opened, setOpened] = useState(false);

	return (
		<Line column>
			<PatternFormFileModalView
				opened={opened}
				onConfirm={onChangeConfirm}
				onClose={() => setOpened(false)}
				file={file}
			/>
			<FlexContainer justifyContent="space-between" className="green-on-hover">
				<Tooltip title={file.notes}>
					<FileName className={`name ${file.notes ? 'cursor-pointer' : ''}`}>{file.name}</FileName>
				</Tooltip>
				<div>
					<ActionSpan className="mr-1 cursor-pointer-bold underline" onClick={() => setOpened(true)}>
						{translate({ key: 'Edit' })}
					</ActionSpan>
					<ActionSpan
						className="cursor-pointer-bold underline"
						onClick={onDelete}>{translate({ key: 'Delete' })}</ActionSpan>
				</div>
			</FlexContainer>
		</Line>
	);
};

export default PatternFormFileItem;
