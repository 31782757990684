const UseScreenWidth = () => {
	return {
		isSuperSmallScreen: window.innerWidth < 576,
		isSmallScreen: window.innerWidth < 768,
		isMediumScreen: window.innerWidth < 1200,
		isLargeScreen: window.innerWidth >= 1200
	};
};

export default UseScreenWidth;
