import { namedOperations, ProjectDetailsFragment, useDeleteProjectMutation } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import ProjectImage from 'components/ProjectImage/ProjectImage';
import useIsDemo from 'customHooks/data/useIsDemo';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import DeleteProjectModal from '../../DeleteProjectModal/DeleteProjectModal';


const ProjectsListResultsView = () => {
	const {
		currentFilters,
		updateUrl,
		limit,
		loading,
		data
	} = useProjectsSearchEngine();
	const isDemo = useIsDemo();
	const { notifyError, notifyInfo } = useNotifications();
	const [deleteProject, { loading: deletingProject }] = useDeleteProjectMutation({
		refetchQueries: [namedOperations.Query.getProjectsList],
		onCompleted: () => {
			notifyInfo(translate({ key: 'Project deleted' }));
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	return (
		<div className={'w-100'}>
			{(loading || deletingProject) && <LoadingOverlay/>}
			<ListLayout
				items={data?.projects || []}
				component={(item: ProjectDetailsFragment) => <ListGridItem
					key={item._id}
					item={item}
					modalTitle={translate({ key: 'Project deletion' })}
					navigateLink={`${isDemo ? '/demonstration' : ''}/projects/${item._id}`}
					onModalConfirm={() => deleteProject({ variables: { projectId: item._id } })}
					modalContent={<DeleteProjectModal project={item}/>}
					overrideComponent={<ProjectImage project={item}/>}
				/>}
				currentPage={currentFilters.page || 1}
				total={data?.count || 0}
				limit={limit}
				onPageChange={page => {
					updateUrl('page', page.toString());
				}}
			/>
		</div>
	);
};

export default ProjectsListResultsView;
