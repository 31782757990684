import { useState } from 'react';
import {
	namedOperations,
	NewsDetailsFragment,
	useDeleteNewsMutation,
	useGetNewsListQuery
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { dateToString } from 'services/utils';
import ModalContainer from '../../components/ModalContainer/ModalContainer';

const NewsListView = ({ language, admin }: { language: 'fr' | 'en' | 'ita' | 'spa', admin?: boolean }) => {
	const { data } = useGetNewsListQuery();
	const navigate = useNavigate();
	const [modalOpened, setModalOpened] = useState(false);
	const [newsToDelete, setNewsToDelete] = useState<null | NewsDetailsFragment>(null);
	const [deleteNews] = useDeleteNewsMutation({
		refetchQueries: [namedOperations.Query.getNewsList]
	});
	return (
		<FlexContainer column alignItems={'flex-start'}>
			<ModalContainer
				opened={modalOpened}
				onClose={() => {
					setModalOpened(false);
					setNewsToDelete(null);
				}}
				onConfirm={() => {
					deleteNews({ variables: { newsId: newsToDelete._id } }).then(() => {
						setModalOpened(false);
						setNewsToDelete(null);
					});
				}}>
				{newsToDelete && <>
					<div>{dateToString(new Date(newsToDelete.date))}</div>
					<div dangerouslySetInnerHTML={{ __html: newsToDelete.fr }}/>
				</>}
			</ModalContainer>
			{(data?.newsList?.news || []).map(news => <FlexContainer
				alignItems="flex-start"
				column
				className="pb-1"
				key={news._id}>
				<FlexContainer justifyContent={'start'}>
					<span className="bold italic mr-1">{dateToString(new Date(news.date), language)}</span>
					{admin && <>
						<Button
							className="mr-1"
							label={translate({ key: 'Edit' })}
							onClick={() => navigate(`/admin/news/${news._id}/edit`)}
						/>
						<Button
							label={translate({ key: 'Delete' })}
							onClick={() => {
								setModalOpened(true);
								setNewsToDelete(news);
							}}
						/>
					</>}
				</FlexContainer>
				<div dangerouslySetInnerHTML={{ __html: news[language] }}/>
			</FlexContainer>)}
		</FlexContainer>
	);
};

export default NewsListView;
