import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import Logo from 'components/base/Logo';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { Link } from 'react-router-dom';
import { translate } from 'services/i18n';
import {
	StyledHeader,
	StyledMainPageSidebar,
	StyledMainPageSidebarMenuItem,
	StyledMenu
} from '../../PageSidebarStyles';


export const AdminPageSidebar = () => {
	const { isMediumScreen } = useScreenWidth();

	const managementNavigation = [
		{ path: '/admin/brands', name: 'Manage brands', sub: false },
		{ path: '/admin/users', name: 'Manage users', sub: false },
	];

	const adminNavigation = [
		{ path: '/admin/stats', name: 'Stats', sub: false },
		{ path: '/admin/logs', name: 'Logs', sub: false },
		{ path: '/admin/messages', name: 'Messages', sub: false }
	];

	return <StyledMainPageSidebar>
		{!isMediumScreen && (
			<Logo/>
		)}
		<StyledMenu column justifyContent="space-between" className="h-100">
			<FlexContainer column style={{ paddingLeft: '2em' }}>
				<Div>
					<StyledMainPageSidebarMenuItem>
						<Link to={'/admin'}>{translate({ key: 'Home admin' })}</Link>
					</StyledMainPageSidebarMenuItem>
					<StyledHeader>{translate({ key: 'Site management' })}</StyledHeader>
					{managementNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={route.sub}>
							<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
				<Div className="mt">
					<StyledHeader>{translate({ key: 'Console' })}</StyledHeader>
					{adminNavigation.map(route => (
						<StyledMainPageSidebarMenuItem
							key={route.path}
							$sub={route.sub}>
							<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
						</StyledMainPageSidebarMenuItem>
					))}
				</Div>
				<Div className="mt">
					<StyledHeader>{translate({ key: 'News' })}</StyledHeader>
					<StyledMainPageSidebarMenuItem>
						<Link to={'/admin/news'}>{translate({ key: 'News' })}</Link>
					</StyledMainPageSidebarMenuItem>
					<StyledMainPageSidebarMenuItem>
						<Link to={'/admin/news/new'}>{translate({ key: 'Add news' })}</Link>
					</StyledMainPageSidebarMenuItem>

				</Div>
			</FlexContainer>
		</StyledMenu>
	</StyledMainPageSidebar>;
};
