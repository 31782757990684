import React, { useCallback, useEffect, useState } from 'react';
import { faDownload, faImage, faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileDetailsFragment } from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { Button } from 'components/Button/Button';
import useImageCompressor from 'customHooks/services/useImageCompressor';
import { uploadImage } from 'services/api/rest/filesService';
import { translate } from 'services/i18n';
import { getId } from 'services/utils';
import styled from 'styled-components';
import ImageHelpPanel from './ImageHelpPanel/ImageHelpPanel';
import { StyledImageInput, StyledImageInputLoader, StyledLabelContainer } from './StyledImageInput';
import Panel from '../../Panel/Panel';
import TextInput from '../TextInput';

interface ImageInputProps {
	id: string
	image?: null | Partial<FileDetailsFragment>
	onChange?: (value: FileDetailsFragment | null) => void,
	onRemoteUrlChange: (value: string | null) => void
	alt?: string,
	hosted?: boolean
	remoteUrl?: string
}

const StyledInfoIcon = styled(FlexContainer)`
  border-radius: 2em;
  border: ${({ theme }) => `1px solid ${theme.colors.darkgrey}`};
  padding: 0.2em;
  width: 0.5em;
  height: 0.5em;

  &:hover {
    cursor: pointer;
    background-color: lightgrey;
  }

  svg {
    width: 0.2em;
  }
`;

const HostedImageInput = ({
	id,
	image,
	onChange,
	alt,
	hosted = true,
	remoteUrl,
	onRemoteUrlChange
}: ImageInputProps) => {
	const { compressImage } = useImageCompressor();
	const [loading, setLoading] = useState<boolean>(false);
	const [panelOpened, setPanelOpened] = useState(false);
	const [pastedImage, setPastedImage] = useState(null);


	useEffect(() => {
		const handlePaste = async (event: ClipboardEvent) => {
			event.stopPropagation();
			if (event.clipboardData) {
				const items = Array.from(event.clipboardData.items);
				items.map(async (item) => {
					if (item.type.startsWith('image/')) {
						const file = item.getAsFile();
						setPastedImage(file);
					}
				});
			}
		};
		window.addEventListener('paste', (event) => handlePaste(event));
		return () => window.removeEventListener('paste', handlePaste);
	}, []);

	const saveImage = useCallback(async (image: any, optionsOverride?: { maxSizeMB?: number, maxWidthOrHeight?: number }) => {
		setLoading(true);
		const compressedImage: Blob = await compressImage(image, optionsOverride) as Blob;
		const uploadedImage = await uploadImage(compressedImage, getId(), hosted);
		setLoading(false);
		if (remoteUrl) {
			onRemoteUrlChange(null);
		}
		onChange(uploadedImage);
		setPastedImage(null);
	}, [onChange, hosted, onRemoteUrlChange, remoteUrl, compressImage]);

	useEffect(() => {
		if (pastedImage && !loading) {
			saveImage(pastedImage, { maxWidthOrHeight: 5000, maxSizeMB: 0.5 });
		}
	}, [pastedImage, loading, saveImage]);
	const onInputChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target?.files?.length) {
			await saveImage(e.target.files[0]);
		}
	}, [saveImage]);
	return (
		<FlexContainer column>
			<Panel
				onClose={() => setPanelOpened(false)}
				onClick={() => setPanelOpened(false)}
				opened={panelOpened}
			>
				<ImageHelpPanel/>
			</Panel>
			<TextInput
				className={hosted ? '' : 'mb-1'}
				value={remoteUrl || ''}
				onChange={(url) => {
					onRemoteUrlChange(url);
					if (image) {
						onChange(null);
					}
				}}
				id="remote-img-url"
				label={<FlexContainer justifyContent="space-between" className="w-100">
					<span>{`${translate({ key: 'Url' })} *`}</span>
					<StyledInfoIcon onClick={() => setPanelOpened(true)}>
						<FontAwesomeIcon icon={faInfo}/>
					</StyledInfoIcon>
				</FlexContainer>}
				placeholder={translate({ key: 'Image url' })}
			/>
			{hosted && <Divider label={translate({ key: 'Or' })}/>}
			<StyledImageInput column>
				<input
					id={id}
					type="file"
					accept="image/png, image/jpeg, image/*"
					onChange={onInputChange}
					disabled={!hosted}/>
				<label htmlFor={id}>
					{loading && <StyledImageInputLoader/>}
					{(image || remoteUrl) ? <>
						<RemoteImage
							alt={alt || translate({ key: 'New image' })}
							src={remoteUrl || image.url}
						/>
						<FlexContainer className="overlay" column>
							{hosted && <Button
								sx={{ pointerEvents: 'none!important' }}
								className="mb-1"
								fullWidth
								label={translate({ key: 'Edit' })}
							/>}
							<Button
								fullWidth
								onClick={() => onChange(null)}
								label={translate({ key: 'Delete' })}
								red
							/>
						</FlexContainer>
					</> : (
						<StyledLabelContainer loading={loading} className="label-container">
							<FontAwesomeIcon icon={hosted ? faDownload : faImage}/>
						</StyledLabelContainer>
					)}
				</label>
			</StyledImageInput>
		</FlexContainer>
	);
};
export default HostedImageInput;
