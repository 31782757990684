import { FabricDetailsFragment, namedOperations, useDeleteFabricMutation } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import useFabricsSearchEngine from 'customHooks/searchEngine/useFabricsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import DeleteFabricModal from '../../DeleteFabricModal/DeleteFabricModal';

const FabricsListResultsView = () => {
	const {
		currentFilters,
		updateUrl,
		limit,
		loading,
		data
	} = useFabricsSearchEngine();
	const isDemo = useIsDemo();
	const { notifyError, notifyInfo } = useNotifications();
	const [deleteFabric, { loading: deletingFabric }] = useDeleteFabricMutation({
		refetchQueries: [namedOperations.Query.getFabrics],
		onCompleted: () => {
			notifyInfo(translate({ key: 'Fabric deleted' }));
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	return (
		<div className={'w-100'}>
			{(loading || deletingFabric) && <LoadingOverlay/>}
			<ListLayout
				items={(data?.fabrics || [])}
				component={(item: FabricDetailsFragment) => <ListGridItem
					item={item}
					navigateLink={`${isDemo ? '/demonstration' : ''}/fabrics/${item._id}`}
					modalContent={<DeleteFabricModal fabric={item}/>}
					onModalConfirm={() => deleteFabric({ variables: { fabricId: item._id } })}
					modalTitle={translate({ key: 'Delete fabric' })}
				/>}
				currentPage={currentFilters.page || 1}
				total={data?.count || 0}
				limit={limit}
				onPageChange={page => {
					updateUrl('page', page.toString());
				}}
			/>
		</div>
	);
};

export default FabricsListResultsView;
