import { Box } from '@mui/material';
import { ProjectDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import styled from 'styled-components';
import RemoteVideo from '../base/RemoteVideo';

interface ProjectImageProps {
	project: ProjectDetailsFragment;
}

const StyledProjectImage = styled.div`
  width: 100%;
  aspect-ratio: 3 / 4;
  position: relative;
`;

const StyledStashItemsContainer = styled(FlexContainer)`
  position: absolute;
  right: 0;
  bottom: 3em;
  width: inherit;
  padding: 0.5em;
  justify-content: flex-end;
`;

const ProjectImage = ({ project }: ProjectImageProps) => {
	const stashImages = project.stash.slice(0, 4);
	const backgroundImage = project.inspirations.length ? project.inspirations[0].imageUrl : project.patterns.length ? project.patterns[0].imageUrl : null;
	return <StyledProjectImage>
		{project.imageUrl && <RemoteImage src={project.imageUrl} alt={project.name}/>}
		{project.tutorialUrl && <RemoteVideo adjustRatio={false} videoUrl={project.tutorialUrl}/>}
		{!project.tutorialUrl && !project.imageUrl && <RemoteImage src={backgroundImage} alt={project.name}/>}
		<StyledStashItemsContainer>
			{(project.stash || []).filter(({ imageUrl }) => imageUrl).slice(0, 4).map(item => <Box
				component="img"
				sx={{
					borderRadius: '200px',
					width: stashImages.length <= 2 ? '30%' : '23%',
					aspectRatio: '1 / 1',
					'-webkit-box-shadow': '1px 5px 15px 1px #000000'

				}}
				src={item.imageUrl}
				key={item.resourceId}
				alt={item.name}/>)}
		</StyledStashItemsContainer>
	</StyledProjectImage>;
};

export default ProjectImage;
