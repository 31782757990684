import { ReactNode } from 'react';
import GridItem from '../Layouts/Grid/GridItem';

const FieldDescriptionBox = ({ label, content }: { label: string, content: string | ReactNode }) => {
	return (
		<>
			<GridItem md={3}>
				<strong>{label}</strong>
			</GridItem>
			<GridItem md={9}>
				{content}
			</GridItem>
		</>
	);
};

export default FieldDescriptionBox;
