import { useCallback } from 'react';
import { GetProjectsListQueryVariables, ProjectStatus, useGetProjectsListQuery } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import useIsDemo from '../data/useIsDemo';

const formatSearchParams = (searchParams: URLSearchParams) => {
	const page = searchParams.get('page');
	const name = searchParams.get('name');
	const patternIds = searchParams.get('patternIds');
	const inspirationIds = searchParams.get('inspirationIds');
	const stashIds = searchParams.get('stashIds');
	const tags = searchParams.get('tags');
	const status = searchParams.get('status');
	const recipients = searchParams.get('recipients');

	return {
		patternIds: patternIds?.split(',') || [],
		inspirationIds: inspirationIds?.split(',') || [],
		stashIds: stashIds?.split(',') || [],
		tags: tags?.split(',') || [],
		status: (status?.split(',') || []) as ProjectStatus[],
		recipients: recipients?.split(',') || [],
		name,
		page: page ? +page : 1
	};
};
const useProjectsSearchEngine = ({
	limit,
	overrideFilters
}: { limit?: number, overrideFilters?: Partial<GetProjectsListQueryVariables> } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const [searchParams, setSearchParams] = useSearchParams();

	const { loading, error, data, } = useGetProjectsListQuery({
		fetchPolicy: 'cache-and-network',
		variables: {
			page: 1,
			...overrideFilters ? overrideFilters : formatSearchParams(searchParams),
			limit: LIMIT,
			isDemo
		}
	});

	const updateFilterValues = useCallback((field: keyof GetProjectsListQueryVariables, newValue: string | null) => {
		if (newValue) {
			searchParams.set(field, newValue);
		} else {
			searchParams.delete(field);
		}
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);


	const updateUrl = useCallback((field: keyof GetProjectsListQueryVariables, newValue: string | null) => {
		switch (field) {
		case 'page':
		case 'name':
		case 'stashIds':
		case 'inspirationIds':
		case 'patternIds':
		case 'recipients':
		case 'status':
		case 'tags':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters: formatSearchParams(searchParams),
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		data: data?.projects
	};
};

export default useProjectsSearchEngine;
