import { usePatternQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useIsDemo from 'customHooks/data/useIsDemo';
import useNotifications from 'customHooks/utils/useNotifications';
import { useParams } from 'react-router-dom';
import { PatternForm } from 'views/patterns/PatternForm/PatternForm';

const PatternEditView = () => {
	const { notifyError } = useNotifications();
	const isDemo = useIsDemo();
	const { id } = useParams<'id'>();
	const { loading, data } = usePatternQuery({
		variables: { id, isDemo },
		onError: err => {
			notifyError(err.message);
		}
	});

	return <FlexContainer column>
		{(loading) && <LoadingOverlay/>}
		{data?.pattern && <PatternForm pattern={data.pattern} formTitle={'Pattern edition'}/>}
	</FlexContainer>;
};

export default PatternEditView;
