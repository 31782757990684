import { createContext, useContext } from 'react';
import { InspirationDetailsFragment, InspirationInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export interface IInspirationFormContext {
	state: InspirationDetailsFragment;
	handleChange: (name: keyof InspirationInput, value: ValueOf<InspirationInput> | undefined | null) => void;
}

export const FormContext = createContext<null | IInspirationFormContext>(null);

export const FormProvider = FormContext.Provider;

export function useInspirationFormContext() {
	const context = useContext(FormContext);
	if (!context) {
		throw new Error('useInspirationFormContext must be used within a FormContextProvider');
	}
	return context;
}
