import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import useUserProfile from 'customHooks/data/useUserProfile';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import ProjectForm from 'views/projects/ProjectForm/ProjectForm';

const ProjectCreationView = () => {
	const { profile, loadingProfile, canCreateProjects } = useUserProfile();
	const navigate = useNavigate();

	return <FlexContainer column>
		{!loadingProfile && canCreateProjects && <ProjectForm formTitle={'New project'}/>}
		{!loadingProfile && profile && !canCreateProjects && (
			<>
				<div className="pv">
					{`${translate({ key: 'You have reached the limits of projects you are allowed to create' })}`}
					&nbsp;
					{`(${profile.currentCount.projects}/${profile.limits.projects}).`}
				</div>
				<Button
					label={translate({ key: 'Go back to projects list' })}
					onClick={() => navigate('/projects')}
				/>
			</>
		)}
	</FlexContainer>;
};

export default ProjectCreationView;
