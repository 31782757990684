import LoadingOverlay from 'components/base/LoadingOverlay';
import MultiSelectInput from 'components/Inputs/MultiSelect';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';
import { translate } from 'services/i18n';

const ProjectsListFiltersView = () => {
	const { currentFilters, updateUrl } = useProjectsSearchEngine();

	const { loading, tags, recipients, projectStatuses } = useUserFilters();
	return (
		<div className="w-100">
			{loading && <LoadingOverlay/>}
			<GridContainer>
				<GridItem md={4}>
					<TextInput
						label={translate({ key: 'Name' })}
						onChange={search => updateUrl('name', search)}
						value={currentFilters.name}
						id="project-name-search"
					/>
				</GridItem>
				<GridItem md={4}>
					<MultiSelectInput
						label={translate({ key: 'Tags' })}
						data={tags.map(value => ({ value, label: value }))}
						value={currentFilters.tags.map(value => ({ value, label: value }))}
						onChange={tags => updateUrl('tags', tags.join(','))}
					/>
				</GridItem>
				<GridItem md={4}>
					<MultiSelectInput
						label={translate({ key: 'Recipients' })}
						data={recipients.map(value => ({ value, label: value }))}
						value={currentFilters.recipients.map(value => ({ value, label: value }))}
						onChange={value => updateUrl('recipients', value.join(','))}
					/>
				</GridItem>
				<GridItem md={4}>
					<MultiSelectInput
						label={translate({ key: 'Status' })}
						data={projectStatuses}
						value={currentFilters.status.map(value => ({ value, label: value }))}
						onChange={value => updateUrl('status', value.join(','))}
					/>
				</GridItem>
				<GridItem></GridItem>
			</GridContainer>
		</div>
	);
};

export default ProjectsListFiltersView;
