import { InspirationListFilters } from '__generated__/graphql';
import MultiSelectInput from 'components/Inputs/MultiSelect';
import SelectInput from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { translate } from 'services/i18n';
import { ValueOf } from 'types/core';

const InspirationsListFiltersView = ({
	currentFilters,
	updateUrl
}: { currentFilters: Partial<InspirationListFilters>, updateUrl: (field: keyof InspirationListFilters, value: ValueOf<InspirationListFilters>) => void }) => {
	const { tags, categories } = useUserFilters();

	return (
		<GridContainer className="pb-1 mb-1">
			<GridItem md={3}>
				<TextInput
					label={translate({ key: 'Name' })}
					value={currentFilters.name}
					onChange={name => updateUrl('name', name)}
					id="inspirations-name-filter"/>
			</GridItem>
			<GridItem md={3}>
				<MultiSelectInput
					label={translate({ key: 'Tags' })}
					data={tags.map(value => ({ value, label: value }))}
					value={currentFilters.tags.map(value => ({ value, label: value }))}
					onChange={updatedTags => updateUrl('tags', updatedTags.join(','))}/>
			</GridItem>
			<GridItem md={3}>
				<MultiSelectInput
					label={translate({ key: 'Categories' })}
					data={categories.map(value => ({ value, label: value }))}
					value={currentFilters.categories.map(value => ({ value, label: value }))}
					onChange={updatedCategories => updateUrl('categories', updatedCategories.join(','))}/>
			</GridItem>
			<GridItem md={3}>
				<SelectInput
					label={translate({ key: 'Type' })}
					data={['Sewing', 'Knit', 'Crochet', 'Other'].map(value => ({
						label: translate({ key: value }),
						value
					}))}
					value={currentFilters.craftType ? {
						value: currentFilters.craftType,
						label: translate({ key: currentFilters.craftType })
					} : null}
					onChange={(value) => updateUrl('craftType', value)}
				/>
			</GridItem>
		</GridContainer>
	);
};

export default InspirationsListFiltersView;
