import styled from 'styled-components';

interface IFlag {
	src: string
	onClick?: () => void
	className?: string
}
const StyledImage = styled.img`
  width: 2em;
  height: 2em;
	border-radius: 100%;
`;
const Flag = ({ src, onClick, className }: IFlag) => {
	return (
		<StyledImage className={className||''} src={src} onClick={onClick} />
	);
};

export default Flag;
