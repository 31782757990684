import { ReactComponent as Books } from 'assets/images/books.svg';
import { ReactComponent as Monocle } from 'assets/images/monocle.svg';
import { FlexContainer } from 'components/base/FlexContainer';
import { getCurrentLanguage, translate } from 'services/i18n';
import DemonstrationEnglishHomePage from './DemonstrationEnglishHomePage';
import DemonstrationFrenchHomepage from './DemonstrationFrenchHomepage';
import DemonstrationItalianHomepage from './DemonstrationItalianHomepage';
import { StyledTitle } from '../home/HomepageView';

const DemonstrationHomepage = () => {
	const userLanguage = getCurrentLanguage();
	const links = [
		{
			label: 'Free sewing patterns',
			link: '/demonstration/patterns/sewing?patternType=sewing&isFreePattern=true&page=1'
		},
		{ label: 'Cotton yarn', link: '/demonstration/yarn?composition=Coton%2CCoton%2FAcrylique' },
		{
			label: 'Shell stitch crochet inspirations',
			link: '/demonstration/inspirations?craftType=Crochet&tags=shell+stitch'
		}
	];
	return (
		<FlexContainer column>
			<StyledTitle>
				<Books/>
				&nbsp;{translate({ key: 'Welcome to the demonstration space' })}&nbsp;
				<Monocle/>
			</StyledTitle>
			{userLanguage === 'fr' && <DemonstrationFrenchHomepage links={links}/>}
			{userLanguage === 'en' && <DemonstrationEnglishHomePage links={links}/>}
			{userLanguage === 'ita' && <DemonstrationItalianHomepage links={links}/>}
		</FlexContainer>
	);
};

export default DemonstrationHomepage;
