import { InspirationDetailsFragment, InspirationType } from '__generated__/graphql';
import { Button } from 'components/Button/Button';
import ImageList from 'components/Layouts/ListLayout/ImageList';
import useIsDemo from 'customHooks/data/useIsDemo';
import { translate } from 'services/i18n';

const InspirationsListResultView = ({
	data,
	loadMore,
	limit
}: { data: { inspirations?: InspirationDetailsFragment[], count?: number }, loadMore: () => void, limit: number }) => {
	const isDemo = useIsDemo();

	const numberOfGroups = Math.ceil((data?.inspirations || []).length / limit);
	const groups = Array(numberOfGroups).fill(null).map((_, index) => data?.inspirations.slice(index * limit, index * limit + limit));
	return (<>
		{groups.map((group, index) => <ImageList
			key={index}
			images={(group || []).map(({ name, imageUrl, videoUrl, _id, type }) => ({
				name,
				url: type === InspirationType.Image ? imageUrl : videoUrl,
				navigateLink: `${isDemo ? '/demonstration' : ''}/inspirations/${_id}`,
				type,
				_id
			}))}
		/>)}

		{data?.inspirations?.length < data?.count &&
			<Button label={translate({ key: 'Load more' })} onClick={() => loadMore()}/>}
	</>
	);
};

export default InspirationsListResultView;
