import { FlexContainer } from 'components/base/FlexContainer';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const Img = styled.img`
  border-radius: 0.5em;
`;

const WipPageView = () => {
	return (
		<FlexContainer column>
			<FlexContainer>
				<h2>{`${translate({ key: 'Coming Soon' })}...`}</h2>
				<span style={{ fontSize: '3em' }}>&#128521;</span>
			</FlexContainer>
			<Img src={'https://i.ibb.co/bdBxwpQ/Choupi.png'} alt={'choupi'}/>
		</FlexContainer>
	);
};

export default WipPageView;
