import { useCallback, useEffect, useMemo, useState } from 'react';
import { InspirationListFilters, useGetInspirationsListQuery } from '__generated__/graphql';
import useIsDemo from 'customHooks/data/useIsDemo';
import { useSearchParams } from 'react-router-dom';
import { ValueOf } from 'types/core';

export interface InspirationFilters {
	name: string | null;
	tags: string[];
	categories: string[];
	page: number | null;

}

const LIMIT = 24;
const useInspirationsSearchEngine = () => {
	const isDemo = useIsDemo();
	const [searchParams, setSearchParams] = useSearchParams();
	const [lastLoadedPage, setLastLoadedPage] = useState(1);

	const currentFilters = useMemo(() => {
		const tags = searchParams.get('tags');
		const name = searchParams.get('name');
		const categories = searchParams.get('categories');
		const craftType = searchParams.get('craftType');

		return {
			tags: tags?.split(',') || [],
			categories: categories?.split(',') || [],
			name,
			craftType
		};
	}, [searchParams]);

	const { loading, error, data, fetchMore } = useGetInspirationsListQuery({
		fetchPolicy: 'cache-first',
		variables: {
			page: +(searchParams.get('page') || 1),
			limit: LIMIT,
			filters: {
				...currentFilters || {},
				isDemo
			}
		},
	});

	useEffect(() => {
		if (lastLoadedPage !== 1) {
			fetchMore({
				variables: {
					page: lastLoadedPage
				},
			});
		}
	}, [lastLoadedPage, fetchMore]);


	const updateFilterValues = useCallback((field: keyof InspirationListFilters, newValue: ValueOf<InspirationListFilters>) => {
		if (newValue) {
			searchParams.set(field, newValue.toString());
		} else {
			searchParams.delete(field);
		}
		setLastLoadedPage(1);
		setSearchParams(searchParams);
	}, [searchParams, setSearchParams]);

	const updateUrl = useCallback((field: keyof InspirationListFilters, newValue: ValueOf<InspirationListFilters>) => {
		switch (field) {
		case 'tags':
		case 'categories':
		case 'name':
		case 'craftType':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters,
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		page: +(searchParams.get('page') || 1),
		data: data?.inspirations,
		loadMore: () => setLastLoadedPage(lastLoadedPage + 1)
	};
};

export default useInspirationsSearchEngine;
