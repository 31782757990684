import { createContext, ReactNode, useCallback, useState } from 'react';

interface DemonstrationContextProps {
	isDemo: boolean;
	getHelpPanelState: (panelName: string) => ({ opened: boolean, closePanel: () => void });
}

const DemonstrationContext = createContext<DemonstrationContextProps | null>(null);

const DemonstrationProvider = ({ children }: { children: ReactNode }) => {
	const [patternFormHelpPanelOpened, setPatternFormHelpPanelOpened] = useState(true);
	const [patternListHelpPanelOpened, setPatternListHelpPanelOpened] = useState(true);
	const [projectHelpPanelOpened, setProjectHelpPanelOpened] = useState(true);
	const [stashHelpPanelOpened, setStashHelpPanelOpened] = useState(true);
	const [inspirationHelpPanelOpened, setInspirationHelpPanelOpened] = useState(true);

	const getHelpPanelState = useCallback((panelName?: string) => {
		switch (panelName) {
		case 'patternForm': {
			return {
				opened: patternFormHelpPanelOpened,
				closePanel: () => setPatternFormHelpPanelOpened(false)
			};
		}
		case 'patternList': {
			return {
				opened: patternListHelpPanelOpened,
				closePanel: () => setPatternListHelpPanelOpened(false)
			};
		}
		case 'project': {
			return {
				opened: projectHelpPanelOpened,
				closePanel: () => setProjectHelpPanelOpened(false)
			};
		}
		case 'stash': {
			return {
				opened: stashHelpPanelOpened,
				closePanel: () => setStashHelpPanelOpened(false)
			};
		}
		case 'inspiration': {
			return {
				opened: inspirationHelpPanelOpened,
				closePanel: () => setInspirationHelpPanelOpened(false)
			};
		}
		default: {
			return {
				opened: false,
				closePanel: () => {
				}
			};
		}
		}
	}, [patternListHelpPanelOpened, patternFormHelpPanelOpened, projectHelpPanelOpened, stashHelpPanelOpened, inspirationHelpPanelOpened]);
	return (
		<DemonstrationContext.Provider value={{
			isDemo: true,
			getHelpPanelState
		}}>
			{children}
		</DemonstrationContext.Provider>
	);
};

export { DemonstrationContext, DemonstrationProvider };
