import { Suspense, useEffect } from 'react';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import NotFoundPage from 'pages/NotFoundPage';
import { Route, Routes, useNavigate } from 'react-router-dom';
import routes from 'routes/admin';
import RouteComponent from 'routes/RouteComponent';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/main';
import { AdminPageHeader } from './AdminPageHeader/AdminPageHeader';
import { AdminPageSidebar } from './AdminPageSidebar/AdminPageSidebar';
import { StyledAdminPage, StyledContainer, StyledContent } from './AdminPageStyles';

export const AdminPage = () => {
	const { isMediumScreen } = useScreenWidth();
	const { user, loading } = useAuthenticatedUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (!loading && (!user || user.role !== 'ADMIN')) {
			//navigate('/');
		}
	}, [loading, user, navigate]);

	return <ThemeProvider theme={theme}>
		{loading && <LoadingOverlay/>}
		{(!loading && user) && (
			<StyledAdminPage>
				{!isMediumScreen && <AdminPageSidebar/>}
				<StyledContainer>
					<AdminPageHeader/>
					<StyledContent>
						<Suspense fallback={(() => <div/>)()}>
							<Routes>
								{routes.map(({ path, Component, needAuthentication }) => (
									<Route
										key={path}
										path={path}
										element={<RouteComponent Component={Component}
																 needAuthentication={needAuthentication}/>}
									/>
								))}
								<Route path="*" element={<NotFoundPage/>}/>
							</Routes>
						</Suspense>
					</StyledContent>
				</StyledContainer>
			</StyledAdminPage>
		)}
	</ThemeProvider>;
};
