import { Inspiration, InspirationInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export const InspirationFormReducer = (state: Inspiration, {
	key,
	value
}: { key: keyof InspirationInput, value: ValueOf<InspirationInput> }) => {
	switch (key) {
	case 'imageUrl':
	case 'videoUrl':
	case 'videoId':
	case 'name':
	case 'notes':
	case 'categories':
	case 'type':
	case 'craftType':
	case 'sourceUrl':
	case 'tags': {
		return {
			...state,
			[key]: value
		};
	}
	default:
		throw new Error(`No reducer available for key = ${key}`);
	}
};
