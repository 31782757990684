import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import useUserProfile from 'customHooks/data/useUserProfile';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import InspirationForm from 'views/inspirations/InspirationForm/InspirationForm';

const InspirationCreationView = () => {
	const { profile, loadingProfile } = useUserProfile();
	const navigate = useNavigate();
	const canCreateInspiration = profile && (!profile.limits.patterns ||
		(profile.limits.patterns && (profile.currentCount.patterns || 0) < profile.limits.patterns));

	return <FlexContainer column className="w-100">
		{loadingProfile && <LoadingOverlay/>}
		{!loadingProfile && canCreateInspiration && <InspirationForm formTitle={'New inspiration'}/>}
		{!loadingProfile && profile && !canCreateInspiration && (
			<>
				<div className="pv">
					{`${translate({ key: 'You have reached the limits of inspirations you are allowed to create' })}`}
					&nbsp;
					{`(${profile.currentCount.patterns}/${profile.limits.patterns}).`}
				</div>
				<Button
					label={translate({ key: 'Go back to inspirations list' })}
					onClick={() => navigate('/inspirations')}
				/>
			</>
		)}
	</FlexContainer>;
};

export default InspirationCreationView;
