import React, { useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import PatternFormVideoModalView from './PatternFormVideoModal';

interface PatternFormVideoItemProps {
	video: {
		name: string
		url: string
		notes?: string
	};
	onChangeConfirm: (video: {
		name: string
		url: string
		notes?: string
	}) => void;
	onDelete: () => void;
}

const VideoName = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  cursor: default;
`;

const Line = styled(FlexContainer)`
  line-height: 2em;
  margin: 0 1em;
  width: 80%;

  .green-on-hover {
    &:hover {
      .name {
        color: ${({ theme }) => theme.colors.green};
        font-weight: bold;
      }
    }
  }
`;

const ActionSpan = styled.span`
  &:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;

const PatternFormVideoItem = ({ video, onChangeConfirm, onDelete }: PatternFormVideoItemProps) => {
	const [opened, setOpened] = useState(false);

	return (
		<Line column>
			<PatternFormVideoModalView
				opened={opened}
				onConfirm={onChangeConfirm}
				onClose={() => setOpened(false)}
				video={video}
			/>
			<FlexContainer justifyContent="space-between" className="green-on-hover">
				<VideoName className={'name'}>{video.name}</VideoName>
				<div>
					<ActionSpan className="mr-1 cursor-pointer-bold underline" onClick={() => setOpened(true)}>
						{translate({ key: 'Edit' })}
					</ActionSpan>
					<ActionSpan
						className="cursor-pointer-bold underline"
						onClick={onDelete}>{translate({ key: 'Delete' })}</ActionSpan>
				</div>
			</FlexContainer>
		</Line>
	);
};

export default PatternFormVideoItem;
