import { FlexContainer } from 'components/base/FlexContainer';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';

interface StyledMenuItemProps {
	$active?: boolean;
}

const StyledMainPageSidebarMenuItem = styled.div<StyledMenuItemProps>`
  a {
    text-decoration: none;
    color: ${({ theme, $active }) => $active ? theme.colors.activeLink : theme.colors.darkgrey};
    padding: 0 1em;

    &:hover {
      color: ${({ theme }) => theme.colors.brightgreen};
    }
  }

  font-weight: ${({ $active }) => $active ? 'bold' : 'normal'};
  font-style: ${({ $active }) => $active ? 'italic' : 'normal'};
`;

const Footer = styled.footer`
  position: relative;
  bottom: 0;
`;
const MainPageFooter = () => {
	const location = useLocation();

	const footerNavigation = [
		//	{ path: '/subscription', name: 'Subscription', sub: false },
		{ path: '/feedbacks', name: 'Feedbacks', sub: false },
		//	{ path: '/about', name: 'About us', sub: false },
		{ path: '/contact', name: 'Contact', sub: false },
		{ path: '/privacy', name: 'Privacy', sub: false },
	];
	return (
		<Footer>
			<FlexContainer className="pv-1">
				{footerNavigation.map(route => (
					<StyledMainPageSidebarMenuItem
						key={route.path}
						$active={location.pathname === (route.path.split('?')[0])}>
						<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
					</StyledMainPageSidebarMenuItem>
				))}
			</FlexContainer>
		</Footer>
	);
};

export default MainPageFooter;
