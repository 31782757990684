import { useState } from 'react';
import { namedOperations, useSaveNewsMutation } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import DateInput from 'components/Inputs/DateInput';
import TextEditor from 'components/Inputs/TextEditor';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';

const NewsFormView = ({
	fr = '',
	en = '',
	ita = '',
	spa = '',
	newsDate = new Date(),
	id
}: { fr?: string, en?: string, spa?: string, ita?: string, newsDate?: Date, id?: string }) => {
	const navigate = useNavigate();
	const [news, setNews] = useState<{ [key: string]: string }>({
		fr,
		en,
		ita,
		spa
	});
	const [date, setDate] = useState(newsDate);

	const [saveNews] = useSaveNewsMutation({
		onCompleted: () => navigate('/admin/news'),
		refetchQueries: [namedOperations.Query.getNewsList]
	});

	return (
		<FlexContainer column>
			<h3>{translate({ key: 'News' })}</h3>
			<FlexContainer alignItems="end" justifyContent="end" className="mb-1">
				<div className="mr-1">
					<DateInput label={translate({ key: 'Date' })} onChange={date => setDate(date)} value={date}/>
				</div>
			</FlexContainer>
			<GridContainer>
				<GridItem md={6}>
					<div className="w-100 mb-1">
						<TextEditor
							title={'Français'}
							handleChange={(newsForLanguage) => setNews({
								...news,
								fr: newsForLanguage
							})}
							content={news.fr}
						/>
					</div>
				</GridItem>
				<GridItem md={6}>
					<div className="w-100 mb-1">
						<TextEditor
							title={'Anglais'}
							handleChange={(newsForLanguage) => setNews({
								...news,
								en: newsForLanguage
							})}
							content={news.en}
						/>
					</div>
				</GridItem>
				<GridItem md={6}>
					<div className="w-100 mb-1">
						<TextEditor
							title={'Italien'}
							handleChange={(newsForLanguage) => setNews({
								...news,
								ita: newsForLanguage
							})}
							content={news.en}
						/>
					</div>
				</GridItem>
				<GridItem md={6}>
					<div className="w-100 mb-1">
						<TextEditor
							title={'Espagnol'}
							handleChange={(newsForLanguage) => setNews({
								...news,
								spa: newsForLanguage
							})}
							content={news.en}
						/>
					</div>
				</GridItem>
			</GridContainer>
			<Button
				label={translate({ key: 'Save' })}
				onClick={() => saveNews({
					variables: {
						input: {
							...id ? { _id: id } : {},
							date,
							fr: news.fr,
							en: news.en,
							ita: news.ita,
							spa: news.spa
						}
					}
				})}/>
		</FlexContainer>
	);
};

export default NewsFormView;
