import ExpendableContainer from 'components/base/ExpendableContainer';
import { translate } from 'services/i18n';
import GlobalFiltersView from './GlobalFiltersView/GlobalFiltersView';


const FiltersView = () => {
	return (
		<ExpendableContainer label={translate({ key: 'My filters' })}>
			<GlobalFiltersView/>
		</ExpendableContainer>
	);
};

export default FiltersView;
