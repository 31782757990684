import { useState } from 'react';
import { PatternDetailsFragment } from '__generated__/graphql';
import ActiveProjectsListView from 'components/ActiveProjectsListView/ActiveProjectsListView';
import useProjectsSearchEngine from 'customHooks/searchEngine/useProjectsSearchEngine';

interface PatternDetailsProjectsViewProps {
	pattern: PatternDetailsFragment;
}

const PatternDetailsActiveProjectsView = ({ pattern }: PatternDetailsProjectsViewProps) => {
	const { data } = useProjectsSearchEngine({ limit: 6, overrideFilters: { patternIds: [pattern._id] } });
	const [page, setPage] = useState(1);
	return <ActiveProjectsListView
		projects={data?.projects || []}
		total={data?.count || 0}
		page={page}
		onPageChange={page => setPage(page)}
		data={{
			type: 'pattern',
			pattern
		}}
	/>;

};


export default PatternDetailsActiveProjectsView;
