import { ReactNode, useMemo } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, Tooltip } from '@mui/material';
import useIsDemo from 'customHooks/data/useIsDemo';
import { translate } from 'services/i18n';
import styled from 'styled-components';

interface ButtonProps extends MuiButtonProps {
	label: string | ReactNode,
	disabled?: boolean
	onClick?: any/*() => void*/
	className?: string
	red?: boolean
	loading?: boolean
	icon?: ReactNode,
	disableIfDemo?: boolean
	disabledMessage?: string;
	fullWidth?: boolean
}

const ButtonLabelContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Button = ({
	label,
	disabled = false,
	onClick,
	className,
	red = false,
	loading,
	icon,
	disableIfDemo,
	disabledMessage,
	fullWidth,
	...props
}: ButtonProps) => {
	const isDemo = useIsDemo();
	const isDisabled = useMemo(() => disabled || loading || (disableIfDemo && isDemo), [disableIfDemo, disabled, isDemo, loading]);
	return (
		<Tooltip
			sx={{ border: '1px solid blue' }}
			title={disableIfDemo && isDemo ? translate({ key: 'Disabled in demo mode' }) : disabledMessage}
		>
			<ButtonLabelContainer className={fullWidth ? 'w-100' : 'w-fit-content'}>
				<MuiButton
					{...props}
					color={red ? 'error' : 'inherit'}
					variant="outlined"
					className={`button w-100 ${className}`}
					disabled={isDisabled}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (!isDisabled) {
							onClick(e);
						}
					}}
					{...icon ? { startIcon: icon } : {}}
					sx={{
						...props.sx,
						borderRadius: '0.5em',
					}}
				>
					{label}
					{/*loading ? <Loader size={'sm'}/> : label*/}
				</MuiButton>
			</ButtonLabelContainer>
		</Tooltip>
	);
};
