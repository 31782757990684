import { lazy } from 'react';
import AdminDashboardView from '../views/admin/dashboard/AdminDashboardView';
import AdminNewsListView from '../views/admin/news/AdminNewsListView/AdminNewsListView';
import NewsEditView from '../views/news/NewsEditView';
import NewsFormView from '../views/news/NewsFormView';

const AdminBrandsListView = lazy(() => import('views/admin/brands/AdminBrandsListView'));
const UsersListView = lazy(() => import('views/admin/users/UsersListView'));
const MessagesListView = lazy(() => import('views/admin/messages/MessagesListView'));

export default [
	{ path: '/', Component: AdminDashboardView, needAuthentication: true, breadcrumbs: [] },
	{ path: 'brands', name: 'Brands', Component: AdminBrandsListView, needAuthentication: true },
	{ path: 'users', name: 'Users', Component: UsersListView, needAuthentication: true },
	{ path: 'messages', name: 'Messages', Component: MessagesListView, needAuthentication: true },
	{ path: 'news', name: 'News', Component: AdminNewsListView, needAuthentication: true },
	{
		path: 'news/new',
		name: 'News',
		Component: NewsFormView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'News', link: '/admin/news' }]
	},
	{
		path: '/news/:newsId/edit',
		name: 'News',
		Component: NewsEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'News', link: 'news/new' }]
	},
];
