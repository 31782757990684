import { useState } from 'react';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Drawer, Tooltip } from '@mui/material';
import { translate } from 'services/i18n';
import NotesListView from '../NotesListView';

const NotesDrawerView = () => {
	const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
	return (
		<>
			<Tooltip title={translate({ key: 'My notes' })} onClick={() => setDrawerOpened(true)}>
				<FontAwesomeIcon icon={faClipboard}/>
			</Tooltip>
			<Drawer anchor="right" variant="temporary" open={drawerOpened} onClose={() => setDrawerOpened(false)}>
				<NotesListView/>
			</Drawer>
		</>
	);
};

export default NotesDrawerView;
