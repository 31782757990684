import { Grid } from '@mui/material';

const GridItem = ({ children, ...props }: any) => {
	return (
		<Grid item xs={12} md={6} {...props}>
			{children}
		</Grid>
	);
};

export default GridItem;
