import { ReactNode } from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';

interface InputProps {
	value?: string;
	label?: string | ReactNode;
	onChange: (value: string) => void;
	invalid?: boolean;
	required?: boolean;
	disabled?: boolean;
	placeholder?: string;
}


const Input = ({ value, label, onChange, invalid, required, disabled, placeholder }: InputProps) => {
	return <FormControl error={invalid} className="w-100">
		<FormLabel>{label}</FormLabel>
		<TextField
			type="password"
			value={value}
			required={required}
			error={invalid}
			onChange={e => onChange(e.target.value)}
			placeholder={placeholder}
			disabled={disabled}
		/>
	</FormControl>;
};

export default Input;
