// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { forwardRef } from 'react';
import { FormControl, FormLabel } from '@mui/material';
import styled from 'styled-components';
import { FlexContainer } from '../base/FlexContainer';

interface TextAreaProps {
	rows: number;
	label?: string;
	value?: string;
	onChange: (value: string) => void;
	className?: string;
	disabled?: boolean;
	required?: boolean;
	maxCharacters?: number;
}

const StyledTextField = styled.textarea`
  height: fit-content;
  border-color: ${({ theme }) => theme.colors.lightgrey};
  resize: none;
  border-radius: 0.5em;

  textarea {
    font-size: 14px;
  }
`;

const TextArea = forwardRef<HTMLInputElement, TextAreaProps>(({
	rows,
	label,
	value = '',
	onChange,
	className,
	disabled = false,
	required = false,
	maxCharacters = 500
}, ref) => {
	return <FormControl className="w-100">
		<FormLabel>{label}</FormLabel>
		<StyledTextField
			ref={ref}
			value={value}
			className={className}
			onChange={e => {
				if (e.target.value.trim().length < maxCharacters) {
					onChange(e.target.value);
				}
			}}
			multiline
			rows={rows}
			disabled={disabled}
			required={required}
		/>
		<FlexContainer justifyContent="end">{`${value.trim().length}/${maxCharacters}`}</FlexContainer>
	</FormControl>;
});

TextArea.displayName = 'TextArea';

export default TextArea;
