import { ProjectDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import ProjectImage from 'components/ProjectImage/ProjectImage';
import { translate } from 'services/i18n';

const DeleteProjectModal = ({ project }: { project: ProjectDetailsFragment }) => {
	return (
		<FlexContainer column>
			<div style={{ alignSelf: 'flex-start' }} className={'mb-1'}>
				{`${translate({
					key: 'Are you sure you want to delete that project',
					data: { projectName: project.name }
				})} ?`}
			</div>
			<div className="w-50">
				<ProjectImage project={project}/>
			</div>
		</FlexContainer>
	);
};

export default DeleteProjectModal;
