import Badge from 'components/base/Badge';
import { FlexContainer } from 'components/base/FlexContainer';
import { Link, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';

const DemonstrationEnglishHomePage = ({ links }: { links: { label: string, link: string }[] }) => {
	const navigate = useNavigate();
	return (
		<FlexContainer column justifyContent="flex-start">
			<p>
				The demonstration space allows you to see what your personal space can look like once it is filled.
				You can browse the website and navigate to the different pages to understand how the app works.
				Some pages have explanation panels to provide more details.
			</p>
			<p>Navigate through the left sided menu to visit the different parts of the app, or check out some examples
				below
			</p>
			{<FlexContainer alignItems="flex-start">
				{links.map(link => (
					<Badge
						className="mb-1"
						key={link.link}
						label={translate({ key: link.label })}
						onClick={() => navigate(link.link)}/>
				))}
			</FlexContainer>}
			<p>
				For more informations, feel free to contact me through the <Link
					to={'/contact'}><strong>dedicated page</strong></Link>.
			</p>
		</FlexContainer>
	);
};

export default DemonstrationEnglishHomePage;
