import React, { ReactNode, useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { Button } from 'components/Button/Button';
import TextInput from 'components/Inputs/TextInput';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import useDeviceDetection from '../../../customHooks/utils/useDeviceDetection';

const StyledItemContainer = styled.div`
  position: relative;

  .overlay {
    z-index: 0;
    height: calc(100% - 3em);
    width: 100%;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    justify-content: flex-end;
    padding-bottom: 3em;

    button {
      width: calc(100% - 2em) !important;
      margin: 0.5em 0 1em 0;
    }
  }

  &:hover {
    cursor: pointer;

    .overlay {
      display: flex;
      z-index: 2000;
    }
  }
`;

const StyledResultImageView = styled(FlexContainer)`
  border-radius: 0.5em;
  width: 100%;
  aspect-ratio: 0.75;
  align-items: flex-end;
  overflow: hidden;
`;

const StyledOverlayText = styled.div`
  position: absolute;
  bottom: 0;
  background-color: white;
  width: calc(100% - 1em);
  padding: 0.5em;
  opacity: 0.8;
  text-align: center;
  height: 2em;
  line-height: 2em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

type ListGridItemProps = {
	navigateLink: string,
	item: {
		name?: string,
		imageUrl?: string
	}
	onCloseModal?: () => void,
	modalTitle?: string,
	modalContent?: ReactNode,
	deletable?: boolean
	projectCreatable?: boolean;
	initialProjectName?: string
	onModalConfirm?: (name?: string) => void
	overrideComponent?: ReactNode
}

const ListGridItem = ({
	onCloseModal,
	modalTitle,
	modalContent,
	navigateLink,
	deletable = true,
	projectCreatable = false,
	item,
	onModalConfirm,
	overrideComponent, initialProjectName
}: ListGridItemProps) => {
	const navigate = useNavigate();
	const { isMobileDevice } = useDeviceDetection();
	const [modalOpened, setModalOpened] = useState(false);
	const [projectName, setProjectName] = useState(initialProjectName);
	return (
		<StyledItemContainer>
			{modalContent && <ModalContainer
				opened={modalOpened}
				onClose={() => {
					setModalOpened(false);
					if (onCloseModal) {
						onCloseModal();
					}
				}}
				onConfirm={() => onModalConfirm(projectName)}
				title={modalTitle ? translate({ key: modalTitle }) : ''}
			>
				{modalContent}
				{projectCreatable && <TextInput
					className="mt-1"
					label={translate({ key: 'Project name' })}
					onChange={name => setProjectName(name)}
					id="project-name"
					value={projectName}
				/>}
			</ModalContainer>}
			<FlexContainer onClick={() => navigate(navigateLink)}>
				{!isMobileDevice && <FlexContainer column className="overlay">
					<Button
						label={translate({ key: 'View' })}
						onClick={() => {
							navigate(navigateLink);
						}}
						fullWidth
					/>
					{deletable && <Button
						red
						disableIfDemo
						label={translate({ key: 'Delete' })}
						onClick={() => setModalOpened(true)}
						fullWidth
					/>}
					{projectCreatable && <Button
						fullWidth
						label={translate({ key: 'Create project' })}
						onClick={(e: any) => {
							e.preventDefault();
							e.stopPropagation();
							setModalOpened(true);
						}}
					/>}
				</FlexContainer>}
				{!overrideComponent && <StyledResultImageView>
					<RemoteImage src={item?.imageUrl || null} alt={item.name}/>
				</StyledResultImageView>}
				{!!overrideComponent && overrideComponent}
				<StyledOverlayText>
					{item.name}
				</StyledOverlayText>
			</FlexContainer>
		</StyledItemContainer>
	);
};

export default ListGridItem;
