import { File } from '__generated__/graphql';
import { post } from '../index';

export async function uploadImage(data: Blob, filename = 'filename', tempFile = false): Promise<File> {
	const formData = new FormData();
	formData.append('file', data);
	formData.append('filename', filename);
	if (tempFile) {
		formData.append('tempFile', 'true');
	}
	return post('files/upload', { body: formData }, null);
}
