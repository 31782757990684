import { FunctionComponent, KeyboardEventHandler, ReactElement, ReactNode, useState } from 'react';
import { FormControl, FormLabel } from '@mui/material';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { translate } from '../../services/i18n';

interface Option {
	label: string;
	value: string;
}

type SelectInputProps = {
	data: Option[];
	label?: string | ReactNode | ReactElement;
	onCreate?: (value: string) => void;
	invalid?: boolean;
	required?: boolean;
	disabled?: boolean;
	creatable?: boolean;
	clearable?: boolean;
	searchable?: boolean;
	placeholder?: string;
	value: Option;
	onChange: (value: string) => void;
	className?: string
}

const SelectInput: FunctionComponent<SelectInputProps> = ({
	value,
	data,
	onChange,
	label,
	invalid,
	required,
	disabled,
	searchable = true,
	clearable = true,
	creatable = false,
	onCreate,
	placeholder,
	className = ''
}) => {
	const [search, setSearch] = useState('');

	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (!search) return;
		switch (event.key) {
		case 'Enter': {
			event.preventDefault();
			const existingValue = data.find(({ value }) => value === search);
			if (existingValue) {
				onChange(search);
			} else {
				if (creatable) {
					onCreate(search);
					onChange(search);
				}
				return;
			}
			setSearch('');
		}
		}
	};

	return (
		<FormControl className={`w-100 ${className}`} error={invalid}>
			<FormLabel>{label}</FormLabel>
			{creatable ? <CreatableSelect
				className="w-100"
				classNamePrefix="react-select"
				classNames={{
					control: (state) => state.isFocused ? 'react-select-control-focused react-select-control' : 'react-select-control'
				}}
				isDisabled={disabled}
				required={required}
				inputValue={search}
				isClearable={clearable}
				isSearchable={searchable}
				onChange={(newValue: Option) => {
					onChange(newValue?.value);
				}}
				options={data}
				onInputChange={(searchValue) => setSearch(searchValue)}
				onKeyDown={handleKeyDown}
				placeholder={placeholder || `${translate({ key: 'Select or add' })}...`}
				value={value}
				onCreateOption={newOption => {
					onCreate(newOption);
					onChange(newOption);
				}}
			/> : <Select
				className="w-100"
				classNamePrefix="react-select"
				classNames={{
					control: (state) => state.isFocused ? 'react-select-control-focused react-select-control' : 'react-select-control'
				}}
				required={required}
				inputValue={search}
				isDisabled={disabled}
				isClearable={clearable}
				isSearchable={searchable}
				onChange={(newValue: Option) => {
					onChange(newValue?.value);
				}}
				options={data}
				onInputChange={(searchValue) => setSearch(searchValue)}
				onKeyDown={handleKeyDown}
				placeholder={placeholder || `${translate({ key: 'Select' })}...`}
				value={value}
			/>}

		</FormControl>
	);
};

export default SelectInput;
