import { useGetProjectQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useNotifications from 'customHooks/utils/useNotifications';
import { useParams } from 'react-router-dom';
import ProjectForm from 'views/projects/ProjectForm/ProjectForm';

const ProjectEditView = () => {
	const { notifyError } = useNotifications();
	const { id } = useParams<'id'>();
	const { loading, data } = useGetProjectQuery({
		variables: { projectId: id },
		onError: err => {
			notifyError(err.message);
		}
	});

	return (
		<FlexContainer column className="align-items-start">
			{loading && <LoadingOverlay/>}
			{data?.project && <ProjectForm project={data.project} formTitle={'Edit project'}/>}
		</FlexContainer>
	);
};

export default ProjectEditView;
