import { useState } from 'react';
import { namedOperations, NotesDetailsFragment, NotesInput, useSaveNotesMutation } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const StyledContainer = styled(FlexContainer)`
  width: 50em;
`;

const NotesForm = ({
	initialNote,
	onCancel,
	onSaved
}: { initialNote?: NotesDetailsFragment, onCancel: () => void, onSaved: (savedNot: NotesDetailsFragment) => void }) => {
	const [saveNotes] = useSaveNotesMutation({
		refetchQueries: [namedOperations.Query.getNotesList, namedOperations.Query.notes]
	});
	const [note, setNote] = useState<null | NotesDetailsFragment | NotesInput>(initialNote || {
		title: '',
		content: '',
	});

	return (
		<StyledContainer column>
			<FlexContainer column className="pv-1">
				<TextInput
					className="pb-1"
					id="note-title"
					value={note.title}
					label={translate({ key: 'Title' })}
					onChange={(title) => setNote({
						...note,
						title
					})}
				/>
				<TextEditor
					content={note.content}
					handleChange={content => setNote({
						...note,
						content
					})}/>
			</FlexContainer>
			<FlexContainer>
				<Button red className="mr-1" label={translate({ key: 'Cancel' })} onClick={onCancel}/>
				<Button
					disabled={!note.content.length}
					label={translate({ key: 'Save' })}
					onClick={async () => {
						const { data } = await saveNotes({
							variables: {
								input: {
									_id: note._id,
									content: note.content,
									title: note.title
								}
							}
						});
						onSaved(data.saveNotes);
					}}
				/>
			</FlexContainer>
		</StyledContainer>
	);
};

export default NotesForm;
