import { InspirationDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';

const DeleteInspirationModal = ({ inspiration }: { inspiration: InspirationDetailsFragment }) => {
	return (
		<FlexContainer column>
			<div style={{ alignSelf: 'flex-start', paddingBottom: '1em' }}>
				{`${translate({ key: 'Are you sure you want to delete that inspiration' })} ?`}
			</div>
			{!!inspiration.imageUrl && <div className="w-50">
				<RemoteImage src={inspiration.imageUrl} alt={inspiration.name}/>
			</div>}
		</FlexContainer>
	);
};

export default DeleteInspirationModal;
