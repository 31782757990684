import { namedOperations, useLogoutMutation } from '__generated__/graphql';
import { useNavigate } from 'react-router-dom';
import { toast, } from 'react-toastify';
import { translate } from 'services/i18n';
import { useAuthenticatedUser } from '../data/useAuthenticatedUser';

const UseNotifications = () => {
	const { user } = useAuthenticatedUser();
	const navigate = useNavigate();
	const [logout] = useLogoutMutation({
		refetchQueries: [namedOperations.Query.getAuthenticatedUser]
	});

	return {
		notifyError: (message: string) => {
			toast.error(`${translate({ key: 'Sorry, an error occurred' })} : ${message}`);
			if (user && message === 'Unauthorized User') {
				logout().then(() => navigate('/login'));
			}
		},
		notifyInfo: (message: string) => {
			toast.info(message);
		}
	};
};

export default UseNotifications;
