import React from 'react';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import { Navigate, useLocation } from 'react-router-dom';

interface IRouteComponent {
	needAuthentication: boolean;
	Component: React.FC;
}

const RouteComponent = ({ needAuthentication, Component }: IRouteComponent) => {
	const { user, loading } = useAuthenticatedUser();
	const location = useLocation();

	if (loading) return <LoadingOverlay/>;
	return !user && needAuthentication ? <Navigate to="/login" state={{ from: location }} replace/> : <Component/>;
	return <Component/>;
};

export default RouteComponent;
