import React, { ReactNode } from 'react';
import { Grid } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';

const FormLayout = ({ imgContent, children, mainContent }: {
	imgContent: React.ReactComponentElement<any>,
	mainContent: React.ReactComponentElement<any>,
	children?: ReactNode
}) => {
	return (
		<FlexContainer column>
			<Grid container spacing={4}>
				<Grid item xs={12} sm={4} md={3}>
					{imgContent}
				</Grid>
				<Grid item md={9} sm={8} xs={12}>
					{mainContent}
				</Grid>
			</Grid>
			{children && <FlexContainer>{children}</FlexContainer>}
		</FlexContainer>
	);
};

export default FormLayout;
