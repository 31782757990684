import { InstagramEmbed } from 'react-social-media-embed';
import { FlexContainer } from './FlexContainer';

interface InstagramMediaProps {
	url: string;
}

const InstagramMedia = ({ url }: InstagramMediaProps) => {
	return (
		<FlexContainer>
			<InstagramEmbed
				url={url}
				captioned={false}
				style={{
					aspectRatio: '9 / 12'
				}}
			/>
		</FlexContainer>
	);
};

export default InstagramMedia;
