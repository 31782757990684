import { ProjectDetailsFragment, StashItemType } from '__generated__/graphql';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import { translate } from 'services/i18n';

const ProjectDetailsRelatedEntitiesView = ({ project }: { project: ProjectDetailsFragment }) => {
	const { stash, patterns, inspirations } = project;
	return (
		<GridContainer className="w-100">
			{(patterns || []).map((pattern, index) => <GridItem key={pattern._id} xs={6} md={2}
				sx={{
					display: 'flex',
					justifyContent: index === 0 ? 'space-between' : 'flex-end',
					flexDirection: 'column'
				}}>
				{index === 0 && <strong className="mb-1">{translate({ key: 'Patterns' })}</strong>}
				<ListGridItem
					deletable={false}
					navigateLink={`/patterns/${pattern._id}`}
					item={pattern}
				/>
			</GridItem>)
				.concat(
					(inspirations || []).map((inspiration, index) => <GridItem
						key={inspiration._id} xs={6} md={2}
						sx={{
							display: 'flex',
							justifyContent: index === 0 ? 'space-between' : 'flex-end',
							flexDirection: 'column'
						}}>
						{index === 0 && <strong className="mb-1">{translate({ key: 'Inspirations' })}</strong>}
						<ListGridItem
							deletable={false}
							navigateLink={`/inspirations/${inspiration._id}`}
							item={inspiration}
						/>
					</GridItem>
					))
				.concat(
					(stash || []).map((item, index) => <GridItem
						key={item.resourceId} xs={6} md={2}
						sx={{
							display: 'flex',
							justifyContent: index === 0 ? 'space-between' : 'flex-end',
							flexDirection: 'column'
						}}>
						{index === 0 && <strong className="mb-1">{translate({ key: 'Items' })}</strong>}
						<ListGridItem
							deletable={false}
							navigateLink={item.itemType === StashItemType.Yarn ? `/yarn/${item.resourceId}` : `/fabrics/${item.resourceId}`}
							item={item}
						/>
					</GridItem>
					))}
		</GridContainer>
	);
};

export default ProjectDetailsRelatedEntitiesView;
