const ImageItalianHelpPanel = () => {
	// TODO
	return (
		<>

		</>
	);
};

export default ImageItalianHelpPanel;
