import { useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as DriveLogo } from 'assets/images/drive_logo.svg';
import { ReactComponent as DropboxLogo } from 'assets/images/dropbox_logo.svg';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import useDropboxPicker from 'customHooks/services/useDropboxPicker';
import useGoogleDrivePicker from 'customHooks/services/useGoogleDrivePicker';
import { translate } from 'services/i18n';
import PatternFormFileItem from './PatternFormFiles/PatternFormFileItem';
import PatternFormFileModalView from './PatternFormFiles/PatternFormFileModalView';
import PatternFormVideoItem from './PatternFormVideos/PatternFormVideoItem';
import PatternFormVideoModalView from './PatternFormVideos/PatternFormVideoModal';

const PatternFormFilesAndVideosView = () => {
	const { openPicker, pickedFile: googleFile, resetPickedFile: resetGoogleFile } = useGoogleDrivePicker();
	const { openDropboxChooser, selectedFile: dropboxFile, resetSelectedFile: resetDropboxFile } = useDropboxPicker();
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;
	const [fileModalOpened, setFileModalOpened] = useState(false);
	const [videoModalOpened, setVideoModalOpened] = useState(false);
	const [file, setFile] = useState(null);

	useEffect(() => {
		if (googleFile) {
			setFile(googleFile);
		} else if (dropboxFile) {
			setFile(dropboxFile);
		} else {
			setFile(null);
		}
	}, [googleFile, dropboxFile]);
	return (<>
		<PatternFormVideoModalView
			opened={videoModalOpened}
			onConfirm={(video) => handleChange('relevantVideos', state.relevantVideos.concat(video))}
			onClose={() => setVideoModalOpened(false)}
		/>
		<PatternFormFileModalView
			opened={fileModalOpened}
			onClose={() => {
				setFileModalOpened(false);
				setFile(null);
				resetGoogleFile();
				resetDropboxFile();
			}}
			file={file}
			onConfirm={(file) => {
				handleChange('files', state.files.concat(file));
				setFile(null);
				resetGoogleFile();
				resetDropboxFile();
			}}
		/>
		<Divider label={translate({ key: 'Links' })} className="m-0"/>
		<GridContainer>
			<GridItem>
				<FlexContainer column alignItems="start">
					<FlexContainer className="mb-1" alignItems="start" column>
						<span className="bold mr-1 mb-1">
							{`${translate({ key: 'Files' })} ${state.files.length ? `(${state.files.length})` : ''}`}
						</span>
						<FlexContainer justifyContent="start">
							<Button
								label={translate({ key: 'Add' })}
								icon={<FontAwesomeIcon className="cursor-pointer" icon={faPlus}/>}
								onClick={() => setFileModalOpened(true)}
								className="mr-1"
							/>
							<Button
								label={<FlexContainer>
									<DriveLogo
										style={{
											height: '1.5em',
											width: '1.5em',
											marginRight: '0.5em'
										}}
									/>
									<span>Drive</span>
								</FlexContainer>}
								className="mr-1"
								onClick={() => openPicker(() => setFileModalOpened(true))}
							/>
							<Button
								label={<FlexContainer>
									<DropboxLogo
										style={{
											height: '1.5em',
											width: '1.5em',
											marginRight: '0.5em'
										}}
									/>
									<span>Dropbox</span>
								</FlexContainer>}
								onClick={async () => openDropboxChooser(() => setFileModalOpened(true))}
							/>
						</FlexContainer>
					</FlexContainer>
					{!state.files?.length && <div>{translate({ key: 'No file' })}</div>}
					{!!state?.files?.length && state.files.map((file, index) => <PatternFormFileItem
						key={file.url}
						file={file}
						onChangeConfirm={(updatedFile) => {
							const updatedArray = [...state.files];
							updatedArray.splice(index, 1, updatedFile);
							handleChange('files', updatedArray);
						}}
						onDelete={() => handleChange('files', state.files.filter((_, i) => i !== index))}
					/>)}
				</FlexContainer>
			</GridItem>
			<GridItem>
				<FlexContainer column alignItems="start">
					<FlexContainer className="mb-1" column alignItems="start">
						<span className="bold mr-1 mb-1">{translate({ key: 'Videos' })}</span>
						<Button
							label={translate({ key: 'Add' })}
							icon={<FontAwesomeIcon className="cursor-pointer" icon={faPlus}/>}
							onClick={() => setVideoModalOpened(true)}
						/>
					</FlexContainer>
					{!state.relevantVideos?.length && <div>{translate({ key: 'No video' })}</div>}
					{!!state.relevantVideos?.length && state.relevantVideos.map((video, index) =>
						<PatternFormVideoItem
							key={video.url}
							video={video}
							onChangeConfirm={(updatedVideo) => {
								const updatedArray = [...state.relevantVideos];
								updatedArray.splice(index, 1, updatedVideo);
								handleChange('relevantVideos', updatedArray);
							}}
							onDelete={() => {
								handleChange('relevantVideos', state.relevantVideos.filter((_, i) => i !== index));
							}}
						/>)}
				</FlexContainer>
			</GridItem>
		</GridContainer>
	</>
	);
};

export default PatternFormFilesAndVideosView;
