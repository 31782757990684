import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import useYarnsSearchEngine from 'customHooks/searchEngine/useYarnsSearchEngine';
import { translate } from 'services/i18n';
import YarnsListFiltersView from './YarnsListFiltersView/YarnsListFiltersView';
import YarnsListResultsView from './YarnsListResultsView/YarnsListResultsView';

const YarnsListView = () => {
	const { data } = useYarnsSearchEngine();
	return (
		<FlexContainer column>
			<YarnsListFiltersView/>
			<Divider
				sx={{ marginTop: '2em' }}
				label={<strong>
					{translate({ key: 'Search results', data: { count: data?.count || 0 } })}
				</strong>}/>
			<YarnsListResultsView/>
		</FlexContainer>
	);
};

export default YarnsListView;
