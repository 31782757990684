import { Link } from 'react-router-dom';
import { FlexContainer } from '../../components/base/FlexContainer';

const HomepageItalianView = () => {
	return (
		<FlexContainer justifyContent="space-between" alignItems="flex-start" column>
			<p>Né de l&apos;imagination et des besoins d&apos;une couturière amatrice, My Crafting Cloud est un
				outil
				dont
				le but
				est d&apos;accompagner les créateurs. Que vous souhaitiez cataloguer vos patrons, gérer votre stock
				ou
				organiser vos projets et inspirations, vous êtes au bon endroit! Ici, vous pourrez :</p>
			<ul>
				<li>Créer votre bibliothèque de patrons, les catégoriser et les annoter</li>
				<li>Inventorier vos tissus, laines et autre matériel</li>
				<li>Enregistrer vos inspirations pour ne rien oublier</li>
				<li>Combiner vos ressources pour créer vos projets</li>
			</ul>
			<p>N&apos;hésitez pas à consulter <span style={{ fontSize: '18px' }}><Link
				to={'/demonstration'}>l&apos;espace démonstration</Link></span> pour
				tester le fonctionnement de
				l&apos;application. Vous
				pouvez également consulter la <Link to={'/faq'}>FAQ</Link> ou <Link to={'/contact'}>nous
					contacter</Link> pour toute autre
				question.</p>
			<p>Afin de rendre notre outil toujours plus utile, nous vous invitons à nos faire part de vos
				suggestions
				d&apos;amélioration sur notre <Link to={'/feedbacks'}>page dédiée</Link>.</p>
		</FlexContainer>
	);
};

export default HomepageItalianView;
