import { useGetProfileQuery } from '__generated__/graphql';
import useIsDemo from 'customHooks/data/useIsDemo';
import useNotifications from 'customHooks/utils/useNotifications';

const UseUserProfile = () => {
	const isDemo = useIsDemo();
	const { notifyError } = useNotifications();

	const { loading: loadingProfile, data } = useGetProfileQuery({
		onError: (err) => {
			//notifyError(err.message);
		},
		variables: { isDemo }
	});
	//TODO - adjust
	return {
		loadingProfile,
		profile: data?.profile,
		canCreatePatterns: data?.profile && (!data.profile.limits.patterns ||
			(data.profile.limits.patterns && (data.profile.currentCount.patterns || 0) < data.profile.limits.patterns)),
		canCreateItems: data?.profile && (!data.profile.limits.fabrics ||
			(data.profile.limits.fabrics && (data.profile.currentCount.fabrics || 0) < data.profile.limits.fabrics)),
		canCreateProjects: data?.profile && (!data.profile.limits.projects ||
			(data.profile.limits.projects && (data.profile.currentCount.projects || 0) < data.profile.limits.projects)),
	};
};

export default UseUserProfile;
