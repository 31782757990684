import { FlexContainer } from 'components/base/FlexContainer';

const InspirationFrenchHelpPanel = () => {
	return (
		<FlexContainer column>
			<p>Pour ajouter vos inspirations, vous devez récupérer l&apos;url de l&apos;image ou de la vidéo que
				vous
				souhaitez sauvegarder.</p>
			<p><u>Images :</u></p>
			<p>Dans la majorité des cas, vous pouvez récupérer l&apos;url d&apos;une image en faisant un clic droit
				sur
				l&apos;image et en sélectionnant “Copier l&apos;adresse de l&apos;image” dans le menu. Pour les
				posts Instagram,
				copiez l&apos;url de la barre d&apos;url ou sélectionnez “Copier le lien” dans le menu du post.</p>
			<p><u>Vidéos :</u></p>
			<p>Vous pouvez ajouter des vidéos provenant de Youtube, Instagram et Tiktok.&nbsp;</p>
			<ul>
				<li><strong>Youtube</strong> : copiez l&apos;url de la barre d&apos;url, ou faites un clic droit,
					puis
					sélectionnez &#8220;Copier l&apos;URL de la vidéo&#8220;
				</li>
				<li><strong>Instagram</strong> : copiez l&apos;url de la barre d&apos;url ou sélectionnez “Copier le
					lien”
					dans le menu du post&nbsp;</li>
				<li><strong>Tiktok</strong> : copiez l&apos;url de la barre d&apos;url ou sélectionnez “Copier le
					lien” sur la
					publication
				</li>
			</ul>
			<p>&nbsp;</p>
			<p>Qu&apos;il s&apos;agisse d&apos;une image ou d&apos;une vidéo, si l&apos;url est correcte, une
				miniature ou un thumbnail de la
				vidéo s&apos;affichera immédiatement en-dessous du champ url.</p>
		</FlexContainer>
	);
};

export default InspirationFrenchHelpPanel;
