import { ProjectDetailsFragment } from '__generated__/graphql';
import { ExtendedProjectInput } from 'customHooks/formContexts/useProjectFormContext';
import { ValueOf } from 'types/core';

export const ProjectFormReducer = (
	state: ProjectDetailsFragment,
	{ key, value }: { key: keyof ExtendedProjectInput, value: ValueOf<ExtendedProjectInput> }
) => {
	switch (key) {
	case 'name':
	case 'inspirationIds':
	case 'patternIds':
	case 'stashIds':
	case 'recipient':
	case 'status':
	case 'tags':
	case 'notes':
	case 'finishedAt':
	case 'startedAt':
	case 'patterns':
	case 'inspirations':
	case 'stash':
	case 'imageUrl':
	case 'tutorialUrl':
		return {
			...state,
			[key]: value
		};

	default:
		throw new Error(`No reducer available for key = ${key}`);
	}
};
