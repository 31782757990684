import { Checkbox, FormControlLabel } from '@mui/material';

interface CheckboxInputProps {
	label: string
	id: string
	className?: string
	checked?: boolean,
	onChange: (checked?: boolean) => void,
	disabled?: boolean
}

const CheckboxInput = ({ label, id, checked = false, onChange, className, disabled }: CheckboxInputProps) => {
	return <FormControlLabel
		control={<Checkbox
			disabled={!!disabled}
			defaultChecked={checked}
			onChange={e => onChange(e.target.checked)}
		/>}
		id={id}
		className={className}
		label={label}
	/>;
};

export default CheckboxInput;
