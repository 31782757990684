import { PatternType, QuantityUnit } from '__generated__/graphql';
import Checkbox from 'components/Inputs/Checkbox';
import GaugeInput from 'components/Inputs/GaugeInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import NeedlesSizeSelectInput from 'components/Inputs/NeedlesSizeSelectInput';
import QuantityInput from 'components/Inputs/QuantityInput';
import Select from 'components/Inputs/Select';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import { translate } from 'services/i18n';
import PatternFormAdditionalInfosView from '../PatternFormAdditionalInfosView/PatternFormAdditionalInfosView';
import PatternFormFilesAndVideosView from '../PatternFormFilesAndVideosView/PatternFormFilesAndVideosView';

const PatternFormMainContentView = () => {
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;
	const { updateUserFilters, brands, categories, tags, yarnWeights, fabrics } = useUserFilters();
	return (
		<GridContainer>
			<GridItem xs={12} md={6}>
				<TextInput
					id="pattern-name"
					className="pt-0"
					max={50}
					value={state.name}
					label={`${translate({ key: 'Pattern name' })} *`}
					onChange={name => {
						if (name.length < 50) {
							handleChange('name', name);
						}
					}}
					validate={value => !!value && !!value.length}
					errorMessage={translate({ key: 'Name is mandatory' })}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<Select
					label={translate({ key: 'Brand' })}
					onChange={(brand) => {
						handleChange('brand', brand);
					}}
					value={state.brand ? { value: state.brand, label: state.brand } : null}
					creatable
					onCreate={name => updateUserFilters({ brands: [name] })}
					data={brands.map(value => ({ value, label: value }))}/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<TextInput
					className={'pb-0 pt-0'}
					id={'shop-pattern-link'}
					value={state.shopPatternLink || ''}
					label={translate({ key: 'Shop pattern link' })}
					onChange={shopPatternLink => handleChange('shopPatternLink', shopPatternLink)}
				/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<Checkbox
					id="free-pattern"
					checked={!!state.isFreePattern}
					label={translate({ key: 'Free pattern' })}
					onChange={(checked) => {
						handleChange('isFreePattern', !!checked);
					}}/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<MultiSelect
					label={translate({ key: 'Categories' })}
					creatable={true}
					onChange={(categories) => {
						handleChange('categories', categories);
					}}
					onCreate={category => updateUserFilters({ 'categories': [category] })}
					value={state.categories.map(value => ({ value, label: value })) || []}
					data={categories.map(value => ({ value, label: value }))}/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<MultiSelect
					label={translate({ key: 'Tags' })}
					onChange={(tags) => {
						handleChange('tags', tags);
					}}
					value={state.tags.map(value => ({ value, label: value }))}
					creatable
					onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
					data={tags.map(value => ({ value, label: value }))}/>
			</GridItem>
			{state.patternType === PatternType.Sewing && <>
				<GridItem xs={12} md={6}>
					<MultiSelect
						label={translate({ key: 'Recommended fabrics' })}
						onChange={(fabrics) => {
							handleChange('recommendedFabrics', fabrics);
						}}
						value={state.recommendedFabrics.map(value => ({ value, label: value }))}
						creatable
						onCreate={(fabric: string) => updateUserFilters({ 'recommendedFabrics': [fabric] })}
						data={fabrics}/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<QuantityInput
						label="Required quantity"
						onChange={({ quantity, unit }) => {
							handleChange('fabricRequirement', quantity);
							handleChange('fabricQuantityUnit', unit);
						}}
						quantity={state.fabricRequirement}
						unit={state.fabricQuantityUnit || QuantityUnit.Meters}/>
				</GridItem>
			</>}
			{(state.patternType === PatternType.Crochet || state.patternType === PatternType.Knit) && <>
				<GridItem xs={12} md={6}>
					<NeedlesSizeSelectInput
						onChange={data => handleChange('needlesSize', data.map(size => +size))}
						values={state.needlesSize || []}/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<MultiSelect
						onChange={data => handleChange('recommendedYarnWeight', data)}
						data={yarnWeights}
						value={(state.recommendedYarnWeight || []).map(value => ({ value, label: value }))}
						creatable
						onCreate={(value) => updateUserFilters({ recommendedYarnWeight: [value] })}
						label={translate({ key: 'Recommended yarn weight' })}/>
				</GridItem>
				<GridItem xs={12} md={6}>
					<GaugeInput
						onChange={gauge => handleChange('gauge', gauge)}
						gauge={state.gauge}/>
				</GridItem>
			</>}
			<div className="pl-1 pt-1 w-100">
				<TextEditor
					content={state.notes}
					title={translate({ key: 'Notes' })}
					handleChange={notes => handleChange('notes', notes)}/>
			</div>
			<div className="pl-1 pt-1 w-100">
				<PatternFormFilesAndVideosView/>
			</div>
			<div className="pl-1 pt-1 w-100">
				<PatternFormAdditionalInfosView/>
			</div>
		</GridContainer>
	);
};

export default PatternFormMainContentView;
