import { useEffect } from 'react';
import { useGetBrandsQuery } from '__generated__/graphql';
import { Div } from 'components/base/Div';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import Pagination from 'components/Pagination/Pagination';
import useNotifications from 'customHooks/utils/useNotifications';
import { useSearchParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import BrandsListSubmitBrandForm from './BrandsListSubmitBrandForm/BrandsListSubmitBrandForm';
import BrandsListTileView from './BrandsListTileView/BrandsListTileView';

const StyledGrid = styled(FlexContainer)`
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
  grid-row-gap: 1.5em;
  grid-auto-rows: minmax(min-content, max-content);
  align-items: start;
  padding-bottom: 2em;
  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(2, 48%);
  }
  @media screen and (max-width: 767px) {
    grid-template-columns: auto;
  }
`;


const BrandsListView = () => {
	const { notifyError } = useNotifications();
	const [searchParams, setSearchParams] = useSearchParams();
	const page = searchParams.get('page') || 1;
	const { loading, fetchMore, data } = useGetBrandsQuery({
		variables: {
			page: +page,
			limit: 12
		},
		onError: (err) => {
			notifyError(err.message);
		}
	});

	useEffect(() => {
		if (fetchMore) {
			fetchMore({
				variables: {
					page, limit: 12
				}
			});
		}
	}, [fetchMore, page]);

	return (
		<FlexContainer column>
			{loading && <LoadingOverlay/>}
			<h1>{translate({ key: 'Brands list' })}</h1>
			<Div className="pv">
				{`${translate({ key: 'On this page, you will find a list of verified brands selling pdf patterns' })}. 
				${translate({ key: 'Feel free to submit a brand you like if it is not in the list in the form below' })}.`}
			</Div>
			<FlexContainer className="border-bottom pb" column>
				<StyledGrid>
					{(data?.brands?.brands || []).map((brand) => <BrandsListTileView brand={brand} key={brand._id}/>)}
				</StyledGrid>
				{!!data?.brands?.brands.length && (
					<Pagination
						onPageChange={(page) => {
							setSearchParams({ page: `${page}` });
						}}
						totalItems={data?.brands?.count || 0}
						currentPage={+page}
						limit={12}
					/>
				)}
			</FlexContainer>
			<BrandsListSubmitBrandForm/>
		</FlexContainer>
	);
};

export default BrandsListView;
