import { useGetInspirationsTagsListQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import StackedImages from 'components/StackedImages/StackedImages';
import { Link } from 'react-router-dom';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const ListContainer = styled(FlexContainer)`
  padding: 1em;

  &:hover {
    cursor: pointer;
  }

  .MuiGrid-item {
    &:hover {
      font-weight: bold;

      img {
        box-shadow: 5px -5px 25px -5px #3C3C3C;
        -webkit-box-shadow: 5px -5px 25px -5px #3C3C3C;
      }
    }
  }
`;
const TagContainer = styled(FlexContainer)`
  height: 2em;
  font-style: italic;
`;
const InspirationsTagsListView = () => {
	const { data, loading } = useGetInspirationsTagsListQuery({
		fetchPolicy: 'cache-and-network'
	});
	return <ListContainer>
		{loading && <LoadingOverlay/>}
		{!data?.inspirationsTags.length && <span>{translate({ key: 'No results' })}</span>}
		{data?.inspirationsTags && <GridContainer>
			{data.inspirationsTags.map(({ tag, inspirations, count }) => (
				<GridItem xs={6} sm={4} md={2} key={tag}>
					<Link to={`/inspirations/tags/${tag}?tags=${tag}`}>
						<FlexContainer column>
							<StackedImages imagesUrls={inspirations} id={tag}/>
							<TagContainer>{`#${tag} (${count})`}</TagContainer>
						</FlexContainer>
					</Link>
				</GridItem>
			))}
		</GridContainer>}
	</ListContainer>;
};

export default InspirationsTagsListView;
