import { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import TextInput, { TextInputProps } from './TextInput';

const debouncedSearch = debounce((value: string, onChange: (value: string) => void) => onChange(value), 500);
const SearchInput = ({ onChange, id, value, ...props }: TextInputProps & { value: string }) => {
	const [search, setSearch] = useState<string>(value);
	const onSearchChange = useCallback((search: string) => {
		debouncedSearch.cancel();
		setSearch(search);
		debouncedSearch(search, onChange);
	}, [onChange]);

	return (
		<TextInput onChange={onSearchChange} id={id} value={search} {...props} />
	);
};

export default SearchInput;
