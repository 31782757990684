import { useGetStashListLazyQuery } from '__generated__/graphql';
import { translate } from 'services/i18n';
import AsyncAutoCompleteInput, { AsyncAutoCompleteInputOptionProps } from './AsyncAutoCompleteInput';
import { defaultPaginationParams } from '../../constants';

interface MaterialsAutoCompleteInputProps {
	value: AsyncAutoCompleteInputOptionProps[];
	onChange: (options: AsyncAutoCompleteInputOptionProps[]) => void;
}

const MaterialsAutoCompleteInput = ({ value, onChange }: MaterialsAutoCompleteInputProps) => {
	const [getStash] = useGetStashListLazyQuery();
	return (
		<AsyncAutoCompleteInput
			label={translate({ key: 'Materials' })}
			id="project-materials"
			value={value}
			fetchOptions={async (search: string) => {
				const { data } = await getStash({
					variables: {
						...defaultPaginationParams,
						name: search
					}
				});
				return (data?.stash?.items || []).map((item) => ({
					imageUrl: item.imageUrl,
					label: item.name,
					resourceType: item.itemType,
					value: item._id
				}));
			}}
			onChange={options => onChange(options)}
		/>
	);
};

export default MaterialsAutoCompleteInput;
