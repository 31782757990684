import React from 'react';
import { BrandDetailsFragment } from '__generated__/graphql';
import { ReactComponent as FacebookLogo } from 'assets/images/fb_logo.svg';
import { ReactComponent as InstagramLogo } from 'assets/images/instagram_logo.svg';
import { ReactComponent as PinterestLogo } from 'assets/images/pinterest_logo.svg';
import { ReactComponent as TiktokLogo } from 'assets/images/tiktok_logo.svg';
import { ReactComponent as TwitterLogo } from 'assets/images/twitter_logo.svg';
import { ReactComponent as YoutubeLogo } from 'assets/images/youtube_logo.svg';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';
import styled from 'styled-components';

const StyledTile = styled(FlexContainer)`
  justify-content: flex-start;
  border: 1px solid #E6E6E6;
  border-radius: 0.5em;
  padding: 1em;
  width: calc(100% - 2em);
  grid-auto-rows: auto;
  height: calc(100% - 3em);
  min-height: 3em;
  align-items: stretch;

  a:hover {
    color: ${({ theme }) => theme.colors.green};
  }
`;
const StyledImageContainer = styled.div`
  width: 12em;
  height: 8em;
  @media screen and (max-width: 1200px) {
    width: 6em;
    height: 4em;
  }

  img {
    object-fit: scale-down !important;
  }
`;

const StyledSocialsContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 2.4em);
  justify-content: space-between;
  height: fit-content;

  svg {
    width: 2.2em;
    height: 2.2em;

    &:hover {
      fill: ${({ theme }) => theme.colors.green};
    }

    &:hover {
      path[fill]:not([fill="#fff"]) {
        fill: ${({ theme }) => theme.colors.green};
      }
    }
  }
`;

interface ISocials {
	tiktokUrl: React.FC;
	facebookUrl: React.FC;
	twitterUrl: React.FC;
	instagramUrl: React.FC;
	youtubeUrl: React.FC;
	pinterestUrl: React.FC;
}

const BrandsListTileView = ({ brand }: { brand: BrandDetailsFragment }) => {
	const socials: ISocials = {
		tiktokUrl: () => <TiktokLogo/>,
		facebookUrl: () => <FacebookLogo/>,
		twitterUrl: () => <TwitterLogo/>,
		instagramUrl: () => <InstagramLogo/>,
		youtubeUrl: () => <YoutubeLogo/>,
		pinterestUrl: () => <PinterestLogo/>
	};

	return (
		<StyledTile>
			{brand.logo && (
				<StyledImageContainer>
					<RemoteImage alt={brand.name} src={brand.logo.url || null}/>
				</StyledImageContainer>
			)}
			<FlexContainer column className="pl-1 w-100 align-items-start h-fit-content" justifyContent={'flex-start'}>
				<a href={brand.websiteUrl} target={'_blank'} rel="noopener noreferrer">
					<div className="pb-1">
						<div className="bold pb-1">{brand.name.toUpperCase()}</div>
						{translate({ key: 'Website' })}
					</div>
				</a>
				<StyledSocialsContainer>
					{Object.entries(socials).map(([key, Logo]) => {
						const social = key as keyof ISocials;
						if (!brand[social]) {
							return <></>;
						}
						const href: string = brand[social] as string;
						return <a key={`${social}-${brand._id}`} href={href} target="_blank"
								  rel="noopener noreferrer"><Logo/></a>;
					})}
				</StyledSocialsContainer>
			</FlexContainer>
		</StyledTile>
	);
};

export default BrandsListTileView;
