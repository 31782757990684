import { FlexContainer } from 'components/base/FlexContainer';
import Loader from 'components/base/Loader';
import styled from 'styled-components';

export const StyledImageInput = styled(FlexContainer)`
  height: 100%;
  width: 100%;
  border-color: ${({ theme }) => theme.colors.lightgrey};
  border-radius: 0.5em;
  overflow: hidden;
  position: relative;

  input {
    display: none;
  }

  label {
    height: 100%;
    width: 100%;
    pointer-events: auto;
  }

  button {
    width: 80% !important;
  }

  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    display: none;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.9);
    cursor: pointer;
  }

  &:hover {
    .overlay {
      display: flex;
    }
  }
`;
export const StyledLabelContainer = styled(FlexContainer)<{ loading: boolean }>`
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightgrey};
  background-image: linear-gradient(45deg, #9de6f2 0%, #ebfff5 100%);
  border-radius: 0.5em;
  color: ${({ theme }) => theme.colors.petrol};
  width: 100%;
  height: 100%;
  aspect-ratio: 0.75;

  svg {
    width: 4em;
    height: 4em;
    color: ${({ theme }) => theme.colors.petrol};
    opacity: 80%;
  }

  &:hover {
    cursor: ${({ loading }) => loading ? 'default' : 'pointer'};

    svg {
      opacity: 100%;
    }
  }
`;

export const StyledImageInputLoader = styled(Loader)`
  position: absolute;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
`;
